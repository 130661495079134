import moment from "moment";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ModalFooter,
  Tooltip,
  UncontrolledDropdown,
} from "reactstrap";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import AddTicket from "./AddTicket";
import { customStyles } from "../../assets/CustomTableStyles";

import { ContactComp, HeaderSelectComp, SelectComp } from "./Helpers";
import CustomUpdate from "./CustomUpdate";
import { Person } from "react-bootstrap-icons";
import { typeOptions } from "./options";

const TicketTable = (props) => {
  const history = useHistory();

  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState([]);
  const [deletId, setDeletId] = useState();
  const [checkedItems, setCheckedItems] = useState([]);
  const [desTooltip, setDesTooltip] = useState({});
  const [remarksTooltip, setRemarksTooltip] = useState({});

  // Initialize state for cell content
  const [desCellContent, setDesCellContent] = useState("");
  const [remarksCellContent, setRemarksCellContent] = useState("");

  return (
    <>
      <DataTable
        customStyles={customStyles}
        columns={[
          {
            name: (
              <>
                <input
                  type="checkbox"
                  style={{
                    width: "14px",
                    height: "14px",
                    marginRight: "5px",
                  }}
                  checked={props?.allChecked}
                  onChange={() => {
                    props?.handleCheckAll();
                  }}
                />
                <span className="fs-14 font-weight-bold">ALL</span>
              </>
            ),
            selector: (row) => row,
            cell: (value) => (
              <>
                <span>
                  <input
                    style={{
                      width: "14px",
                      height: "14px",
                      // maxWidth: "80px"
                    }}
                    type="checkbox"
                    checked={props?.checkedIds.includes(value?.id)}
                    onChange={() => {
                      props?.getCheckedItems(value);
                    }}
                  />
                </span>
              </>
            ),
            maxWidth: "10px",
          },
          {
            name: <span className="font-weight-bold fs-14">CONTACT</span>,
            selector: (row) => row?.contact,
            cell: (value) => {
              return (
                <div
                  onClick={() =>
                    history.push(`/ticketInfo/?id=${value?.id}`, value)
                  }
                  // style={{ maxWidth: "1000px" }}
                >
                  <ContactComp
                    name={
                      value?.user_object?.full_name?.length > 0
                        ? value?.user_object?.full_name
                        : "Admin"
                    }
                  />
                </div>
              );
            },
            sortable: true,
            minWidth: "250px",
          },
          {
            name: <span className="font-weight-bold fs-14">SUBJECT</span>,
            selector: (row) => row,
            cell: (value) => {
              return (
                <div
                  className="fs-14"
                  title={value?.subject}
                  // style={{
                  //   whiteSpace: "nowrap",
                  //   textOverflow: "ellipsis",
                  //   maxWidth: "200px",
                  // }}
                  style={{
                    maxWidth: "350px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {value?.subject}
                </div>
              );
            },
            sortable: true,
          },

          {
            name: <span className="font-weight-bold fs-14">DESCRIPTION</span>,
            selector: (row) => row,
            cell: (value, index) => {
              const tooltipId = `TooltipExample1-${index}`;

              return (
                <>
                  <div
                    className="fs-14"
                    onMouseEnter={() => {
                      setDesTooltip({ ...desTooltip, [index]: true });
                      setDesCellContent(value.description);
                    }}
                    onMouseLeave={() => {
                      setDesTooltip({ ...desTooltip, [index]: false });
                      setDesCellContent("");
                    }}
                    id={tooltipId}
                    style={{
                      maxWidth: "350px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {value.description}
                  </div>{" "}
                  <Tooltip
                    placement="top"
                    isOpen={desTooltip[index] || false}
                    style={{
                      backgroundColor: "#093564",
                      width: "400px",
                    }}
                    target={tooltipId}
                    hideArrow={true}
                    toggle={(prev) => !prev}
                  >
                    <div className="text-start">
                      <h4 className="fw-bold text-white fs-14">Description</h4>
                      <span className="fs-14">
                        {moment(value?.updated_at).format(
                          "DD-MM-YYYY  hh:mm A"
                        )}
                      </span>
                      <br></br>
                      <span className="fs-14">{desCellContent}</span>
                    </div>
                  </Tooltip>
                </>
              );
            },
            sortable: true,
          },

          {
            name: (
              <>
                <span className="font-weight-bold fs-14">TYPE</span>
              </>
            ),
            selector: (row) => row,
            cell: (value) => {
              return (
                <>
                  <SelectComp
                    options={typeOptions}
                    val={value?.type}
                    // curObj={value}
                    // curSelector="group"
                    // getTickets={() => props.getTicketsData()}
                    // checkedIds={props?.checkedIds}
                    setSelObj={(val) => {
                      props?.setSelObj(val);
                    }}
                    onChange={() => {
                      props?.getCheckedItems(value);
                      props.setModal(true);
                      props.setCurItem("Type");
                    }}
                    style={{ maxWidth: "100px" }}
                  />
                </>
              );
            },
            sortable: false,
          },
          {
            name: <span className="font-weight-bold fs-14">DATE</span>,
            selector: (row) => row,
            cell: (value) => {
              return (
                <div
                  title={moment(value?.created_at).format("DD/MM/YYYY")}
                  className="font-weight-bold fs-14 rounded"
                  style={{ maxWidth: "100px", minWidth: "100px" }}
                >
                  {moment(value?.created_at).format("DD/MM/YYYY")}
                </div>
              );
            },
            sortable: true,
            maxWidth: "110px",
          },
          {
            name: (
              <>
                <span className="font-weight-bold fs-14">GROUP</span>
              </>
            ),
            selector: (row) => row,
            cell: (value) => {
              return (
                <>
                  <SelectComp
                    options={props?.groupOptions}
                    val={value?.group}
                    setSelObj={(val) => {
                      props?.setSelObj(val);
                    }}
                    onChange={() => {
                      props?.getCheckedItems(value);
                      props.setModal(true);
                      props.setCurItem("Group");
                    }}
                  />
                </>
              );
            },
            sortable: false,
          },

          {
            name: (
              <>
                <span className="font-weight-bold fs-14">AGENT</span>
              </>
            ),
            selector: (row) => row,
            cell: (value) => {
              const agentOpts = props?.agentOptions.filter((dd) =>
                dd?.groups.includes(value?.group)
              );
              return (
                <>
                  <SelectComp
                    options={value?.group ? agentOpts : props?.agentOptions}
                    val={value?.assigned_agent}
                    setSelObj={(val) => {
                      props?.setSelObj(val);
                    }}
                    onChange={() => {
                      props?.getCheckedItems(value);
                      props.setModal(true);
                      props.setCurItem("Assign");
                    }}
                  />
                </>
              );
            },
            sortable: false,
          },
          {
            name: (
              <>
                <span className="font-weight-bold fs-14">PRIORITY</span>
              </>
            ),
            selector: (row) => row,
            cell: (value) => {
              return (
                <>
                  <SelectComp
                    key={value?.id}
                    options={props?.priorityOptions}
                    val={value?.priority}
                    setSelObj={(val) => {
                      props?.setSelObj(val);
                    }}
                    onChange={() => {
                      props?.getCheckedItems(value);
                      props.setModal(true);
                      props.setCurItem("Priority");
                    }}
                  />
                </>
              );
            },
            sortable: false,
          },

          {
            name: (
              <>
                <span className="font-weight-bold fs-14">STATUS</span>
              </>
            ),
            selector: (row) => row,
            cell: (value) => {
              return (
                <>
                  <SelectComp
                    options={props?.statusOptions}
                    val={value?.status}
                    setSelObj={(val) => {
                      props?.setSelObj(val);
                    }}
                    onChange={() => {
                      props?.getCheckedItems(value);
                      props.setModal(true);
                      props.setCurItem("Close");
                    }}
                  />
                </>
              );
            },
            sortable: false,
          },

          {
            name: <span className="font-weight-bold fs-14">REMARKS</span>,
            selector: (row) => row,
            cell: (value, index) => {
              const tooltipId = `TooltipExample-${index}`;

              return (
                <>
                  <div
                    className="fs-14"
                    onMouseEnter={() => {
                      setRemarksTooltip({ ...remarksTooltip, [index]: true });
                      setRemarksCellContent(value.remarks);
                    }}
                    onMouseLeave={() => {
                      setRemarksTooltip({ ...remarksTooltip, [index]: false });
                      setRemarksCellContent("");
                    }}
                    id={tooltipId}
                    style={{
                      maxWidth: "200px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {value.remarks}
                  </div>{" "}
                  <Tooltip
                    placement="top"
                    isOpen={remarksTooltip[index] || false}
                    style={{
                      backgroundColor: "#093564",
                      width: "400px",
                    }}
                    target={tooltipId}
                    hideArrow={true}
                    toggle={(prev) => !prev}
                  >
                    <div className="text-start">
                      <h4 className="fw-bold text-white fs-14">Remarks</h4>
                      <span className="fs-14">
                        {moment(value.updated_at).format("DD-MM-YYYY  hh:mm A")}
                      </span>
                      <br></br>
                      <span className="fs-14">{remarksCellContent}</span>
                    </div>
                  </Tooltip>
                </>
              );
            },
            sortable: true,
          },
          {
            name: <span className="font-weight-bold fs-14">ACTIONS</span>,
            selector: (row) => row,
            cell: (value) => {
              return (
                <UncontrolledDropdown className="dropdown d-inline-block">
                  <DropdownToggle
                    className="btn btn-soft-secondary btn-sm"
                    tag="button"
                  >
                    <i className="ri-more-fill align-middle"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem
                      className="edit-item-btn"
                      onClick={() => {
                        setEditModal(true);
                        setSelectedTicket(value);
                      }}
                    >
                      <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                      Edit
                    </DropdownItem>
                    <DropdownItem
                      className="remove-item-btn"
                      onClick={() => {
                        setDeleteModal(true);
                        setDeletId(value);
                      }}
                    >
                      <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                      Delete{" "}
                    </DropdownItem>
                    <DropdownItem
                      className="remove-item-btn"
                      onClick={() => {
                        props?.getCheckedItems(value);
                        props.setModal(true);
                        props.setCurItem("Assign");
                      }}
                    >
                      <Person className="align-bottom me-2 text-muted" />
                      Re-Assign{" "}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              );
            },
            maxWidth: "200px",
          },
        ]}
        data={[...props.ticketsData]}
        pointerOnHover={true}
        onRowClicked={(val) => {
          history.push(
            `/ticketInfo/?id=${val?.id}&page=${props?.pagination?.currentPage}`,
            val
          );
        }}
        paginationPerPage={props.pagination?.rowsPerPage}
        onChangePage={(p, t) => {
          props.handlePagination({
            ...props.pagination,
            currentPage: p,
          });
        }}
        onChangeRowsPerPage={(c, t) => {
          props.handlePagination({
            ...props.pagination,
            rowsPerPage: c,
            currentPage: t,
          });
        }}
        paginationServer
        paginationDefaultPage={props.pagination?.currentPage}
        paginationTotalRows={props.pagination?.totalRows}
        pagination={true}
      />
      <Modal
        id="signupModals"
        tabIndex="-1"
        className="modal-lg"
        isOpen={editModal}
        toggle={() => {
          setEditModal((prev) => !prev);
        }}
      >
        <ModalHeader
          className="p-3"
          toggle={() => {
            setEditModal((prev) => !prev);
          }}
        >
          <span className="text-primary">Edit Ticket</span>
        </ModalHeader>
        <ModalBody>
          <AddTicket
            closeAddPopup={() => {
              setEditModal(false);
              setSelectedTicket(null);
              props.getTicketsData(props.pagination);
            }}
            selectedTicket={selectedTicket}
            history={props.history}
            isEdit={true}
          />
        </ModalBody>
      </Modal>

      <Modal
        id="signupModals"
        tabIndex="-1"
        className="modal-lg"
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal((prev) => !prev);
        }}
      >
        <ModalHeader
          className="p-3"
          toggle={() => {
            setDeleteModal((prev) => !prev);
          }}
        >
          Delete
        </ModalHeader>
        <ModalBody>
          <div>
            <h4>Are you sure you want to delete?</h4>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              props.deleteTicket(deletId.id);
              setDeleteModal((prev) => !prev);
            }}
          >
            Yes
          </Button>
          <Button onClick={() => setDeleteModal((prev) => !prev)}>No</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default TicketTable;
