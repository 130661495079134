import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import ProfileDropdown from "../components/Common/ProfileDropdown";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";

const Header = ({ onChangeLayoutMode, layoutModeType, headerClass }) => {
  const history = useHistory();
  const [search, setSearch] = useState(false);
  const [userRole, setUserRole] = useState("");
  const toogleSearch = () => {
    setSearch(!search);
  };

  const toogleMenuBtn = () => {
    var windowSize = document.documentElement.clientWidth;

    if (windowSize > 767)
      document.querySelector(".hamburger-icon").classList.toggle("open");

    //For collapse horizontal menu
    if (document.documentElement.getAttribute("data-layout") === "horizontal") {
      document.body.classList.contains("menu")
        ? document.body.classList.remove("menu")
        : document.body.classList.add("menu");
    }

    //For collapse vertical menu
    if (document.documentElement.getAttribute("data-layout") === "vertical") {
      if (windowSize < 1025 && windowSize > 767) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.getAttribute("data-sidebar-size") === "sm"
          ? document.documentElement.setAttribute("data-sidebar-size", "")
          : document.documentElement.setAttribute("data-sidebar-size", "sm");
      } else if (windowSize > 1025) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.getAttribute("data-sidebar-size") === "lg"
          ? document.documentElement.setAttribute("data-sidebar-size", "sm")
          : document.documentElement.setAttribute("data-sidebar-size", "lg");
      } else if (windowSize <= 767) {
        document.body.classList.add("vertical-sidebar-enable");
        document.documentElement.setAttribute("data-sidebar-size", "lg");
      }
    }

    //Two column menu
    if (document.documentElement.getAttribute("data-layout") === "twocolumn") {
      document.body.classList.contains("twocolumn-panel")
        ? document.body.classList.remove("twocolumn-panel")
        : document.body.classList.add("twocolumn-panel");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      setUserRole(obj?.groups);
    }
  }, []);
  return (
    <React.Fragment>
      <header id="page-topbar" className={headerClass}>
        <div className="layout-width">
          <div
            className="navbar-header"
            style={{
              background: "#E9E9E9",
            }}
          >
            <div className="d-flex">
              <button
                onClick={toogleMenuBtn}
                type="button"
                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                id="topnav-hamburger-icon"
              >
                <span className="hamburger-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>
            </div>

            <div className="d-flex align-items-center">
              {/* <div className="d-flex align-items-center cursor-pointer">
                <SearchOutlinedIcon />
                <span
                  style={{
                    // fontFamily: "sans-serif",
                    fontSize: "16px",
                    marginLeft: "3px",
                    marginRight: "18px",
                  }}
                  onClick={() => history.push("/knowledge ")}
                >
                  Knowledge Base
                </span>
              </div> */}
              {/* Notification Icon */}
              {/* <div
                style={{ position: "relative" }}
                className="mx-3 cursor-pointer"
              >
                <span>
                  <NotificationsNoneOutlinedIcon />
                </span>

                <span
                  style={{
                    position: "absolute",
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    backgroundColor: "#C72549",
                    border: "2px solid white",
                    top: 2,
                    right: 1,
                  }}
                ></span>
              </div> */}
              <ProfileDropdown />
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
