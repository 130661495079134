import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import ProfileDropdown from "../components/Common/ProfileDropdown";
import SimpliforceLogo1 from "../assets/images/SimpliforceLogo1.png";
import { Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const HeaderTwo = () => {
  const history = useHistory();
  return (
    <React.Fragment>
      <div>
        <nav
          className="navbar navbar-expand-lg shadow-lg"
          style={{ backgroundColor: "#E9E9E9" }}
        >
          <div className="container-fluid mx-4 p-0 d-flex gap-2">
            <a className="navbar-brand" href="/dashboard">
              <img
                src={SimpliforceLogo1}
                alt=""
                width={200}
              />
            </a>

            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo02"
              aria-controls="navbarTogglerDemo02"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
              <ul
                className="navbar-nav mb-2 mb-lg-0"
                style={{ marginLeft: "auto", fontFamily: "Inter" }}
              >
                <li className="nav-item m-auto">
                  <Button
                    sx={{
                      color: "#000",
                      cursor: "pointer",
                      margin: "auto",
                      fontSize: "16px",
                      // marginLeft: "10px",
                      fontFamily: "Inter",
                      textTransform: "capitalize",
                      padding: "6px 8px",
                      fontWeight: 600
                    }}
                    onClick={() => history.push("/home")}
                  >
                    Home
                  </Button>
                </li>
                <li className="nav-item m-auto">
                  <Button
                    style={{
                      color: "#000",
                      cursor: "pointer",
                      margin: "auto",
                      fontSize: "16px",
                      fontFamily: "Inter",
                      textTransform: "capitalize",
                      padding: "6px 8px",
                      fontWeight: 600
                    }}
                    onClick={() => history.push("/knowledge")}
                  >
                    <SearchIcon /> Knowledge Base
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </React.Fragment>
  );
};

export default HeaderTwo;
