import React, { useState, useEffect, useRef } from "react";
import { Row, Card } from "reactstrap";
import { Colxx } from "../../components/Common/CustomBootstrap";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Grid, Button } from "@mui/material";
import apiAuth from "../../helpers/ApiAuth";
import PublicHeader from "../../Layouts/PublicHeader";
import NotificationManager from "../../components/Common/NotificationManager";
import { useHistory } from "react-router-dom";

const ChangePassword = ({ userData, setLoading, loading, history }) => {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  return (
    <div>
      <Row className="m-4 p-3" style={{ backgroundColor: "#FBFBFB" }}>
        <Colxx
          lg={12}
          xs={12}
          className="p-0"
          style={{
            backgroundColor: "#FBFBFB",
          }}
        >
          <div className="">
            <Grid lg={12}>
              <div className="p-5">
                <Formik
                  initialValues={{
                    current_password: "",
                    new_password: "",
                    confirm_password: "",
                  }}
                  enableReinitialize={true}
                  validationSchema={Yup.object({
                    current_password: Yup.string()
                      .trim()
                      .matches(
                        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
                        "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number And One Special Case Character"
                      )
                      .required("Current Password is required"),
                    new_password: Yup.string()
                      .trim()
                      .matches(
                        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
                        "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number And One Special Case Character"
                      )
                      .required("New Password is required"),
                    confirm_password: Yup.string().oneOf(
                      [Yup.ref("new_password")],
                      "password does not match"
                    ),
                  })}
                  onSubmit={(values, { resetForm }) => {
                    let formdata = new FormData();
                    if (values) {
                      formdata.append(
                        "current_password",
                        values?.current_password
                      );
                      formdata.append("new_password", values?.new_password);
                      formdata.append("email", userData?.email);
                      // formdata.append("otp", "1234");
                      formdata.append(
                        "confirm_password",
                        values?.confirm_password
                      );
                    } else {
                      console.log("values object is null or undefined");
                    }
                    setLoading(true);
                    apiAuth
                      .post("/api/forgot/password/verify/", formdata)
                      .then((res) => {
                        const { data } = res;
                        setLoading(false);
                        NotificationManager.success(
                          "Password",
                          "Password Changed Successfully",
                          3000,
                          null,
                          null,
                          ""
                        );
                        resetForm();
                        history.push("/user_tickets");
                      })
                      .catch((err) => {
                        console.log("errorrr", err?.response);
                        setLoading(false);
                        if (err?.response?.status === 400) {
                          NotificationManager.error(
                            "Password",
                            err?.response?.data?.Error ||
                              "Password Change Error",
                            3000,
                            null,
                            null,
                            ""
                          );
                        } else {
                          NotificationManager.error(
                            "Password",
                            "Password Change Error",
                            3000,
                            null,
                            null,
                            ""
                          );
                        }
                      });
                  }}
                >
                  {({ values, errors, touched, setFieldValue, resetForm }) => (
                    <Form className="av-tooltip tooltip-label-bottom">
                      <Row className="my-1 ">
                        <Colxx xs={12} lg={6}>
                          <Grid item lg={10} xs={12} className="mb-3">
                            <label
                              htmlFor="current_password"
                              className="form-label text-primary"
                              style={{
                                fontSize: "16px",
                                fontWeight: 600,
                                wordWrap: "break-word ",
                              }}
                            >
                              Current Password
                            </label>
                            <div className="position-relative auth-pass-inputgroup mb-3">
                              <Field
                                className="form-control"
                                name="current_password"
                                type={showCurrentPassword ? "text" : "password"}
                                placeholder="Enter current password"
                                style={{
                                  borderRadius: "10px",
                                }}
                              />
                              <div
                                className={[
                                  "btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted",
                                ].join(" ")}
                                onClick={(e) => {
                                  setShowCurrentPassword((prev) => !prev);
                                }}
                                id="password-addon"
                              >
                                <i
                                  className={[
                                    showCurrentPassword
                                      ? "ri-eye-close-line"
                                      : "ri-eye-fill",
                                    "align-middle",
                                  ].join(" ")}
                                ></i>
                              </div>
                            </div>
                            <ErrorMessage
                              name="current_password"
                              render={(msg) => (
                                <div className="text-danger">{msg}</div>
                              )}
                            />
                          </Grid>
                          <Grid item lg={10} xs={12} className="mb-3">
                            <label
                              htmlFor="new_password"
                              className="form-label text-primary"
                              style={{
                                fontSize: "16px",
                                fontWeight: 600,
                                wordWrap: "break-word ",
                              }}
                            >
                              New Password
                            </label>
                            <div className="position-relative auth-pass-inputgroup mb-3">
                              <Field
                                className="form-control"
                                name="new_password"
                                type={showNewPassword ? "text" : "password"}
                                placeholder="Enter new password"
                                style={{
                                  borderRadius: "10px",
                                }}
                              />
                              <div
                                className={[
                                  "btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted",
                                ].join(" ")}
                                onClick={(e) => {
                                  setShowNewPassword((prev) => !prev);
                                }}
                                id="password-addon"
                              >
                                <i
                                  className={[
                                    showNewPassword
                                      ? "ri-eye-close-line"
                                      : "ri-eye-fill",
                                    "align-middle",
                                  ].join(" ")}
                                ></i>
                              </div>
                            </div>
                            <ErrorMessage
                              name="new_password"
                              render={(msg) => (
                                <div className="text-danger">{msg}</div>
                              )}
                            />
                          </Grid>

                          <Grid item lg={10} xs={12} className="mb-3">
                            <label
                              htmlFor="confirm_password"
                              className="form-label text-primary"
                              style={{
                                fontSize: "16px",
                                fontWeight: 600,
                                wordWrap: "break-word ",
                              }}
                            >
                              Confirm New Password
                            </label>
                            <div className="position-relative auth-pass-inputgroup mb-3">
                              <Field
                                className="form-control"
                                name="confirm_password"
                                type={showConfirmPassword ? "text" : "password"}
                                placeholder="Confirm new password"
                                style={{
                                  borderRadius: "10px",
                                }}
                              />
                              <div
                                className={[
                                  "btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted",
                                ].join(" ")}
                                onClick={(e) => {
                                  setShowConfirmPassword((prev) => !prev);
                                }}
                                id="password-addon"
                              >
                                <i
                                  className={[
                                    showConfirmPassword
                                      ? "ri-eye-close-line"
                                      : "ri-eye-fill",
                                    "align-middle",
                                  ].join(" ")}
                                ></i>
                              </div>
                            </div>

                            <ErrorMessage
                              name="confirm_password"
                              render={(msg) => (
                                <div className="text-danger">{msg}</div>
                              )}
                            />
                          </Grid>
                        </Colxx>
                      </Row>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                          fontSize: "16px",
                        }}
                      >
                        {loading ? (
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <div className="mt-4 mb-3">
                            <Button
                              className="btn "
                              type="submit"
                              style={{
                                color: "white",
                                fontWeight: 500,
                                background: "#093564",
                                borderRadius: "20px",
                                padding: "8px 40px",
                              }}
                            >
                              Submit
                              {/* {props?.isEdit ? "Update" : "Submit"} */}
                            </Button>
                          </div>
                        )}
                        <div className="mt-4 mb-3 ms-3">
                          <Button
                            className="btn text-primary"
                            style={{
                              fontWeight: 500,
                              padding: "8px 0px",
                            }}
                            onClick={() => {
                              history.push("/user_tickets");
                              // resetForm()
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Grid>
          </div>
        </Colxx>
      </Row>
    </div>
  );
};

export default ChangePassword;
