import React from "react";
import LinkedIn from "../assets/images/LinkedIn.png";
import { Paper } from "@mui/material";

const Footer = () => {
  return (
    // <React.Fragment>
    //   <Paper
    //     sx={{
    //       bottom: 0,
    //       width: "100%",
    //     }}
    //     component="footer"
    //   >
    //     <div className="text-white d-flex justify-content-around aling-items-center px-4 py-3 bg-primary">
    //       <div style={{ fontFamily: "Inter" }}>
    //         © 2023 All rights reserved by Colosseumtech Solutions Pvt Ltd
    //       </div>
    //       <div>
    //         <img
    //           src={LinkedIn}
    //           alt="LinkedIn"
    //           style={{ width: "20px", height: "20px" }}
    //         />
    //       </div>
    //     </div>
    //   </Paper>
    // </React.Fragment>

    <div>
      <footer
        className="py-2 mb-0 shadow-lg w-100 bg-primary"
        style={{
          // background: "rgba(40, 40, 40, 0.80)",
          color: "#fff",
          backdropFilter: "blur(30px)",
        }}
      >
        <div className="text-white d-flex justify-content-around aling-items-center px-4 py-3">
          <div className="fs-14" style={{ fontFamily: "Inter" }}>
            © 2023 All rights reserved by Colosseumtech Solutions Pvt Ltd
          </div>
          <div>
            <img
              src={LinkedIn}
              alt="LinkedIn"
              style={{ width: "20px", height: "20px" }}
            />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
