import React, { useState } from "react";
import { Button } from "@mui/material";

const EditComment = ({ item, handleSaveComment, handleCancel }) => {
  const [editedComment, setEditedComment] = useState(item?.comment);
  return (
    <>
      <div className="d-flex flex-column" style={{}}>
        <div className="mb-2">
          <textarea
            value={editedComment}
            onChange={(e) => {
              setEditedComment(e.target.value);
            }}
            style={{
              width: "100%",
              minHeight: "50px",
              border: "none",
              outline: "none",
              resize: "none", // Allows users to resize the textarea's height
              boxSizing: "border-box", // Includes padding and border in the element's total width and height
            }}
          />
        </div>
        <div className="d-flex justify-content-end">
          <Button
            style={{
              width: "69px",
              height: "21px",
              background: "#093564",
              color: "#fff",
              borderRadius: "100px",
              textTransform: "capitalize",
              // fontWeight: 600,
              fontSize: "14px",
            }}
            onClick={() =>
              handleSaveComment(item?.comment_id, editedComment, item?.comment)
            }
          >
            Submit
          </Button>
          <Button
            style={{
              width: "69px",
              height: "21px",
              color: "#093564",
              borderRadius: "100px",
              textTransform: "capitalize",
              // fontWeight: 600,
              fontSize: "14px",
            }}
            onClick={() => handleCancel(item?.comment_id)}
          >
            Cancel
          </Button>
        </div>
      </div>
    </>
  );
};

export default EditComment;
