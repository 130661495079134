import React, { useEffect, useState } from "react";
import { Card, Col, Row, Tooltip } from "reactstrap";
import Select from "react-select";
import CancelMsg from "../../assets/images/Cancel Message.png";
import apiAuth from "../../helpers/ApiAuth";
import { useHistory } from "react-router-dom";
import NotificationManager from "../../components/Common/NotificationManager";
import moment from "moment";
import { Bar } from "react-chartjs-2";

const customStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    width: "150px",
    border: "none",
    borderRadius: "8px",
    fontSize: "14px",
    fontWeight: "700",
    color: "#093564 !important",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#093564", // Color of the dropdown indicator
  }),
  menu: (baseStyles, state) => ({
    ...baseStyles,
    width: "150px",
    fontSize: "14px",
    color: "#093564",
    backgroundColor: "white",
  }),
};

const DashboardCrm = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [groupOptions, setGroupOptions] = useState([]);
  const [tooltip, setToolTip] = useState({});
  const [selectedGroup, setSelectedGroup] = useState({
    label: "All Groups",
    value: "all",
  });
  const [selectedGroupData, setSelectedGroupData] = useState({});
  const cardsData = [
    { status: "Open", key: "Open" },
    { status: "In Review", key: "In_Review" },
    { status: "Resolved", key: "Resolved" },
    { status: "Dropped", key: "Dropped" },
  ];
  const [totalCount, setTotalCount] = useState({});
  const [ticketData, setTicketData] = useState([]);
  const [logData, setLogData] = useState([]);
  const [ticketCountGraph, setTicketCountGraph] = useState({
    groupNames: [],
    ticketCounts: [],
  });
  const [chartData, setChartData] = useState({});

  const getTicketCount = () => {
    apiAuth
      .get("/api/ticketcount")
      .then((res) => {
        const data = res?.data;
        let allGroupCount = { Open: 0, Resolved: 0, Dropped: 0, In_Review: 0 };
        data?.forEach((item) => {
          allGroupCount.Open = allGroupCount.Open += item.Open;
          allGroupCount.Resolved = allGroupCount.Resolved += item.Resolved;
          allGroupCount.Dropped = allGroupCount.Dropped += item.Dropped;
          allGroupCount.In_Review = allGroupCount.In_Review += item.In_Review;
        });
        const groupNames = data.map((item) => item.group_name);
        const ticketCounts = data.map((item) => item.Open);
        setTicketCountGraph({
          ...setTicketCountGraph,
          groupNames,
          ticketCounts,
        });

        const chartData = {
          labels: groupNames,
          datasets: [
            {
              label: "Open Tickets",
              backgroundColor: "rgba(9, 53, 100, 0.2)",
              borderColor: "rgba(9, 53, 100, 1)",
              borderWidth: 1,
              hoverBackgroundColor: "rgba(9, 53, 100, 0.4)",
              hoverBorderColor: "rgba(9, 53, 100, 1)",
              data: ticketCounts,
            },
          ],
        };
        setChartData(chartData);
        setTicketData(data);
        setSelectedGroupData(allGroupCount);
        setTotalCount(allGroupCount);
        setLoading(false);
      })
      .catch((err) => {
        NotificationManager.error(
          "Ticket Count",
          "Ticket Count Get Error",
          3000,
          null,
          null,
          ""
        );
        setLoading(false);
        console.log(err);
      });
  };

  const getGroups = () => {
    apiAuth
      .get("/api/master/group")
      .then((res) => {
        console.log(res);
        const { data } = res;
        const groupOptions = data?.map((item) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });
        groupOptions.unshift({ label: "All Groups", value: "all" });
        setGroupOptions(groupOptions);
      })
      .catch((err) => {
        NotificationManager.error(
          "Groups",
          "Group Get Error",
          3000,
          null,
          null,
          ""
        );
      });
  };

  useEffect(() => {
    getTicketCount();
    getGroups();
    getTicketsActivity();
  }, []);

  const getBadgeValue = (str) => {
    let list = str.split(" ");
    if (list.length > 1) {
      return list[0].charAt(0).toUpperCase() + list[1].charAt(0).toUpperCase();
    } else {
      return list[0].charAt(0).toUpperCase();
    }
  };

  const getTicketsActivity = () => {
    apiAuth
      .get("/api/ticketactivitylog")
      .then((res) => {
        console.log(res);
        const { data } = res;
        const sortedData = data?.sort((a, b) => {
          const dateA = new Date(a.timestamp);
          const dateB = new Date(b.timestamp);
          return dateB - dateA;
        });
        setLogData(sortedData);
      })
      .catch((err) => {
        NotificationManager.error(
          "Tickets Logs",
          "Tickets Logs Get Error",
          3000,
          null,
          null,
          ""
        );
      });
  };

  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div>
          {loading ? (
            <div className="loading"></div>
          ) : (
            <>
              <Row>
                <Col>
                  <div className="mb-3 ">
                    <Select
                      options={groupOptions}
                      defaultValue={selectedGroup}
                      value={selectedGroup}
                      onChange={(data) => {
                        setSelectedGroup(data);
                        let result = ticketData.find(
                          (grp) => grp.group_name === data.label
                        );
                        data.value === "all"
                          ? setSelectedGroupData(totalCount)
                          : setSelectedGroupData(result ? result : {});
                      }}
                      styles={customStyles}
                    />
                  </div>
                </Col>
              </Row>

              {/* card status */}
              <Row>
                <Col lg={12}>
                  <Row>
                    {cardsData.map((dd, i) => (
                      <Col lg={3} xs={12} key={i}>
                        <Card
                          className="p-2 cursor-pointer"
                          style={{
                            textAlign: "center",
                            color: " #000",
                            boxShadow: "0px 0px 21px 5px rgba(0, 0, 0, 0.10)",
                          }}
                          onClick={(e) => {
                            history.push(
                              `/tickets/?status=${dd?.key}&group=${selectedGroup?.value}`
                            );
                          }}
                        >
                          <span
                            style={{
                              fontSize: "20px",
                              paddingBottom: "5px",
                            }}
                          >
                            {dd?.status}
                          </span>
                          <h4 style={{ fontSize: "20px", fontWeight: 900, color: "#000" }}>
                            {selectedGroupData[dd?.key]
                              ? selectedGroupData[dd?.key]
                              : 0}
                          </h4>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>

              {/* Across Helpdesk Cards */}
              <Row>
                <Col lg={12}>
                  {/* <div
                    className="d-flex flex-row gap-2"
                    style={{
                      color: " #093564",
                      boxShadow: "0px 0px 21px 5px rgba(0, 0, 0, 0.10)",
                      fontSize: "18px",
                      width: "100%",
                    }}
                  > */}
                  {/* Undelivered Emails Card */}
                  <Row>
                    <Col lg={6} className="mb-4">
                      <Card className="p-3 h-100 cursor-pointer">
                        <div>
                          <Bar data={chartData} options={chartOptions} />
                        </div>
                      </Card>
                    </Col>
                    <Col lg={6} className="mb-4">
                      {/* Undelivered Tickets Card */}
                      <Card className="p-3 h-100">
                        <div className="d-flex justify-content-between align-items-center gap-3">
                          <div>
                            <div
                              style={{
                                color: "black",
                                fontSize: "16px",
                              }}
                            >
                              Unresolved Tickets
                            </div>
                            <div
                              style={{
                                fontSize: "14px",
                                color: "#53686A",
                              }}
                            >
                              Across Helpdesk
                            </div>
                          </div>
                          {/* <div
                            style={{
                              color: "#354A94",
                              fontSize: "16px",
                              textDecoration: "underline",
                            }}
                          >
                            <span className="cursor-pointer">View Details</span>
                          </div> */}
                        </div>
                        <div className="my-3 mx-3">
                          <div
                            style={{
                              borderBottom: "0.5px solid grey",
                              color: "#53686A",
                            }}
                            className="d-flex justify-content-between align-items-center w-100 px-1 my-1"
                          >
                            <div>Group</div>
                            <div>Open</div>
                          </div>
                          {ticketData.map((dd, i) => (
                            <div
                              key={i}
                              style={{
                                borderBottom: "0.5px solid grey",
                                fontSize: "16px",
                              }}
                              className="d-flex justify-content-between align-items-center w-100 px-1 my-1"
                            >
                              <div>
                                {dd?.group_name === "No Group"
                                  ? "Customer Support"
                                  : dd?.group_name}
                              </div>
                              <div>{dd?.Open}</div>
                            </div>
                          ))}
                        </div>
                      </Card>
                    </Col>
                  </Row>
                  {/* </div> */}
                </Col>
              </Row>
              <Row>
                <Col lg={7}>
                  <div
                    className="d-flex flex-row gap-2"
                    style={{
                      color: " #093564",
                      boxShadow: "0px 0px 21px 5px rgba(0, 0, 0, 0.10)",
                      fontSize: "18px",
                      width: "100%",
                    }}
                  >
                    {logData && logData?.length > 0 ? (
                      <Card
                        style={{
                          width: "100%",
                          maxHeight: "300px",
                          overflowY: "auto",
                        }}
                        className="p-3"
                      >
                        <div className="d-flex justify-content-between align-items-center gap-3">
                          <div>
                            <div
                              style={{
                                color: "black",
                                fontSize: "16px",
                                marginBottom: "10px",
                              }}
                            >
                              Recent Activities
                            </div>
                          </div>
                        </div>
                        {logData?.map((item, index) => {
                          const letter =
                            item?.type === "Created" &&
                            item?.info?.split(" ")[3];
                          const words =
                            item?.type === "Created"
                              ? letter[0]?.toUpperCase()
                              : item?.updated_by_details
                              ? item?.updated_by_details?.username[0]?.toUpperCase()
                              : "";
                          const generateRandomColor = () => {
                            const colors = [
                              "#c72135",
                              "#e16033",
                              "#dba355",
                              "#324b7b",
                            ];

                            const randomIndex =
                              words?.charCodeAt(0) % colors.length;
                            return colors[randomIndex];
                          };

                          const backgroundColor = generateRandomColor();
                          return (
                            <>
                              <div
                                className="d-flex align-items-center gap-2 cursor-pointer"
                                onClick={() =>
                                  history.push(
                                    `/ticketInfo/?id=${item?.ticket?.id}`
                                  )
                                }
                              >
                                <div
                                  style={{
                                    width: "35px",
                                    height: "35px",
                                    backgroundColor: backgroundColor,
                                    color: "#fff",
                                    display: "flex",
                                    borderRadius: "5px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontWeight: "800",
                                  }}
                                  id={`TooltipExample${item?.id}`}
                                  onMouseEnter={() => {
                                    setToolTip({
                                      ...tooltip,
                                      [index]: true,
                                    });
                                  }}
                                  onMouseLeave={() => {
                                    setToolTip({
                                      ...tooltip,
                                      [index]: false,
                                    });
                                  }}
                                >
                                  {" "}
                                  {words}
                                </div>

                                <Tooltip
                                  placement="right"
                                  isOpen={tooltip[index] || false}
                                  style={{
                                    backgroundColor: "#d9d9d9",
                                    width: "400px",
                                    color: "#000",
                                  }}
                                  target={`TooltipExample${item?.id}`}
                                  hideArrow={true}
                                  toggle={(prev) => !prev}
                                >
                                  <div className="text-start">
                                    <h4>
                                      {item?.type === "Created"
                                        ? item?.created_by_details?.username
                                            .charAt(0)
                                            .toUpperCase() +
                                          item?.created_by_details?.username
                                            .slice(1)
                                            .toLowerCase()
                                        : item?.updated_by_details?.username
                                            .charAt(0)
                                            .toUpperCase() +
                                          item?.updated_by_details?.username
                                            .slice(1)
                                            .toLowerCase()}
                                    </h4>

                                    <span>
                                      Email:{" "}
                                      {item?.type === "Created"
                                        ? item?.created_by_details?.email
                                        : item?.updated_by_details?.email}
                                    </span>

                                    <br></br>

                                    <span>
                                      Mobile:{" "}
                                      {item?.type === "Created"
                                        ? item?.created_by_details?.mobile
                                        : item?.updated_by_details?.mobile
                                        ? item?.updated_by_details?.mobile
                                        : "7981755654"}
                                    </span>
                                  </div>
                                </Tooltip>

                                <div className="d-flex flex-column">
                                  <span className="fs-13">
                                    {item?.type === "Created"
                                      ? `${item?.info} (#${item?.ticket?.id})`
                                      : `${
                                          item?.info
                                        } ${item?.type?.toLowerCase()} by ${
                                          item?.updated_by_details?.username
                                        } (#${item?.ticket?.id})`}
                                  </span>
                                  <span className="fs-10">
                                    {moment(item.timestamp).fromNow()}
                                  </span>
                                </div>
                              </div>
                              <hr></hr>
                            </>
                          );
                        })}
                      </Card>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              </Row>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default DashboardCrm;
