import React from "react";
import { Col, Row } from "reactstrap";
import { ChevronRight } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { cardsData } from "./Data";

import PublicHeader from "../../Layouts/PublicHeader";
import { useParams } from "react-router";
const TmsSystem = () => {
  const id = useParams();

  const questionData = cardsData?.find((item) => {
    return item?.route === id?.route;
  });

  const data = questionData?.points?.filter((item) => item.link === id?.link);
  const subPoints = data?.map((item) => {
    return item?.subPoints;
  });
  return (
    <React.Fragment>
      <div className="" style={{ height: "100vh", background: "white" }}>
        <PublicHeader ticketPage={localStorage.getItem("role") === "user"} />
        <Row className="mt-4" style={{ padding: "2%"}}>
          <Col
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <Link
              to="/home"
              className="p-2 fs-20"
              style={{
                color: "rgba(9, 53, 100, 0.50)",
              }}
            >
              Home
            </Link>
            <ChevronRight />
            <Link
              to="/knowledge"
              className="p-2 fs-20"
              style={{
                color: "rgba(9, 53, 100, 0.50)",
              }}
            >
              Knowledge Base
            </Link>
            <ChevronRight />

            <Link
              to={`/knowledge/${questionData?.route}`}
              className="p-2 fs-20"
              style={{ color: "rgba(9, 53, 100, 0.50)" }}
            >
              {" "}
              {questionData?.title}
            </Link>
            <ChevronRight />

            {data?.map((item) => {
              return (
                <>
                  <Link
                    to={`/knowledge/${questionData?.route}/${item?.link}`}
                    className="p-2 fs-20"
                    style={{ color: "#093564" }}
                  >
                    {item?.subTitle}
                  </Link>
                </>
              );
            })}
          </Col>
        </Row>
        {data?.map((item) => {
          return (
            <>
              <Row style={{ padding: "2%" }}>
                <Col>
                  <div>
                    <h3 className="text-primary fw-800">{item?.subTitle}</h3>
                    <div>
                      <p
                        className="text-primary fs-18"
                        style={{
                          fontWeight: 300,
                          lineHeight: "32.50px",
                          wordWrap: "break-word",
                        }}
                      >
                        {item?.answer}
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          );
        })}

        {subPoints?.map((dd) => {
          return (
            <>
              <p
                style={{
                  color: "#093564",
                  fontSize: "18px",
                  lineHeight: "32.50px",
                  wordWrap: "break-word",
                  margin: "0",
                }}
              >
                {dd?.name}
              </p>
            </>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default TmsSystem;
