import React, { useState, useEffect } from "react";
import { Card, Container, Row } from "reactstrap";
import { Colxx } from "../../components/Common/CustomBootstrap";
import BreadCrumb from "../../components/Common/BreadCrumb";
import apiAuth from "../../helpers/ApiAuth";
import NotificationManager from "../../components/Common/NotificationManager";
import ClientTable from "./ClientTable";

const Clients = () => {
  const [clientsData, setClientsData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getClientsData = () => {
    setLoading(true);
    apiAuth
      .get(`/api/master/client`)
      .then((response) => {
        let data = response.data;
        setClientsData(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const deleteClient = (id, index) => {
    let url = `/api/master/client/${id}/`;
    apiAuth
      .delete(url)
      .then((response) => {
        const newdata = response.data;
        NotificationManager.success(
          "",
          "Client Deleted Successfully",
          3000,
          null,
          null,
          ""
        );
        let clients = [...clientsData];
        clients.splice(index, 1);
        setClientsData([...clients]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    getClientsData();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Clients"
            pageTitle="Settings"
            add_new={true}
            add_new_url={`/clients/add`}
          />
        </Container>
        <Row>
          <Colxx lg="12">
            <>
              {loading ? (
                <div className="loading"></div>
              ) : (
                <>
                  {" "}
                  <Card>
                    <ClientTable
                      clientsData={clientsData}
                      deleteClient={deleteClient}
                      getClientsData={() => getClientsData()}
                      updateClient={(data, index) => {
                        let clients = [...clientsData];
                        clients.splice(index, 1, data);
                        setClientsData([...clients]);
                      }}
                    />
                  </Card>
                </>
              )}
            </>
          </Colxx>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Clients;
