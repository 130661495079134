import axios from "axios";
import { smartsightAPI } from "../components/constants/defaultValues";
import jwt from "jwt-decode";

const apiAuth = axios.create({
  baseURL: smartsightAPI,
});

const requestHandler = async (request) => {
  // Modify request here
  const token = localStorage.getItem("jwt");

  request.headers["Authorization"] = "Token " + localStorage.getItem("jwt");

  return request;
};

apiAuth.interceptors.request.use(async (request) => requestHandler(request));

export default apiAuth;
