import React from "react";
import { Card, Col, Row } from "reactstrap";
import { useHistory } from "react-router";
import PublicHeader from "../../Layouts/PublicHeader";
import PublicFooter from "../../Layouts/PublicFooter";
import { cardsData } from "./Data";
const KnowledgePage = () => {
  const history = useHistory();

  return (
    <div  style={{ display: "flex", flexDirection: "column", minHeight: "100vh", overflow: "hidden" }}>

      <PublicHeader ticketPage={localStorage.getItem("role") === "user"} />
      <div style={{ flex: "1",paddingTop: "20px" }}>
      <Row className="mt-2 mb-2" style={{ margin: "auto"}}>
        <Col lg={12} className="p-0">
          <div className="mb-0">
            {/* title */}
            <Row style={{ padding: "2%" }}>
              <Col className="d-flex justify-content-center align-items-center">
                <h2
                  className="text-primary ff-primary"
                  style={{
                    color: "#093564",
                    fontFamily: "inter",
                  }}
                >
                  Knowledge Base
                </h2>
              </Col>
            </Row>
            {/* cards */}
            <Row
              className="g-0 justify-content-center align-items-center"
              style={{
                // height: "100vh",
                padding: "2%",
              }}
            >
              {cardsData?.map((item, index) => {
                return (
                  <>
                    <Col xs="12" sm="6" md="3" lg="3" xl="3" key={index}>
                      <Card
                        className="f-flex justify-content-center fs-18 align-items-center mx-3 text-center text-primary rounded-3 cursor-pointer ff-primary"
                        style={{
                          // width: "25%",
                          fontWeight: "800",
                          height: "100px",
                          fontFamily: "Inter",
                          boxShadow: "0px 0px 21px 5px rgba(0, 0, 0, 0.10)",
                        }}
                        onClick={() =>
                          history.push(`/knowledge/${item?.route}`)
                        }
                      >
                        <span>{item?.title}</span>
                      </Card>
                    </Col>
                  </>
                );
              })}

                {/* <AuthSlider /> */}
                {/* <Col style={{ padding: "8%", paddingTop: "3%" }}></Col> */}
              </Row>
            </div>
          </Col>
        </Row>
        {/* footer */}
        </div>
      <PublicFooter />
   
    </div>
  );
};

export default KnowledgePage;
