import React, { useState, useEffect } from "react";
import moment from "moment";
import * as XLSX from "xlsx";
import Select from "react-select";
import * as FileSaver from "file-saver";
import {
  Card,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { useLocation } from "react-router-dom";
import apiAuth from "../../helpers/ApiAuth";
import TicketTable from "./TicketTable";
import { UpdateButton, customStyles } from "./Helpers";
import { priorityOptions, statusOptions } from "./options";
import BreadCrumb from "../../components/Common/BreadCrumb";
import { Colxx } from "../../components/Common/CustomBootstrap";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import NotificationManager from "../../components/Common/NotificationManager";
import { Person, XCircleFill, Check2, Trash, X } from "react-bootstrap-icons";
import CustomUpdate from "./CustomUpdate";
import {
  Group,
  PriorityHighRounded,
  TableChartOutlined,
} from "@mui/icons-material";
import adjustWidthInExcel from "../../helpers/AdjustWidthInExcel";

const Tickets = () => {
  const location = useLocation();
  const [ticketsData, setTicketsData] = useState([]);
  const [params, setParams] = useState({
    status: "",
    group: "",
  });
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selObj, setSelObj] = useState(null);
  const [curItem, setCurItem] = useState("All");
  const [contactOptions, setContactOptions] = useState([]);
  const [agentOptions, setAgentOptions] = useState([]);
  const [checkedIds, setCheckedIds] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [selSortBy, setSelSortBy] = useState({
    label: "Date Created",
    value: "Date Created",
  });
  const [pagination, setPagination] = useState({
    rowsPerPage: 10,
    totalRows: 0,
    currentPage: 1,
  });

  useEffect(() => {
    getTicketsData(pagination);
    getALLContacts();
    getALLAgents();
  }, []);

  const getTicketsData = (pgdata) => {
    const searchParams = new URLSearchParams(location?.search);
    const status = searchParams?.get("status");
    const group = searchParams?.get("group");
    let api;
    if (status !== "" && status !== null) {
      if (group !== null && group !== "all") {
        api = `/api/master/ticket?status=${status}&group=${group}`;
      } else {
        api = `/api/master/ticket?status=${status}`;
      }
    } else {
      api = `/api/master/ticket?page=${pgdata?.currentPage}`;
    }

    if (localStorage.getItem("role") === "agent") {
      if (status !== "" && status !== null) {
        if (group !== null && group !== "all") {
          api = `/api/user-group-tickets?status=${status}&group=${group}`;
        } else {
          api = `/api/user-group-tickets?status=${status}`;
        }
      } else {
        api = "/api/user-group-tickets";
      }
    }

    apiAuth
      .get(api)
      .then((response) => {
        let data = response?.data?.results;
        const totalRows = response?.data?.count;
        setPagination({
          ...pgdata,
          totalRows: totalRows,
        });
        setTicketsData(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getALLContacts = () => {
    apiAuth
      .get("/api/master/group")
      .then((res) => {
        const data = res?.data;
        const groupOptions = data?.map((item) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });
        setContactOptions(groupOptions);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getALLAgents = () => {
    apiAuth
      .get("/api/get-agents")
      .then((res) => {
        const data = res?.data;
        const agentOptions = data
          .filter((agent) => agent?.groups?.length > 0)
          .map((item) => {
            return {
              label: item?.full_name,
              value: item?.id,
              groups: item?.groups,
            };
          });

        // console.log("agentoptss-----", agentOptions);
        setAgentOptions(agentOptions);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const deleteTicket = (id) => {
    let url = `/api/master/ticket/${id}/`;
    apiAuth
      .delete(url)
      .then((response) => {
        const newdata = response.data;
        NotificationManager.success(
          "",
          "Ticket Deleted Successfully",
          3000,
          null,
          null,
          ""
        );
        getTicketsData(pagination);
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response?.data);
        console.log(error.response?.status);
        console.log(error.response?.headers);
      });
  };

  const handleDownload = () => {
    const myData = [];
    checkedItems.forEach((dd) => {
      const obj = {};
      obj["Ticket Id"] = dd?.id;
      obj["Subject"] = dd?.subject;
      obj["Description"] = dd?.description;
      obj["Group "] = dd?.group_name;
      obj["Assigned agent"] = dd?.assigned_agent_name;
      obj["Status"] = dd?.status;
      obj["Priority"] = dd?.priority;
      obj["Created At"] = moment(dd?.created_at).format("D/M/YY");
      obj["Updated At"] = moment(dd?.updated_at).format("D/M/YY");
      myData.push(obj);
    });

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = "Ticktes";
    const ws = XLSX.utils.json_to_sheet(myData);
    adjustWidthInExcel(ws, 1);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    setCheckedIds([]);
    setCheckedItems([]);
    setAllChecked(false);
  };

  const sortTicketsData = (val) => {
    if (val?.value === "Date Created") {
      const sortedData = ticketsData?.sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateB - dateA;
      });
      setTicketsData(sortedData);
    } else if (val?.value === "Priority") {
      const sortByPriority = ticketsData?.sort((a, b) => {
        const priorityOrder = {
          Urgent: 1,
          High: 2,
          Medium: 3,
          Low: 4,
        };

        const priorityA = priorityOrder[a?.priority];
        const priorityB = priorityOrder[b?.priority];

        return priorityA - priorityB;
      });
      setTicketsData(sortByPriority);
    } else if (val?.value === "Status") {
      const sortByStatus = ticketsData?.sort((a, b) => {
        const statusOrder = {
          Open: 1,
          In_Review: 2,
          Waiting_for_customer_input: 3,
          Resolved: 4,
          Dropped: 5,
        };

        const statusA = statusOrder[a?.status];
        const statusB = statusOrder[b?.status];

        return statusA - statusB;
      });
      setTicketsData(sortByStatus);
    }
  };

  const getCheckedItems = (org) => {
    let ids = [...checkedIds];
    let itms = [...checkedItems];

    if (ids.includes(org?.id)) {
      ids = ids.filter((id) => id !== org?.id);
      itms = itms.filter((itm) => itm?.id !== org?.id);
    } else {
      ids.push(org?.id);
      itms.push(org);
    }

    if (allChecked && ids.length !== ticketsData.length) {
      setAllChecked(false);
    }

    setCheckedIds(ids);
    setCheckedItems(itms);
  };

  const selectAll = () => {
    setAllChecked((prev) => !prev);

    let ids = [...checkedIds];
    let itms = [...checkedItems];

    if (allChecked) {
      ids = [];
      itms = [];
    } else {
      ticketsData.forEach((org) => {
        ids.push(org?.id);
        itms.push(org);
      });
    }

    setCheckedIds(ids);
    setCheckedItems(itms);
  };

  const setEverythingToNull = () => {
    setModal(false);
    setSelObj(null);
    setCurItem(null);
    setCheckedIds([]);
    setCheckedItems([]);
    setAllChecked(false);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="All Tickets"
            pageTitle="Settings"
            add_new={true}
            add_new_url={`/tickets/add`}
            search_functionality={true}
            searchValue={searchValue}
            setSearchValue={(val) => {
              setSearchValue(val);
            }}
          />
        </Container>
        <Row>
          <Colxx lg="12">
            <>
              {loading ? (
                <div className="loading"></div>
              ) : (
                <>
                  <Card
                    style={{
                      background: "#F5F7F9",
                      padding: "10px 20px 10px 20px",
                    }}
                    className="d-flex flex-row justify-content-between align-items-center"
                  >
                    {checkedIds?.length > 0 ? (
                      /* Buttons */
                      <div className="d-flex align-items-center gap-2">
                        {/* Type */}
                        <UpdateButton
                          onClick={() => {
                            setModal(true);
                            setCurItem("Type");
                          }}
                          icon={<TableChartOutlined />}
                          label={"Type"}
                        />

                        {/* Group */}
                        <UpdateButton
                          onClick={() => {
                            setModal(true);
                            setCurItem("Group");
                          }}
                          icon={<Group />}
                          label={"Group"}
                        />

                        {/* Assign  */}
                        <UpdateButton
                          onClick={() => {
                            setModal(true);
                            setCurItem("Assign");
                          }}
                          icon={<Person />}
                          label={"Assign"}
                        />

                        {/* Priority */}
                        <UpdateButton
                          onClick={() => {
                            setModal(true);
                            setCurItem("Priority");
                          }}
                          icon={<PriorityHighRounded />}
                          label={"Priority"}
                        />

                        {/* Status */}
                        <UpdateButton
                          onClick={() => {
                            setModal(true);
                            setCurItem("Close");
                          }}
                          icon={<X />}
                          label={"Status"}
                        />

                        {/* Bulk Update */}
                        <UpdateButton
                          onClick={() => {
                            setModal(true);
                            setCurItem("All");
                          }}
                          icon={<Check2 />}
                          label={"Bulk Update"}
                        />

                        {/* Delete */}
                        <UpdateButton
                          onClick={() => {
                            setModal(true);
                            setCurItem("Delete");
                          }}
                          icon={<Trash />}
                          label={"Delete"}
                        />
                      </div>
                    ) : (
                      <div className="d-flex align-items-center">
                        <div className="d-flex  align-items-center">
                          <div>
                            <span
                              style={{ fontWeight: 600, fontSize: "16px" }}
                              className="text-muted"
                            >
                              Sort By:
                            </span>
                          </div>
                        </div>
                        {/* Dropdown */}
                        <div
                          className="select-container"
                          style={{ width: "auto", marginLeft: "10px" }}
                        >
                          <Select
                            value={selSortBy}
                            onChange={(val) => {
                              setSelSortBy(val);
                              sortTicketsData(val);
                            }}
                            options={[
                              { label: "Date Created", value: "Date Created" },
                              { label: "Priority", value: "Priority" },
                              { label: "Status", value: "Status" },
                            ]}
                            styles={customStyles}
                          />
                        </div>
                      </div>
                    )}

                    {/* Export Button */}
                    <div
                      style={{
                        border: "0.5px solid #E6E6E6",
                        borderRadius: "5px",
                      }}
                      className="btn btn-light text-primary"
                      onClick={() => {
                        if (checkedItems.length) {
                          handleDownload();
                        } else {
                          NotificationManager.error(
                            "Export Tickets",
                            "Please select altleast one ticket.",
                            3000,
                            null,
                            null,
                            ""
                          );
                        }
                      }}
                    >
                      <CloudDownloadIcon />{" "}
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: 600,
                        }}
                      >
                        Export
                      </span>
                    </div>
                  </Card>
                  <Card>
                    <TicketTable
                      ticketsData={[
                        ...ticketsData.filter(
                          (item) =>
                            String(item?.subject)
                              ?.toLowerCase()
                              .includes(String(searchValue)?.toLowerCase()) ||
                            String(item?.description)
                              ?.toLowerCase()
                              .includes(String(searchValue)?.toLowerCase()) ||
                            String(item?.user_object?.username)
                              ?.toLowerCase()
                              .includes(String(searchValue)?.toLowerCase()) ||
                            item?.id === Number(searchValue)
                        ),
                      ]}
                      setModal={setModal}
                      setSelObj={setSelObj}
                      setCurItem={setCurItem}
                      deleteTicket={deleteTicket}
                      getTicketsData={() => getTicketsData(pagination)}
                      groupOptions={contactOptions}
                      setAgentOptions={setAgentOptions}
                      agentOptions={agentOptions}
                      priorityOptions={priorityOptions}
                      statusOptions={statusOptions}
                      getCheckedItems={getCheckedItems}
                      checkedIds={checkedIds}
                      allChecked={allChecked}
                      handleCheckAll={() => {
                        selectAll();
                      }}
                      pagination={pagination}
                      handlePagination={(data) => {
                        setPagination(data);
                        getTicketsData(data);
                      }}
                    />
                  </Card>
                </>
              )}
            </>
          </Colxx>
        </Row>
        <Modal
          isOpen={modal}
          toggle={() => setModal((prev) => !prev)}
          centered
          size="md"
          className="border-0"
          modalClassName="modal fade zoomIn"
        >
          <ModalHeader
            className="p-3"
            toggle={() => {
              setEverythingToNull();
            }}
          >
            <span className="text-primary">
              {curItem === "Delete" ? "Delete" : "Update"} Ticket(s)
            </span>
          </ModalHeader>

          <ModalBody className="modal-body">
            <CustomUpdate
              curItem={curItem}
              selObj={selObj}
              checkedItems={checkedItems}
              agentOptions={agentOptions}
              closeAddPopup={(val) => {
                setEverythingToNull();
                if (val !== "cancel") {
                  getTicketsData(pagination);
                }
              }}
            />
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default Tickets;
