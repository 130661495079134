import React, { useEffect, useState } from "react";
import { Card, Container, Row } from "reactstrap";

import CompanyTable from "./CompanyTable";
import apiAuth from "../../helpers/ApiAuth";
import BreadCrumb from "../../components/Common/BreadCrumb";
import { Colxx } from "../../components/Common/CustomBootstrap";
import NotificationManager from "../../components/Common/NotificationManager";

const Companies = () => {
  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    getCompanies();
  }, []);

  const getCompanies = () => {
    apiAuth
      .get("/api/master/company/")
      .then((res) => {
        const { data } = res;
        setLoading(false);
        setCompanies(data);
      })
      .catch((err) => {
        setLoading(false);
        NotificationManager.error(
          "Companies",
          "Company Get Error",
          3000,
          null,
          null,
          ""
        );
      });
  };

  const deleteCompany = (id, index) => {
    apiAuth
      .delete(`/api/master/company/${id}`)
      .then((res) => {
        NotificationManager.success(
          "Company Deletion",
          "Company deleted Successfully",
          3000,
          null,
          null,
          ""
        );
        let cmps = [...companies];
        cmps.splice(index, 1);
        setCompanies([...cmps]);
        // getCompanies();
      })
      .catch((err) =>
        NotificationManager.error(
          "Company Deletion",
          "Company Delete Error",
          3000,
          null,
          null,
          ""
        )
      );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Companies"
            pageTitle="Settings"
            add_new={true}
            add_new_url={`/companies/add`}
          />
        </Container>
        {/* Table */}
        <Row>
          <Colxx lg="12">
            <>
              {loading ? (
                <div className="loading"></div>
              ) : (
                <>
                  <Card>
                    <CompanyTable
                      companies={companies}
                      deleteCompany={(id, index) => deleteCompany(id, index)}
                      getCompanies={() => getCompanies()}
                      updateCompany={(data, index) => {
                        let cmps = [...companies];
                        cmps.splice(index, 1, data);
                        setCompanies([...cmps]);
                      }}
                    />
                  </Card>
                </>
              )}
            </>
          </Colxx>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Companies;
