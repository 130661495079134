import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Card, Container, Row } from "reactstrap";

import BreadCrumb from "../../components/Common/BreadCrumb";
import { Colxx } from "../../components/Common/CustomBootstrap";
import ProductTable from "./ProductTable";
import NotificationManager from "../../components/Common/NotificationManager";
import apiAuth from "../../helpers/ApiAuth";

const Products = () => {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState("");

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = () => {
    apiAuth
      .get("/api/master/project")
      .then((res) => {
        console.log("projects response", res);
        const data = res?.data?.results;
        setProducts(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        NotificationManager.error(
          "Projects",
          "Projects Get Error",
          3000,
          null,
          null,
          ""
        );
      });
  };

  const deleteProduct = (id, index) => {
    console.log("inside delete group");
    apiAuth
      .delete(`/api/master/project/${id}`)
      .then((res) => {
        NotificationManager.success(
          "Product Deletion",
          "Productt deleted Successfully",
          3000,
          null,
          null,
          ""
        );
        let pds = [...products];
        pds.splice(index, 1);
        setProducts([...pds]);
      })
      .catch((err) =>
        NotificationManager.error(
          "Product Deletion",
          "Product Delete Error",
          3000,
          null,
          null,
          ""
        )
      );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Products"
            pageTitle="Settings"
            add_new={true}
            add_new_url={`/products/add`}
          />
        </Container>
        <Row>
          <Colxx lg="12">
            <>
              {loading ? (
                <div className="loading"></div>
              ) : (
                <>
                  <Card>
                    <ProductTable
                      products={products}
                      deleteProduct={(id, index) => deleteProduct(id, index)}
                      updateProduct={(data, index) => {
                        let pds = [...products];
                        pds.splice(index, 1, data);
                        setProducts([...pds]);
                      }}
                    />
                  </Card>
                </>
              )}
            </>
          </Colxx>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Products;
