import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Card, Container, Row } from "reactstrap";

import BreadCrumb from "../../components/Common/BreadCrumb";
import { Colxx } from "../../components/Common/CustomBootstrap";
import AgentTable from "./AgentTable";
import NotificationManager from "../../components/Common/NotificationManager";
import apiAuth from "../../helpers/ApiAuth";

const Agents = () => {
  const [loading, setLoading] = useState(true);
  const [agents, setAgents] = useState([]);

  useEffect(() => {
    getAgents();
  }, []);

  const getAgents = () => {
    apiAuth
      .get("/api/get-agents/")
      .then((res) => {
        console.log(res);
        const { data } = res;
        setAgents(data);
        console.log("agents", data)
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const deleteAgent = (id) => {
    apiAuth
      .delete(`/api/master/company/${id}`)
      .then((res) =>
        NotificationManager.success(
          "Company Deletion",
          "Company deleted Successfully",
          3000,
          null,
          null,
          ""
        )
      )
      .catch((err) =>
        NotificationManager.error(
          "Company Deletion",
          "Company Delete Error",
          3000,
          null,
          null,
          ""
        )
      );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Agents" pageTitle="Settings" />
        </Container>
        <Row>
          <Colxx lg="12">
            <>
              {loading ? (
                <div className="loading"></div>
              ) : (
                <>
                  <Card>
                    <AgentTable
                      agents={agents}
                      deleteAgent={(id) => deleteAgent(id)}
                      getAgents={() => getAgents()}
                    />
                  </Card>
                </>
              )}
            </>
          </Colxx>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Agents;
