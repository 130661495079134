import React from "react";
import { Redirect } from "react-router-dom";

//Dashboard
import DashboardCrm from "../pages/DashboardCrm";

//AuthenticationInner pages
import BasicSignIn from "../pages/AuthenticationInner/Login/BasicSignIn";
import CoverSignIn from "../pages/AuthenticationInner/Login/CoverSignIn";
import BasicSignUp from "../pages/AuthenticationInner/Register/BasicSignUp";
import CoverSignUp from "../pages/AuthenticationInner/Register/CoverSignUp";
import BasicPasswReset from "../pages/AuthenticationInner/PasswordReset/BasicPasswReset";

import CoverPasswReset from "../pages/AuthenticationInner/PasswordReset/CoverPasswReset";
import BasicLockScreen from "../pages/AuthenticationInner/LockScreen/BasicLockScr";
import CoverLockScreen from "../pages/AuthenticationInner/LockScreen/CoverLockScr";
import BasicLogout from "../pages/AuthenticationInner/Logout/BasicLogout";
import CoverLogout from "../pages/AuthenticationInner/Logout/CoverLogout";
import BasicSuccessMsg from "../pages/AuthenticationInner/SuccessMessage/BasicSuccessMsg";
import CoverSuccessMsg from "../pages/AuthenticationInner/SuccessMessage/CoverSuccessMsg";
import BasicTwosVerify from "../pages/AuthenticationInner/TwoStepVerification/BasicTwosVerify";
import CoverTwosVerify from "../pages/AuthenticationInner/TwoStepVerification/CoverTwosVerify";
import Basic404 from "../pages/AuthenticationInner/Errors/Basic404";
import Cover404 from "../pages/AuthenticationInner/Errors/Cover404";
import Alt404 from "../pages/AuthenticationInner/Errors/Alt404";
import Error500 from "../pages/AuthenticationInner/Errors/Error500";

import BasicPasswCreate from "../pages/AuthenticationInner/PasswordCreate/BasicPasswCreate";
import CoverPasswCreate from "../pages/AuthenticationInner/PasswordCreate/CoverPasswCreate";
import Offlinepage from "../pages/AuthenticationInner/Errors/Offlinepage";

//login
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

// User Profile
import UserProfile from "../pages/Authentication/user-profile";

import ResetPassword from "../pages/AuthenticationInner/ResetPassword";
import ResetSuccessful from "../pages/AuthenticationInner/ResetPassword/ResetSuccessful";
import RegisterSuccessful from "../pages/AuthenticationInner/Register/RegisterSuccessful";
import Clients from "../views/Clients";
import Companies from "../views/Companies";
import Groups from "../views/Groups";
import Agents from "../views/Agents";
import Products from "../views/Products";
import Tickets from "../views/Tickets";
import Users from "../views/Users";
import AddAgent from "../views/Agents/AddAgent";
import Home from "../views/Home";
import KnowledgePage from "../views/KnowledgePage";
import TmsStartPage from "../views/KnowledgePage/TmsStartPage";
import TmsSystem from "../views/KnowledgePage/TmsSystem";
import AddCompany from "../views/Companies/AddCompany";
import AddClient from "../views/Clients/AddClient";
import AddProduct from "../views/Products/AddProduct";
import AddUser from "../views/Users/AddUser";
import AddTicket from "../views/Tickets/AddTicket";
import AddGroup from "../views/Groups/AddGroup";
// import SubmitTicket from "../views/SubmitTicket";
import TicketInfo from "../views/Tickets/TicketInfo";
import CreateTicket from "../views/UserTicket/CreateTicket";
import UserTicket from "../views/UserTicket";
import Profile from "../views/Profile";
import TicketDetail from "../views/UserTicket/TicketDetail";
import TicketTimeline from "../views/Tickets/TicketTimeline";
import UploadUsers from "../views/Users/UploadUsers";

const authProtectedRoutes = [
  { path: "/dashboard", component: DashboardCrm },
  { path: "/index", component: DashboardCrm },
  { path: "/tickets", component: Tickets },

  { path: "/add_tickets", component: CreateTicket },
  { path: "/tickets/add", component: AddTicket },
  { path: "/ticket_timeline", component: TicketTimeline },
  { path: "/ticketInfo/", component: TicketInfo },
  { path: "/groups/add", component: AddGroup },
  { path: "/groups", component: Groups },
  { path: "/agents/add", component: AddAgent },
  { path: "/agents", component: Agents },
  { path: "/products/add", component: AddProduct },
  { path: "/products", component: Products },
  { path: "/clients", component: Clients },
  { path: "/clients/add", component: AddClient },
  { path: "/users", component: Users },
  { path: "/users/add", component: AddUser },
  { path: "/users/upload", component: UploadUsers },
  { path: "/companies/add", component: AddCompany },
  { path: "/companies", component: Companies },
  {
    path: "*",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: Logout },
  { path: "/login", component: CoverSignIn },
  { path: "/reset-password", component: ResetPassword },
  { path: "/reset-password-successful", component: ResetSuccessful },
  { path: "/forgot-password", component: ForgetPasswordPage },
  { path: "/register", component: Register },
  { path: "/home", component: Home },
  { path: "/knowledge", component: KnowledgePage },
  { path: "/knowledge/:route/", component: TmsStartPage },
  { path: "/knowledge/:route/:link/", component: TmsSystem },
  // { path: "/submit-ticket", component: SubmitTicket },
  { path: "/ticket-detail", component: TicketDetail },
  { path: "/create-ticket", component: CreateTicket },
  { path: "/user_tickets", component: UserTicket },
  { path: "/profile", component: Profile },

  //AuthenticationInner pages
  { path: "/auth-signin-basic", component: BasicSignIn },
  { path: "/auth-signin-cover", component: CoverSignIn },
  { path: "/auth-signup-basic", component: BasicSignUp },
  { path: "/auth-signup-cover", component: CoverSignUp },
  { path: "/register-successful", component: RegisterSuccessful },
  { path: "/auth-pass-reset-basic", component: BasicPasswReset },
  { path: "/auth-pass-reset-cover", component: CoverPasswReset },
  { path: "/auth-lockscreen-basic", component: BasicLockScreen },
  { path: "/auth-lockscreen-cover", component: CoverLockScreen },
  { path: "/auth-logout-basic", component: BasicLogout },
  { path: "/auth-logout-cover", component: CoverLogout },
  { path: "/auth-success-msg-basic", component: BasicSuccessMsg },
  { path: "/auth-success-msg-cover", component: CoverSuccessMsg },
  { path: "/auth-twostep-basic", component: BasicTwosVerify },
  { path: "/auth-twostep-cover", component: CoverTwosVerify },
  { path: "/auth-404-basic", component: Basic404 },
  { path: "/auth-404-cover", component: Cover404 },
  { path: "/auth-404-alt", component: Alt404 },
  { path: "/auth-500", component: Error500 },

  { path: "/auth-pass-change-basic", component: BasicPasswCreate },
  { path: "/auth-pass-change-cover", component: CoverPasswCreate },
  { path: "/auth-offline", component: Offlinepage },
];

export { authProtectedRoutes, publicRoutes };
