import React, { useEffect, useRef, useState } from "react";
import { Grid, Box, Typography, Button } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Colxx } from "../../components/Common/CustomBootstrap";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Card, Container, Label, Row } from "reactstrap";
import BreadCrumb from "../../components/Common/BreadCrumb";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import TicketImg from "../../assets/images/ticket.png";
import apiAuth from "../../helpers/ApiAuth";
import NotificationManager from "../../components/Common/NotificationManager";
import moment from "moment-timezone";
import { priorityOptions, statusOptions, typeOptions } from "./options";

const AddTicket = (props) => {
  const history = useHistory();
  const fileInputRef = useRef(null);
  const curDate = new Date();
  const [loading, setLoading] = useState(false);
  const [contactOptions, setContactOptions] = useState(null);
  const [groupOptions, setGroupOptions] = useState([]);
  const [agentOptions, setAgentOptions] = useState([]);
  const [originalAgentOptions, setOriginalAgentOptions] = useState([]);
  const [clientsOptions, setClientsOptions] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedPriority, setSelectedPriority] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [descriptionData, setDescriptionData] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  // const [selectedTime, setSelectedTime] = useState("");

  const [selectedTimezone, setSelectedTimezone] = useState(null);

  const timezoneOptions = moment.tz.names()?.map((tz) => ({
    value: tz,
    label: tz,
  }));

  const getALLAgents = () => {
    apiAuth
      .get("/api/get-users")
      .then((res) => {
        const data = res?.data;
        const agentOptions = data
          ?.filter((d) => d.role[0] === "agent")
          .map((item) => {
            return {
              label: item?.full_name,
              value: item,
            };
          });
        setAgentOptions(agentOptions);
        setOriginalAgentOptions(agentOptions);
        const agent = agentOptions?.find(
          (item) => item?.value?.id === props?.selectedTicket?.assigned_agent
        );
        setSelectedAgent(agent);
      })
      .catch((err) => {
        setLoading(false);
        NotificationManager.error(
          "Agents",
          "Agents Get Error",
          3000,
          null,
          null,
          ""
        );
      });
  };
  const filterAgentOptions = (selectedGroupId) => {
    const filteredAgents = originalAgentOptions.filter((agent) => {
      return agent?.value?.group?.includes(selectedGroupId);
    });

    setAgentOptions(filteredAgents);
    setSelectedAgent(null);
  };

  const getClientsOptions = () => {
    apiAuth
      .get("/api/master/client")
      .then((res) => {
        const data = res?.data;
        const clientOpts = data?.map((item) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });
        setClientsOptions(clientOpts);
        const client = clientOpts?.find(
          (item) => item?.value === props?.selectedTicket?.client
        );
        setSelectedClient(client);
      })
      .catch((err) => {
        setLoading(false);
        NotificationManager.error(
          "Clients",
          "Client Get Error",
          3000,
          null,
          null,
          ""
        );
      });
  };

  const getALLGroups = () => {
    apiAuth
      .get("/api/master/group")
      .then((res) => {
        const data = res?.data;
        const groupOptions = data?.map((item) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });
        setGroupOptions(groupOptions);
        const group = groupOptions?.find(
          (item) => item?.value === props?.selectedTicket?.group
        );

        setSelectedGroup(group);
      })
      .catch((err) => {
        setLoading(false);
        NotificationManager.error(
          "Groups",
          "Groups Get Error",
          3000,
          null,
          null,
          ""
        );
      });
  };

  const getALLContacts = () => {
    apiAuth
      .get("/api/master/group")
      .then((res) => {
        const data = res?.data;
        const groupOptions = data?.map((item) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });
        setContactOptions(groupOptions);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getALLAgents();
    getALLContacts();
    getALLGroups();
    getClientsOptions();
    if (props?.isEdit) {
      setSelectedType({
        label: props?.selectedTicket?.type,
        value: props?.selectedTicket?.type,
      });

      setSelectedStatus({
        label: props?.selectedTicket?.status,
        value: props?.selectedTicket?.status,
      });
      setSelectedPriority({
        label: props?.selectedTicket?.priority,
        value: props?.selectedTicket?.priority,
      });
    }

    const timezone = timezoneOptions?.find((item) => {
      return {
        label: props?.selectedTicket?.timezonee,
        value: props?.selectedTicket?.timezonee,
      };
    });
    setSelectedTimezone(timezone);
  }, []);

  const extractTextFromHtml = (html) => {
    const temporaryElement = document.createElement("div");
    temporaryElement.innerHTML = html;
    return temporaryElement.textContent || temporaryElement.innerText || "";
  };

  return (
    <React.Fragment>
      <div className={props?.isEdit ? "" : "page-content"}>
        <div>
          <Grid container>
            <Grid lg={12}>
              <div className="p-3 bg-white">
                {!props?.isEdit && (
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      marginBottom: "2%",
                    }}
                  >
                    <img
                      src={TicketImg}
                      alt="Ticket"
                      height="20"
                      width="20"
                      style={{ marginRight: "10px" }}
                    />
                    <Typography variant="h5" style={{ fontWeight: "700" }}>
                      New Ticket
                    </Typography>
                  </Box>
                )}

                <Formik
                  initialValues={{
                    subject: props?.selectedTicket?.subject || "",
                    type: props?.selectedTicket?.type || "",
                    status: props?.selectedTicket?.status || "",
                    priority: props?.selectedTicket?.priority || "",
                    group: props?.selectedTicket?.group || "",
                    assigned_agent: props?.selectedTicket?.assigned_agent || "",
                    description: "",
                    date: curDate,
                    time: moment(curDate).toDate(),
                    user: props?.isEdit
                      ? props?.selectedTicket?.user
                      : localStorage.getItem("user_id"),
                    timezonee:
                      props?.selectedTicket?.timezonee || "Asia/Kolkata",
                    client: props?.selectedTicket?.client || "",
                    remarks: props?.selectedTicket?.remarks || "",
                  }}
                  validationSchema={Yup.object({
                    subject: Yup.string().ensure().required("Required!"),
                    type: Yup.string().ensure().required("Required!"),
                    status: Yup.string().ensure().required("Required!"),
                    priority: Yup.string().ensure().required("Required!"),
                    group: Yup.string().ensure().required("Required!"),
                    assigned_agent: Yup.string().ensure().required("Required!"),
                    client: Yup.string().ensure().required("Required!"),
                    remarks: props?.isEdit
                      ? Yup.string().required("Required!")
                      : Yup.string().ensure(),
                  })}
                  onSubmit={(values) => {
                    const textContent = extractTextFromHtml(descriptionData);
                    if (!props.isEdit) {
                      values["description"] = textContent;
                      values["date"] = values["date"]?.toISOString();
                      values["time"] = moment
                        ?.tz(values["time"], values["timezonee"])
                        ?.format("HH:mm:ss");
                    }

                    if (props?.isEdit) {
                      apiAuth
                        .patch(
                          `/api/master/ticket/${props.selectedTicket?.id}/`,
                          values
                        )
                        .then((res) => {
                          setLoading(false);
                          NotificationManager.success(
                            "Ticket",
                            "Ticket Updated Successfully",
                            3000,
                            null,
                            null,
                            ""
                          );
                          props.closeAddPopup();
                        })
                        .catch((err) => {
                          setLoading(false);
                          NotificationManager.error(
                            "Ticket",
                            "Ticket Update Error",
                            3000,
                            null,
                            null,
                            ""
                          );
                        });
                    } else {
                      apiAuth
                        .post("/api/master/ticket/", values)
                        .then((res) => {
                          const { data } = res;
                          setLoading(false);
                          NotificationManager.success(
                            "Ticket",
                            "Ticket Created Successfully",
                            3000,
                            null,
                            null,
                            ""
                          );
                          history.push("/tickets");
                        })
                        .catch((err) => {
                          setLoading(false);
                          NotificationManager.error(
                            "Ticket",
                            "Ticket Create Error",
                            3000,
                            null,
                            null,
                            ""
                          );
                        });
                    }
                  }}
                >
                  {({ values, errors, touched, setFieldValue }) => (
                    <Form className="av-tooltip tooltip-label-bottom">
                      {!props.isEdit && (
                        <div className="d-flex gap-2">
                          <Grid item lg={6} xs={12}>
                            <div className="mb-3">
                              <label
                                htmlFor="date"
                                className="form-label text-primary"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  wordWrap: "break-word ",
                                }}
                              >
                                Date
                                <span className="text-danger">*</span>
                              </label>
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <DatePicker
                                  customInput={
                                    <input
                                      style={{
                                        borderRadius: "10px",
                                        height: "40px",
                                        paddingLeft: "20px",
                                        border:
                                          "1px solid var(--vz-input-border)",
                                      }}
                                    />
                                  }
                                  selected={values["date"]}
                                  onChange={(date) => {
                                    setFieldValue("date", date);
                                    setSelectedDate(date);
                                  }}
                                  dateFormat="d MMMM yyyy"
                                />
                                <div
                                  style={{
                                    position: "relative",
                                  }}
                                >
                                  <span
                                    style={{
                                      position: "absolute",
                                      top: 8,
                                      right: 10,
                                      fill: "red",
                                    }}
                                  >
                                    <img
                                      src="/calendar.svg"
                                      alt="calendar"
                                      width="20px"
                                      height="20px"
                                    />
                                  </span>
                                </div>
                              </div>
                              {errors.date && touched.date && (
                                <div className="invalid-feedback d-block">
                                  {errors.date}
                                </div>
                              )}
                            </div>
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <div className="mb-3">
                              <label
                                htmlFor="time"
                                className="form-label text-primary"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  wordWrap: "break-word ",
                                }}
                              >
                                Time
                                <span className="text-danger">*</span>
                              </label>
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <DatePicker
                                  customInput={
                                    <input
                                      style={{
                                        borderRadius: "10px",
                                        height: "40px",
                                        paddingLeft: "20px",
                                        border:
                                          "1px solid var(--vz-input-border)",
                                      }}
                                    />
                                  }
                                  selected={values["time"]}
                                  onChange={(date) => {
                                    setFieldValue("time", date);
                                  }}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15} // Optional: Set the time interval between selections (in minutes)
                                  timeCaption="time"
                                  timeFormat="h:mm aa"
                                  dateFormat="h:mm aa"
                                />
                                <div
                                  style={{
                                    position: "relative",
                                  }}
                                >
                                  <span
                                    style={{
                                      position: "absolute",
                                      top: 8,
                                      right: 10,
                                      fill: "red",
                                    }}
                                  >
                                    <AccessTimeIcon />
                                  </span>
                                </div>
                              </div>
                              {errors.time && touched.time && (
                                <div className="invalid-feedback d-block">
                                  {errors.time}
                                </div>
                              )}
                            </div>
                          </Grid>
                        </div>
                      )}

                      {!props.isEdit && (
                        <Grid item lg={12} xs={12} className="mb-3">
                          <label
                            htmlFor="subject"
                            className="form-label text-primary"
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              wordWrap: "break-word ",
                            }}
                          >
                            Subject
                            <span className="text-danger">*</span>
                          </label>
                          <Field
                            className="form-control"
                            name="subject"
                            placeholder="Write something that describes this ticket."
                            style={{
                              borderRadius: "10px",
                            }}
                          />
                          <ErrorMessage
                            name="subject"
                            render={(msg) => (
                              <div className="text-danger">{msg}</div>
                            )}
                          />
                        </Grid>
                      )}

                      {!props.isEdit && (
                        <Row className="mb-3">
                          <Colxx lg={12}>
                            <div
                              className="my-1 form-label text-primary"
                              style={{
                                fontSize: "16px",
                                fontWeight: 600,
                                wordWrap: "break-word ",
                              }}
                            >
                              Description
                            </div>
                            <div
                              style={{
                                height: "150px",
                                overflowY: "hidden",
                                border: "0.5px solid #D3D3D3",
                                borderRadius: "10px",
                              }}
                            >
                              <ReactQuill
                                theme="snow"
                                value={descriptionData}
                                onChange={(data) => {
                                  setDescriptionData(data);
                                }}
                                placeholder="Write something that describes this ticket."
                                style={{ height: "100%" }}
                              />
                            </div>
                          </Colxx>
                        </Row>
                      )}

                      <Grid item lg={12} xs={12} className="mb-3">
                        <div className="mb-3">
                          <label
                            htmlFor="type"
                            className="form-label text-primary"
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              wordWrap: "break-word ",
                            }}
                          >
                            Type
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            options={typeOptions}
                            value={selectedType}
                            onChange={(data) => {
                              setFieldValue("type", data?.value);
                              setSelectedType(data);
                            }}
                            placeholder=""
                          />
                          <ErrorMessage
                            name="type"
                            render={(msg) => (
                              <div className="text-danger">{msg}</div>
                            )}
                          />
                        </div>
                      </Grid>

                      <Grid item lg={12} xs={12} className="mb-3">
                        <div className="mb-3">
                          <label
                            htmlFor="status"
                            className="form-label text-primary"
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              wordWrap: "break-word ",
                            }}
                          >
                            Status
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            options={statusOptions}
                            value={selectedStatus}
                            onChange={(data) => {
                              setFieldValue("status", data?.value);
                              setSelectedStatus(data);
                            }}
                            placeholder=""
                          />
                          <ErrorMessage
                            name="status"
                            render={(msg) => (
                              <div className="text-danger">{msg}</div>
                            )}
                          />
                        </div>
                      </Grid>

                      <Grid item lg={12} xs={12} className="mb-3">
                        <div className="mb-3">
                          <label
                            htmlFor="priority"
                            className="form-label text-primary"
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              wordWrap: "break-word ",
                            }}
                          >
                            Priority
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            options={priorityOptions}
                            value={selectedPriority}
                            onChange={(data) => {
                              setFieldValue("priority", data?.value);
                              setSelectedPriority(data);
                            }}
                            placeholder=""
                          />
                          <ErrorMessage
                            name="priority"
                            render={(msg) => (
                              <div className="text-danger">{msg}</div>
                            )}
                          />
                        </div>
                      </Grid>

                      <div className="d-flex gap-2">
                        <Grid item lg={6} xs={12} className="mb-3">
                          <div className="mb-3">
                            <label
                              htmlFor="group"
                              className="form-label text-primary"
                              style={{
                                fontSize: "16px",
                                fontWeight: 600,
                                wordWrap: "break-word ",
                              }}
                            >
                              Group
                              <span className="text-danger">*</span>
                            </label>
                            <Select
                              options={groupOptions}
                              value={selectedGroup}
                              onChange={(data) => {
                                setFieldValue("group", data?.value);
                                setSelectedGroup(data);
                                filterAgentOptions(data?.label);
                              }}
                              placeholder=""
                            />
                            <ErrorMessage
                              name="group"
                              render={(msg) => (
                                <div className="text-danger">{msg}</div>
                              )}
                            />
                          </div>
                        </Grid>

                        <Grid item lg={6} xs={12} className="mb-3">
                          <div className="mb-3">
                            <label
                              htmlFor="assigned_agent"
                              className="form-label text-primary"
                              style={{
                                fontSize: "16px",
                                fontWeight: 600,
                                wordWrap: "break-word ",
                              }}
                            >
                              Agent
                              <span className="text-danger">*</span>
                            </label>
                            <Select
                              options={agentOptions}
                              value={selectedAgent}
                              onChange={(data) => {
                                setFieldValue(
                                  "assigned_agent",
                                  data?.value?.id
                                );
                                setSelectedAgent(data);
                              }}
                              placeholder=""
                            />
                            <ErrorMessage
                              name="assigned_agent"
                              render={(msg) => (
                                <div className="text-danger">{msg}</div>
                              )}
                            />
                          </div>
                        </Grid>
                      </div>

                      {props.isEdit && (
                        <Grid item lg={12} xs={12} className="mb-3">
                          <label
                            htmlFor="remarks"
                            className="form-label text-primary"
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              wordWrap: "break-word ",
                            }}
                          >
                            Remarks
                            <span className="text-danger">*</span>
                          </label>
                          <Field
                            className="form-control"
                            name="remarks"
                            placeholder="Enter Remarks"
                            style={{
                              borderRadius: "10px",
                            }}
                          />
                          <ErrorMessage
                            name="remarks"
                            render={(msg) => (
                              <div className="text-danger">{msg}</div>
                            )}
                          />
                        </Grid>
                      )}

                      {!props.isEdit && (
                        <div className="d-flex gap-2">
                          <Grid item lg={6} xs={12} className="mb-3">
                            <div className="mb-3">
                              <label
                                htmlFor="client"
                                className="form-label text-primary"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  wordWrap: "break-word ",
                                }}
                              >
                                Client
                                <span className="text-danger">*</span>
                              </label>
                              <Select
                                options={clientsOptions}
                                value={selectedClient}
                                onChange={(data) => {
                                  setFieldValue("client", data?.value);
                                  setSelectedClient(data);
                                }}
                                placeholder=""
                              />
                              <ErrorMessage
                                name="client"
                                render={(msg) => (
                                  <div className="text-danger">{msg}</div>
                                )}
                              />
                            </div>
                          </Grid>
                          {props.isEdit && (
                            <Grid item lg={6} xs={12} className="mb-3">
                              <div className="mb-3">
                                <label
                                  htmlFor="remarks"
                                  className="form-label text-primary"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: 600,
                                    wordWrap: "break-word ",
                                  }}
                                >
                                  Remarks
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  className="form-control"
                                  name="remarks"
                                  placeholder=""
                                />
                                <ErrorMessage
                                  name="remarks"
                                  render={(msg) => (
                                    <div className="text-danger">{msg}</div>
                                  )}
                                />
                              </div>
                            </Grid>
                          )}
                        </div>
                      )}

                      {!props.isEdit && (
                        <Grid item lg={6} xs={12} className="mb-3">
                          <div className="mb-3">
                            <label
                              htmlFor="timezonee"
                              className="form-label text-primary"
                              style={{
                                fontSize: "16px",
                                fontWeight: 600,
                                wordWrap: "break-word ",
                              }}
                            >
                              Timezone
                            </label>
                            <Select
                              options={timezoneOptions}
                              value={selectedTimezone}
                              onChange={(data) => {
                                setFieldValue("timezonee", data?.value);
                                setSelectedTimezone(data);
                              }}
                              placeholder=""
                            />
                            <ErrorMessage
                              name="timezonee"
                              render={(msg) => (
                                <div className="text-danger">{msg}</div>
                              )}
                            />
                          </div>
                        </Grid>
                      )}

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                          fontSize: "16px",
                        }}
                      >
                        {loading ? (
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <div className="mt-4 mb-3">
                            <Button
                              className="btn "
                              type="submit"
                              style={{
                                color: "white",
                                fontWeight: 500,
                                background: "#093564",
                                borderRadius: "20px",
                                padding: "8px 40px",
                              }}
                            >
                              {props?.isEdit ? "Update" : "Create"}
                            </Button>
                          </div>
                        )}
                        <div className="mt-4 mb-3 ms-3">
                          <Button
                            className="btn text-primary"
                            style={{
                              fontWeight: 500,
                              padding: "8px 0px",
                            }}
                            onClick={() => {
                              props?.isEdit
                                ? props?.closeAddPopup()
                                : props?.history.goBack();
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddTicket;
