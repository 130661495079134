import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Col, Container, Input, Label, Row, Button } from "reactstrap";
import AuthSlider from "../authCarousel";
import { Formik, Form, Field } from "formik";
import { apiError, loginUser } from "../../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import NotificationManager from "../../../components/Common/NotificationManager";
import { GoogleLogin } from "react-google-login";

const ResetPassword = (props) => {
  const dispatch = useDispatch();
  const { logError } = useSelector((state) => ({
    logError: state.Login.error,
  }));
  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    if (logError) {
      NotificationManager.error("", logError, 3000, null, null, "");
      dispatch(apiError(""));
    }
  }, [logError]);

  const validateEmail = (value) => {
    let error;
    if (!value) {
      error = "Please enter your email address";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  };

  const validatePassword = (value) => {
    let error;
    let regExp = new RegExp(
      "^(?=.*\\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$"
    );

    if (!value) {
      error = "Please enter your password";
    } else if (value.length < 6) {
      error = "Value must be longer than 5 characters";
    } else if (!regExp.test(value)) {
      error =
        "Password must have at least One Uppercase, One Number, One Lowercase, And One Special Character";
    }

    return error;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const responseSuccessGoogle = (response) => {
    dispatch(loginUser(response, props.history, "google"));
  };

  useEffect(() => {
    /* global google */
    if (window.google) {
      google.accounts.id.initialize({
        client_id:
          "618283004370-4o0tkge5ja8b9bkti0alkv3rcajo8bjj.apps.googleusercontent.com",
        callback: responseSuccessGoogle,
      });

      google.accounts.id.renderButton(document.getElementById("loginDiv"), {
        theme: "filled_black",
        text: "continue_with",
        shape: "pill",
      });
    }
  }, [responseSuccessGoogle]);

  return (
    <React.Fragment>
      <div className=" overflow-hidden ">
        <Row>
          <Col lg={12}>
            <Card className="overflow-hidden h-100 mb-0">
              <Row className="g-0" style={{ height: "100vh" }}>
                <AuthSlider />

                <Col lg={6} style={{ margin: "auto" }}>
                  <div className="p-lg-5 p-4">
                    <div>
                      <h1 className="text-dark">Reset Password</h1>
                    </div>

                    <div className="mt-4">
                      <Formik
                        initialValues={{
                          // email: "",
                          password: "",
                          cpassword: "",
                        }}
                        onSubmit={(values) => {
                          dispatch(loginUser(values, props.history));
                        }}
                      >
                        {({ errors, touched }) => (
                          <Form className="av-tooltip tooltip-label-bottom">
                            <div className="mb-3">
                              <div className="float-end"></div>
                              <Label
                                className="form-label"
                                htmlFor="password-input"
                              >
                                Enter Password
                              </Label>
                              <div className="position-relative auth-pass-inputgroup mb-3">
                                <Field
                                  className="form-control"
                                  type={showPassword ? "text" : "password"}
                                  name="password"
                                  validate={validatePassword()}
                                />

                                <div
                                  className={[
                                    "btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted",
                                  ].join(" ")}
                                  onClick={(e) => {
                                    setShowPassword((prev) => !prev);
                                  }}
                                  id="password-addon"
                                >
                                  <i
                                    className={[
                                      showPassword
                                        ? "ri-eye-close-line"
                                        : "ri-eye-fill",
                                      "align-middle",
                                    ].join(" ")}
                                  ></i>
                                </div>

                                {errors.password && touched.password && (
                                  <div className="invalid-feedback d-block">
                                    {errors.password}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="password-input"
                              >
                                Re-Enter Password
                              </Label>
                              <div className="position-relative auth-pass-inputgroup mb-3">
                                <Field
                                  className="form-control"
                                  type={showPassword ? "text" : "password"}
                                  name="cpassword"
                                  validate={validatePassword()}
                                />

                                <div
                                  className={[
                                    "btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted",
                                  ].join(" ")}
                                  onClick={(e) => {
                                    setShowPassword((prev) => !prev);
                                  }}
                                  id="password-addon"
                                >
                                  <i
                                    className={[
                                      showPassword
                                        ? "ri-eye-close-line"
                                        : "ri-eye-fill",
                                      "align-middle",
                                    ].join(" ")}
                                  ></i>
                                </div>

                                {errors.cpassword && touched.cpassword && (
                                  <div className="invalid-feedback d-block">
                                    {errors.cpassword}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="mt-4">
                              <Button
                                color="success"
                                className="w-100"
                                type="submit"
                              >
                                Submit
                              </Button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>

                    <div className="mt-5 text-center">
                      <p className="mb-0">
                        Remember the password ?{" "}
                        <a
                          href="/login"
                          className="fw-bold text-primary text-decoration-underline"
                        >
                          {" "}
                          Sigin
                        </a>{" "}
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default ResetPassword;
