import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import {
  Row,
  Col,
  InputGroup,
  Input,
  InputGroupText,
  Card,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import "./commentStyles.css";
import { ChevronLeft } from "react-bootstrap-icons";
import LanguageIcon from "@mui/icons-material/Language";
import { isEmpty } from "lodash";
import apiAuth from "../../helpers/ApiAuth";
import NotificationManager from "../../components/Common/NotificationManager";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useLocation } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import * as Yup from "yup";
import TicketTimeLine from "./TicketTimeline";
import { priorityOptions, statusOptions, typeOptions } from "./options";
import EditComment from "./EditComment";
import FileThumbnail from "./FileThumbnail";

const Tickets = () => {
  const history = useHistory();
  const location = useLocation();
  const [ticketData, setTicketData] = useState(location?.state || {});
  const [loading, setLoading] = useState(true);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedPriority, setSelectedPriority] = useState(null);
  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [agentOptions, setAgentOptions] = useState([]);
  const [commentsData, setCommentsData] = useState([]);
  const [commentValue, setCommentValue] = useState("");
  const [id, setId] = useState();
  const [data, setdata] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [hoveredCommentId, setHoveredCommentId] = useState(null);
  const [deleteIndex, setDeleteIndex] = useState(null);

  useEffect(() => {
    getTicketsData();
    getUserComments();
    getALLGroups();
    getALLAgents();

    const data = JSON.parse(localStorage.getItem("authUser"));
    setdata(data);
  }, []);

  const getALLGroups = () => {
    apiAuth
      .get("/api/master/group")
      .then((res) => {
        const data = res?.data;
        const groupOptions = data?.map((item) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });
        setGroupOptions(groupOptions);
        const group = groupOptions?.find(
          (item) => item?.value === ticketData?.group
        );
        setSelectedGroup(group);
      })
      .catch((err) => {
        setLoading(false);
        NotificationManager.error(
          "Groups",
          "Groups Get Error",
          3000,
          null,
          null,
          ""
        );
      });
  };

  const getUserComments = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get("id");
    apiAuth
      .get(`/api/commentlist/?ticket_id=${id}`)
      .then((res) => {
        const data = res?.data.map((comment) => ({
          ...comment,
          isEditing: false,
        }));
        setCommentsData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getALLAgents = (item) => {
    apiAuth
      .get("/api/get-agents")
      .then((res) => {
        const data = res?.data;
        const agentOptions = data
          .filter((agent) => agent?.groups?.length > 0)
          .map((item) => {
            return {
              label: item?.full_name,
              value: item?.id,
              groups: item?.groups,
            };
          });
        setAgentOptions(agentOptions);
        const agent = agentOptions?.find(
          (item) => item?.value === ticketData?.assigned_agent
        );
        setSelectedAgent(agent);
      })
      .catch((err) => {
        setLoading(false);
        NotificationManager.error(
          "Agents",
          "Agents Get Error",
          3000,
          null,
          null,
          ""
        );
      });
  };

  const getTicketsData = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get("id");
    const page = searchParams.get("page");
    apiAuth
      .get(`/api/master/ticket/${id}`)
      .then((response) => {
        let data = response?.data;

        setSelectedType({
          label: data?.type,
          value: data?.type,
        });
        setSelectedStatus({
          label: data?.status,
          value: data?.status,
        });
        setSelectedPriority({
          label: data?.priority,
          value: data?.priority,
        });

        setTicketData(data);

        setId(id);

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getBadgeValue = (str) => {
    let list = str?.split(" ");
    if (list !== undefined) {
      if (list?.length > 1) {
        return (
          list[0].charAt(0).toUpperCase() + list[1].charAt(0).toUpperCase()
        );
      } else {
        return list[0].charAt(0).toUpperCase();
      }
    }
  };
  const hanlderFunc = (e) => {
    setCommentValue(e.target.value);
  };
  const commentHanlder = () => {
    if (commentValue.trim() !== "") {
      const newItem = {
        text: commentValue,
        user: data?.id,
        ticket: ticketData?.id,
      };
      apiAuth
        .post(`/api/master/comment/`, newItem)
        .then((res) => {
          NotificationManager.success(
            "Comment",
            "Comment Added Successfully",
            3000,
            null,
            null,
            ""
          );
          getUserComments();
          setCommentValue("");
        })
        .catch((err) => {
          console.log(err);
          NotificationManager.error(
            "Comment",
            "Comment Add Error",
            3000,
            null,
            null,
            ""
          );
        });
    }
  };

  const getCapitalise = (str) => {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }
  };

  const handleEditClick = (commentId) => {
    // Find the comment in commentsData with a matching ID
    setCommentsData((prevComments) =>
      prevComments.map((comment) => {
        if (comment.comment_id === commentId) {
          // Toggle isEditing for the clicked comment
          return { ...comment, isEditing: !comment.isEditing };
        }
        return comment;
      })
    );
  };

  const handleSaveComment = (id, editedComment, actualComment) => {
    if (editedComment?.trim() !== actualComment?.trim()) {
      apiAuth
        .patch(`/api/master/comment/${id}/`, { text: editedComment })
        .then((res) => {
          setLoading(false);
          NotificationManager.success(
            "Comment",
            "Comment Updated Successfully",
            3000,
            null,
            null,
            ""
          );
          getUserComments();
          setIsEditing(false);
        })
        .catch((err) => {
          setLoading(false);
          NotificationManager.error(
            "Comment",
            "Comment Update Error",
            3000,
            null,
            null,
            ""
          );
        });
    } else {
      NotificationManager.error(
        "Comment",
        "No Changes made to the comment",
        3000,
        null,
        null,
        ""
      );
      getUserComments();
    }
  };

  const handleCancel = (commentId) => {
    setCommentsData((prevComments) =>
      prevComments.map((comment) => {
        if (comment.comment_id === commentId) {
          // Toggle isEditing for the clicked comment
          return { ...comment, isEditing: !comment.isEditing };
        }
        return comment;
      })
    );
  };

  const deleteComment = (id, index) => {
    let url = `/api/master/comment/${id}/`;
    apiAuth
      .delete(url)
      .then((response) => {
        const finalComments = [...commentsData];
        finalComments.splice(index, 1);
        setCommentsData(finalComments);
        setDeleteId(null);
        setDeleteIndex(null);
        NotificationManager.success(
          "",
          "Comment Deleted Successfully",
          3000,
          null,
          null,
          ""
        );
      })
      .catch(function (error) {
        console.log(error);
        if (error.response?.status == 403) {
          NotificationManager.error(
            "",
            "Permission denied",
            3000,
            null,
            null,
            ""
          );
        }
        // console.log("statusssssssssss", error.response?.data);
        console.log(error.response?.status);
        console.log(error.response?.headers);
      });
  };

  const handleCommentHover = (commentId) => {
    // Set the commentId to show icons for the specific comment
    setHoveredCommentId(commentId);
  };

  const handleCommentLeave = () => {
    // Reset the commentId to hide icons when leaving the comment area
    setHoveredCommentId(null);
  };

  return (
    <React.Fragment>
      <div className="bg-white page-content overflow-hidden">
        <div className="mb-4 d-flex align-items-center">
          <ChevronLeft
            size={24}
            style={{ cursor: "pointer" }}
            onClick={() => history.goBack()}
          />
          <span className="fw-bold fs-20">
            #{ticketData?.id && ticketData?.id}
          </span>
        </div>
        <Row className="px-3">
          <Col
            lg={6}
            style={{
              // height: "100vh",
              overflowY: "auto",
              marginBottom: "10px",
            }}
          >
            <div className="mb-3 d-flex align-items-center">
              <LanguageIcon size={24} />
              <span className="fw-bold fs-20 " style={{ paddingLeft: "12px" }}>
                {/* Falcon site is unable to login */}
                {ticketData?.subject && ticketData?.subject}
              </span>
            </div>
            <div className="d-flex mb-3 align-items-center">
              <div
                style={{
                  width: "35px",
                  height: "35px",
                  backgroundColor: "#FFD9D9",
                  fontWeight: 600,
                  display: "flex",
                  borderRadius: "5px",
                  justifyContent: "center", // Center horizontally
                  alignItems: "center", // Center vertically
                }}
              >
                {getBadgeValue(ticketData?.user_object?.username)}
              </div>
              <div
                className="d-flex flex-column "
                style={{ paddingLeft: "10px" }}
              >
                <span
                  className="text-primary fs-15"
                  style={{ fontWeight: 500 }}
                >
                  <span className="fw-bold fs-16">
                    {getCapitalise(ticketData?.user_object?.username)}
                  </span>{" "}
                  reported via email
                </span>
                {/* <span> 3 days ago(Tue, 26 Sep 2023 at 12:45 PM)</span>{" "} */}
                <em style={{ fontSize: "14px" }}>
                  {moment(ticketData?.created_at).fromNow()}{" "}
                  {`(${moment(ticketData?.created_at).format(
                    "ddd, DD MMM YYYY [at] hh:mm A"
                  )})`}
                </em>
              </div>
            </div>
            <div className="d-flex align-items-center ">
              <div style={{ marginRight: "12px" }}>
                <ChevronLeft size={18} />
              </div>

              <div className="d-flex flex-column ">
                <div>
                  <span className="fw-bold">To</span> : support@falconavl.com
                </div>
              </div>
            </div>
            <div
              className="d-flex flex-column mt-4 fs-15 fw-500"
              style={{ marginLeft: "28px" }}
            >
              <div
                className="fw-600 text-dark"
                style={{ marginBottom: "10px" }}
              >
                Team,
              </div>

              <div>
                {/* Falcon site is unable to login Please look into below
                  screenshot for your reference */}
                {ticketData?.description && ticketData?.description}
              </div>

              <div className="my-3 d-flex flex-column justify-content-center ">
                {ticketData?.attachment ? (
                  ticketData?.attachment.map((dd, i) => {
                    return (
                      <div key={i}>
                        <FileThumbnail attachment={dd} />
                      </div>
                    );
                  })
                ) : (
                  <></>
                )}
              </div>

              <div className="d-flex flex-column fw-600 text-dark mt-3 mb-3">
                <p style={{ marginBottom: "0px" }}>Thanks & Regards</p>
                <span>
                  {ticketData?.user_object?.username?.charAt(0).toUpperCase() +
                    ticketData?.user_object?.username?.slice(1).toLowerCase()}
                </span>
                {ticketData?.user_object?.mobile && (
                  <span>Off:- {ticketData?.user_object?.mobile}</span>
                )}
              </div>
            </div>
            <div className="">
              <Col item lg={12} style={{ fontSize: "15px" }}>
                {commentsData?.length > 0 &&
                  commentsData?.map((item, index) => {
                    return (
                      <>
                        <div
                          className="d-flex mb-3 align-items-center justify-content-between comment"
                          key={item?.comment_id}
                          style={{
                            padding: "12px",
                            background:
                              item?.user === "admin" ? "white" : "#F8F8F8",
                          }}
                          onMouseEnter={() =>
                            handleCommentHover(item.comment_id)
                          }
                          onMouseLeave={handleCommentLeave}
                        >
                          <div className="d-flex">
                            <div
                              style={{
                                width: "35px",
                                height: "35px",
                                backgroundColor:
                                  item?.user === "admin"
                                    ? "#D9E8FF"
                                    : "#FFD9D9",
                                color: "#093564",
                                display: "flex",
                                borderRadius: "5px",
                                justifyContent: "center",
                                alignItems: "center",
                                fontWeight: 600,
                              }}
                            >
                              {getBadgeValue(item?.user)}
                            </div>
                            <div
                              className="d-flex flex-column"
                              style={{ paddingLeft: "10px" }}
                            >
                              <div>
                                <span
                                  className="fw-bold text-primary"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "700",
                                  }}
                                >
                                  {item?.user?.charAt(0).toUpperCase() +
                                    item?.user?.slice(1).toLowerCase()}{" "}
                                </span>
                                <span className="text-primary fw-500">
                                  made a comment
                                </span>

                                {item?.updated_at !== null ? (
                                  <span
                                    className="text-muted"
                                    style={{
                                      fontSize: "13px",
                                      marginLeft: "10px",
                                      fontStyle: "italic",
                                    }}
                                  >
                                    Edited{" "}
                                    {moment(item?.updated_at).format(
                                      "ddd, DD MMM YYYY [at] h:mm A"
                                    )}
                                  </span>
                                ) : (
                                  <span
                                    className="text-muted"
                                    style={{
                                      fontSize: "13px",
                                      marginLeft: "10px",
                                      fontStyle: "italic",
                                    }}
                                  >
                                    {moment(item?.created_at).format(
                                      "ddd, DD MMM YYYY [at] h:mm A"
                                    )}
                                  </span>
                                )}
                              </div>
                              <div>
                                {item?.isEditing && item?.role !== "user" ? (
                                  <EditComment
                                    item={item}
                                    handleSaveComment={handleSaveComment}
                                    handleCancel={handleCancel}
                                  />
                                ) : (
                                  <span
                                    className="text-dark"
                                    style={{ fontWeight: 600 }}
                                  >
                                    {item?.comment}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>

                          {item?.role === localStorage.getItem("role") && (
                            <div
                              style={{
                                marginBottom: "20px",
                                display:
                                  hoveredCommentId === item.comment_id
                                    ? "block"
                                    : "none",
                              }}
                              className="ms-auto comment-icons"
                            >
                              <i
                                className="ri-pencil-fill align-bottom me-2 text-primary cursor-pointer"
                                onClick={() =>
                                  handleEditClick(item?.comment_id)
                                }
                              ></i>
                              <i
                                className="ri-delete-bin-fill align-bottom me-2 text-danger cursor-pointer"
                                onClick={() => {
                                  setDeleteModal(true);
                                  setDeleteId(item?.comment_id);
                                  setDeleteIndex(index);
                                }}
                              ></i>
                            </div>
                          )}
                        </div>
                      </>
                    );
                  })}
                <div
                  style={{
                    width: "100%",
                    padding: "10px",
                    marginTop: "10px",
                    backgroundColor: "#F8F8F8",
                  }}
                >
                  <InputGroup className="">
                    <InputGroupText
                      className="me-2"
                      style={{
                        // backgroundColor: "#FFD9D9",
                        backgroundColor:
                          data?.fullname === "admin" ? "#D9E8FF" : "#FFD9D9",
                        display: "flex",
                        fontWeight: 600,
                        border: "none",
                        borderRadius: "5px",
                        justifyContent: "center", // Center horizontally
                        alignItems: "center", // Center vertically
                      }}
                    >
                      {getBadgeValue(data?.fullname ? data?.fullname : "admin")}
                    </InputGroupText>
                    <Input
                      className="form-control"
                      name="subject"
                      placeholder="Add Comment"
                      style={{
                        border: "none",
                      }}
                      value={commentValue}
                      // onBlur={commentHanlder}
                      onChange={hanlderFunc}
                    />
                    <InputGroupText
                      className="border-0"
                      onClick={commentHanlder}
                      style={{
                        color: "#fff",
                        background: "#EB3939",
                        fontWeight: 600,
                        textTransform: "capitalize",
                        cursor: "pointer",
                        marginLeft: "10px",
                        padding: "5px 30px",
                        borderRadius: "20px",
                      }}
                    >
                      <AddIcon />
                      Add
                    </InputGroupText>
                  </InputGroup>
                </div>
              </Col>
            </div>
          </Col>
          <Col
            lg={4}
            style={{
              // height: "100vh",
              overflowY: "auto",
              backgroundColor: "#f3f3f9",
              marginBottom: "10px",
            }}
          >
            <TicketTimeLine />
          </Col>

          <Col
            lg={2}
            style={{
              // height: "100vh",
              overflowY: "auto",
            }}
          >
            <div className="d-flex flex-column">
              <h4 className="text-primary">Properties</h4>
            </div>

            <div className="mt-4">
              {ticketData && !isEmpty(ticketData) && (
                <Formik
                  initialValues={{
                    type: ticketData?.type || "",
                    status: ticketData?.status || "",
                    priority: ticketData?.priority || "",
                    group: ticketData?.group || "",
                    assigned_agent: ticketData?.assigned_agent,
                    remarks: ticketData?.remarks || "",
                  }}
                  validationSchema={Yup.object({
                    remarks: Yup.string().required("Required!"),
                  })}
                  onSubmit={(values) => {
                    apiAuth
                      .patch(`/api/master/ticket/${ticketData?.id}/`, values)
                      .then((res) => {
                        setLoading(false);
                        NotificationManager.success(
                          "Ticket",
                          "Ticket Updated Successfully",
                          3000,
                          null,
                          null,
                          ""
                        );
                        history.push("/tickets");
                      })
                      .catch((err) => {
                        setLoading(false);
                        NotificationManager.error(
                          "Ticket",
                          "Ticket Update Error",
                          3000,
                          null,
                          null,
                          ""
                        );
                      });
                  }}
                >
                  {({ values, errors, touched, setFieldValue }) => (
                    <Form className="av-tooltip tooltip-label-bottom">
                      <div className="mb-3">
                        <label
                          htmlFor="type"
                          className="form-label text-primary"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            wordWrap: "break-word ",
                          }}
                        >
                          Type
                        </label>
                        <Select
                          options={typeOptions}
                          value={selectedType}
                          onChange={(data) => {
                            setFieldValue("type", data?.value);
                            setSelectedType(data);
                          }}
                          placeholder=""
                        />
                        <ErrorMessage
                          name="type"
                          render={(msg) => (
                            <div className="text-danger">{msg}</div>
                          )}
                        />
                      </div>

                      <div className="mb-3">
                        <label
                          htmlFor="status"
                          className="form-label text-primary"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            wordWrap: "break-word ",
                          }}
                        >
                          Status
                        </label>
                        <Select
                          options={statusOptions}
                          value={selectedStatus}
                          onChange={(data) => {
                            setFieldValue("status", data?.value);
                            setSelectedStatus(data);
                          }}
                          placeholder=""
                        />
                        <ErrorMessage
                          name="status"
                          render={(msg) => (
                            <div className="text-danger">{msg}</div>
                          )}
                        />
                      </div>

                      <div className="mb-3">
                        <label
                          htmlFor="priority"
                          className="form-label text-primary"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            wordWrap: "break-word ",
                          }}
                        >
                          Priority
                        </label>
                        <Select
                          options={priorityOptions}
                          value={selectedPriority}
                          onChange={(data) => {
                            setFieldValue("priority", data?.value);
                            setSelectedPriority(data);
                          }}
                          placeholder=""
                        />
                        <ErrorMessage
                          name="priority"
                          render={(msg) => (
                            <div className="text-danger">{msg}</div>
                          )}
                        />
                      </div>

                      <div className="mb-3">
                        <label
                          htmlFor="group"
                          className="form-label text-primary"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            wordWrap: "break-word ",
                          }}
                        >
                          Group
                        </label>
                        <Select
                          options={groupOptions}
                          value={selectedGroup}
                          onChange={(data) => {
                            setFieldValue("group", data?.value);
                            setSelectedGroup(data);
                            getALLAgents(data);
                          }}
                          placeholder=""
                        />
                        <ErrorMessage
                          name="group"
                          render={(msg) => (
                            <div className="text-danger">{msg}</div>
                          )}
                        />
                      </div>

                      <div className="mb-3">
                        <label
                          htmlFor="assigned_agent"
                          className="form-label text-primary"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            wordWrap: "break-word ",
                          }}
                        >
                          Agent
                        </label>
                        <Select
                          options={
                            selectedGroup
                              ? agentOptions.filter((dd) =>
                                  dd?.groups.includes(selectedGroup?.value)
                                )
                              : agentOptions
                          }
                          value={selectedAgent}
                          onChange={(data) => {
                            setFieldValue("assigned_agent", data?.value);
                            setSelectedAgent(data);
                          }}
                          placeholder=""
                        />
                        <ErrorMessage
                          name="assigned_agent"
                          render={(msg) => (
                            <div className="text-danger">{msg}</div>
                          )}
                        />
                      </div>

                      <div className="mb-3">
                        <label
                          htmlFor="remarks"
                          className="form-label text-primary"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            wordWrap: "break-word ",
                          }}
                        >
                          Remarks
                          <span className="text-danger">*</span>
                        </label>
                        <Field
                          className="form-control"
                          name="remarks"
                          placeholder="Enter Remarks"
                          style={{
                            borderRadius: "10px",
                          }}
                        />
                        <ErrorMessage
                          name="remarks"
                          render={(msg) => (
                            <div className="text-danger">{msg}</div>
                          )}
                        />
                      </div>

                      <div className="mt-5 mb-3">
                        <Button
                          className="btn w-100"
                          type="submit"
                          style={{
                            background: "#00000080",
                            color: "#fff",
                            borderRadius: "100px",
                            textTransform: "capitalize",
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                        >
                          Update
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </Col>
        </Row>
      </div>
      <Modal
        id="signupModals"
        tabIndex="-1"
        className="modal-md"
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal((prev) => !prev);
        }}
      >
        <ModalHeader
          className="p-3"
          toggle={() => {
            setDeleteModal((prev) => !prev);
          }}
        >
          Delete
        </ModalHeader>
        <ModalBody>
          <div>
            <h4>Are you sure you want to delete?</h4>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            style={{ marginRight: "15px", backgroundColor: "#093564" }}
            variant="contained"
            color="primary"
            onClick={() => {
              deleteComment(deleteId, deleteIndex);
              setDeleteModal((prev) => !prev);
            }}
          >
            Yes
          </Button>
          <Button
            style={{ color: "#093564" }}
            onClick={() => setDeleteModal((prev) => !prev)}
          >
            No
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default Tickets;
