import moment from "moment";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ModalFooter,
  UncontrolledDropdown,
} from "reactstrap";
import { Alert, Modal, ModalBody, ModalHeader } from "reactstrap";
import { customStyles } from "../../assets/CustomTableStyles";
import AddUser from "./AddUser";

const UserTable = (props) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const [deletId, setDeletId] = useState();

  const reportingManagerOptionsMap = props.managerOptions.reduce(
    (acc, option) => {
      acc[option.value] = option.label;
      return acc;
    },
    {}
  );

  const [cols, setCols] = useState([
    {
      name: <span className="font-weight-bold fs-13">Name</span>,
      selector: (row) => row?.full_name,
      cell: (value) => {
        return (
          <div
            title={value?.full_name}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "200px",
            }}
          >
            {value?.full_name}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Email</span>,
      selector: (row) => row?.email,
      cell: (value) => {
        return (
          <div
            title={value?.email}
            style={{
              maxWidth: "max-content",
            }}
          >
            {value?.email}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Mobile</span>,
      selector: (row) => row,
      cell: (value) => {
        return (
          <div
            title={value?.mobile}
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              maxWidth: "200px",
            }}
          >
            {value?.mobile}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Job Title</span>,
      selector: (row) => row,
      cell: (value) => {
        return <div title={value?.job_title}>{value?.job_title}</div>;
      },
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Role</span>,
      selector: (row) => row,
      cell: (value) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              maxWidth: "200px",
            }}
          >
            {value?.role[0]}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Groups</span>,
      selector: (row) => row,
      cell: (value) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "200px",
            }}
          >
            {value?.group?.join(", ")}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Reporting Manager</span>,
      selector: "report_manager", // Adjust this selector as per your data
      cell: (value) => {
        return (
          <div
            title={value?.report_manager}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "200px",
            }}
          >
            {reportingManagerOptionsMap[value?.report_manager]}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Client</span>,
      selector: (row) => row, // Adjust this selector as per your data
      cell: (value) => {
        return (
          <div
            title={value?.client_name && value?.client_name[0]}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "200px",
            }}
          >
            {value?.client_name && value?.client_name[0]}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Actions</span>,
      selector: (row) => row,
      cell: (value) => {
        return (
          <UncontrolledDropdown className="dropdown d-inline-block">
            <DropdownToggle
              className="btn btn-soft-secondary btn-sm"
              tag="button"
            >
              <i className="ri-more-fill align-middle"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                className="edit-item-btn"
                onClick={() => {
                  setEditModal(true);
                  setSelectedUser(value);
                }}
              >
                <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                Edit
              </DropdownItem>
              <DropdownItem
                className="remove-item-btn"
                onClick={() => {
                  setDeleteModal(true);
                  setDeletId(value);
                }}
              >
                <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                Delete{" "}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
    },
  ]);
  return (
    <>
      <DataTable
        customStyles={customStyles}
        columns={cols}
        data={props.usersData}
        pagination={true}
      />
      <Modal
        id="signupModals"
        tabIndex="-1"
        className="modal-lg"
        isOpen={editModal}
        toggle={() => {
          setEditModal((prev) => !prev);
        }}
      >
        <ModalHeader
          className="p-3 "
          toggle={() => {
            setEditModal((prev) => !prev);
          }}
        >
          <span className="text-primary"> Edit User</span>
        </ModalHeader>
        <ModalBody>
          <AddUser
            closeAddPopup={() => {
              setEditModal(false);
              setSelectedUser(null);
              props.getUsersData();
            }}
            data={selectedUser}
            selectedUser={selectedUser}
            history={props.history}
            isEdit={true}
          />
        </ModalBody>
      </Modal>

      <Modal
        id="signupModals"
        tabIndex="-1"
        className="modal-lg"
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal((prev) => !prev);
        }}
      >
        <ModalHeader
          className="p-3"
          toggle={() => {
            setDeleteModal((prev) => !prev);
          }}
        >
          Delete
        </ModalHeader>
        <ModalBody>
          <div>
            <h4>Are you sure you want to delete?</h4>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              props.deleteUser(deletId.id);
              setDeleteModal((prev) => !prev);
            }}
          >
            Yes
          </Button>
          <Button onClick={() => setDeleteModal((prev) => !prev)}>No</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default UserTable;
