import React from "react";
import { Col } from "reactstrap";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "react-router-dom";
import whiteCircle from "../../assets/images/whiteCircle.png";

// Import Images
import logoLight from "../../assets/images/logo-light.png";

const AuthSlider = () => {
  return (
    <React.Fragment>
      <Col lg={6}>
        <div className=" p-5 auth-one-bg">
          <div className="bg-overlay"></div>
          <div className="position-relative d-flex flex-column">           
            <div className="mt-auto">
              
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
};

export default AuthSlider;
