import React, { useEffect, useState } from "react";
import { Grid, Box, Typography, Button } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import ClientImg from "../../assets/images/company.png";
import companyBg from "../../assets/images/Company_bg.png";
import apiAuth from "../../helpers/ApiAuth";
import NotificationManager from "../../components/Common/NotificationManager";

const AddClient = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [getCompanies, setGetCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const getALLCompanies = () => {
    apiAuth
      .get("/api/master/company")
      .then((res) => {
        const data = res?.data;
        const companyOptions = data?.map((item) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });
        const selCompany = companyOptions?.find(
          (item) => item?.value === props?.selectedClient?.company
        );
        setSelectedCompany(selCompany);
        setGetCompanies(companyOptions);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  useEffect(() => {
    getALLCompanies();
  }, []);

  const goBack = () => {
    history.goBack();
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "10px",
    }),
  };
  return (
    <React.Fragment>
      <div className={props?.isEdit ? "" : "page-content"}>
        <div>
          <Grid container>
            <Grid lg={12}>
              <div className="p-3 bg-white ">
                <Grid lg={6}>
                  <Box className="d-flex align-items-center justify-content-start">
                    <img
                      src={ClientImg}
                      alt=""
                      height="30"
                      width="40"
                      style={{ marginRight: "10px" }}
                    />
                    <Typography
                      variant="h5"
                      style={{
                        color: "#093564",
                        fontWeight: "700",
                        fontFamily: "Inter",
                      }}
                    >
                      New Client
                    </Typography>
                  </Box>
                  <Box style={{ margin: "20px 0px " }}>
                    <Typography
                      style={{
                        color: "rgba(0, 0, 0, 0.50)",
                        fontWeight: 500,
                        textAlign: "justify",
                        fontFamily: "Inter",
                      }}
                    >
                      when someone reaches out to you, they become a contact in
                      your
                      <br /> account. You can create Clients and associate
                      contacts with them.
                    </Typography>
                  </Box>
                  <Box
                    className="d-flex align-items-center justify-content-start"
                    style={{
                      marginBottom: "30px",
                    }}
                  >
                    <div
                      style={{
                        padding: "10px",
                        borderRadius: "10px",
                        zIndex: "10",
                        background: "#09356494",
                        marginRight: "10px",
                      }}
                    >
                      <img
                        width="50px"
                        height="50px"
                        src={companyBg}
                        alt=""
                        style={{
                          objectFit: "cover",
                          zIndex: "12",
                        }}
                      />
                    </div>
                    <Box>
                      <Typography
                        variant="h6"
                        style={{
                          color: "#093564",
                          fontWeight: "700",
                          fontFamily: "Inter",
                        }}
                      >
                        New Client
                      </Typography>

                      <Typography
                        style={{
                          color: "rgba(0, 0, 0, 0.50)",
                          fontWeight: 500,
                          textAlign: "justify",
                          fontFamily: "Inter",
                        }}
                      >
                        A logo of the company, it's best if it has the <br />{" "}
                        same length and height
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Formik
                  initialValues={{
                    name: props?.isEdit ? props?.selectedClient?.name : "",
                    description: props?.isEdit
                      ? props?.selectedClient?.description
                      : "",
                    email: props?.isEdit ? props?.selectedClient?.email : "",
                    // company: props?.isEdit
                    //   ? props?.selectedClient?.company
                    //   : "",
                    notes: props?.isEdit ? props?.selectedClient?.notes : "",
                  }}
                  validationSchema={Yup.object({
                    // company: Yup.string().ensure().required("Required!"),
                    name: Yup.string().ensure().required("Required!"),
                    email: Yup.string()
                      .test("valid-domain", "Invalid domain", (value) => {
                        if (!value) return true; // If domain is empty, don't validate

                        // Check if the domain contains at least one dot (.)
                        if (value.indexOf(".") === -1) return false;

                        // Split the domain into parts
                        const parts = value.split(".");
                        // Check if both parts have at least 2 characters
                        return parts.every((part) => part.length >= 2);
                      })
                      .required("Required!"),
                  })}
                  onSubmit={(values) => {
                    console.log("vales", values);
                    if (props?.isEdit) {
                      apiAuth
                        .patch(
                          `/api/master/client/${props.selectedClient?.id}/`,
                          values
                        )
                        .then((res) => {
                          let data = res.data;
                          setLoading(false);
                          NotificationManager.success(
                            "Client",
                            "Client Updated Successfully",
                            3000,
                            null,
                            null,
                            ""
                          );
                          props.closeAddPopup(data);
                        })
                        .catch((err) => {
                          setLoading(false);
                          console.log(err);
                          NotificationManager.error(
                            "Client",
                            "Client Update Error",
                            3000,
                            null,
                            null,
                            ""
                          );
                        });
                    } else {
                      apiAuth
                        .post("/api/master/client/", values)
                        .then((res) => {
                          const { data } = res;
                          setLoading(false);
                          NotificationManager.success(
                            "Client",
                            "Client Created Successfully",
                            3000,
                            null,
                            null,
                            ""
                          );
                          history.push("/clients");
                        })
                        .catch((err) => {
                          setLoading(false);
                          console.log(err);
                          NotificationManager.error(
                            "Client",
                            "Client Create Error",
                            3000,
                            null,
                            null,
                            ""
                          );
                        });
                    }
                  }}
                >
                  {({ setFieldValue }) => (
                    <Form className="av-tooltip tooltip-label-bottom">
                      {/* <Grid item lg={12} xs={12} className="mb-3">
                        <div className="mb-3">
                          <label
                            htmlFor="company"
                            className="form-label text-primary fs-16 fw-bold custom-word-wrap"
                          >
                            Company Name
                          </label>
                          <span className="text-danger">*</span>
                          <Select
                            options={getCompanies}
                            value={selectedCompany}
                            onChange={(data) => {
                              setFieldValue("company", data?.value);
                              setSelectedCompany(data);
                            }}
                            styles={customStyles}
                          />
                          <ErrorMessage
                            name="company"
                            render={(msg) => (
                              <div className="text-danger">{msg}</div>
                            )}
                          />
                        </div>
                      </Grid> */}
                      <Grid item lg={12} xs={12} className="mb-3">
                        <label
                          htmlFor="name"
                          className="form-label text-primary fw-bold custom-word-wrap fs-16"
                        >
                          Client Name
                        </label>
                        <span className="text-danger">*</span>
                        <Field
                          className="form-control"
                          name="name"
                          type="text"
                          placeholder="Client Name..."
                        />
                        <ErrorMessage
                          name="name"
                          render={(msg) => (
                            <div className="text-danger">{msg}</div>
                          )}
                        />
                      </Grid>
                      <Grid item lg={12} xs={12} className="mb-3">
                        <label
                          htmlFor="description"
                          className="form-label text-primary fs-16 fw-bold custom-word-wrap"
                        >
                          Description
                        </label>
                        <Field
                          as="textarea"
                          className="form-control"
                          name="description"
                          placeholder="Write something that describes this company"
                          style={{
                            borderRadius: "10px",
                          }}
                        />
                        <ErrorMessage
                          name="description"
                          render={(msg) => (
                            <div className="text-danger">{msg}</div>
                          )}
                        />
                      </Grid>

                      <Grid item lg={12} xs={12} className="mb-3">
                        <label
                          htmlFor="notes"
                          className="form-label text-primary fs-16 fw-bold custom-word-wrap"
                        >
                          Notes
                        </label>
                        <Field
                          className="form-control"
                          as="textarea"
                          name="notes"
                          placeholder="Add notes about the company - maybe something about a recent data etc."
                          style={{
                            borderRadius: "10px",
                          }}
                        />
                        <ErrorMessage
                          name="notes"
                          render={(msg) => (
                            <div className="text-danger">{msg}</div>
                          )}
                        />
                      </Grid>
                      <Grid item lg={12} xs={12} className="mb-3">
                        <label
                          htmlFor="email"
                          className="form-label text-primary fs-16 fw-bold custom-word-wrap"
                        >
                          Domain for this client
                        </label>
                        <span className="text-danger">*</span>

                        <Field
                          className="form-control"
                          name="email"
                          placeholder="eg: mycompany1.com, mycompany2.com"
                          style={{
                            borderRadius: "10px",
                          }}
                        />
                        <ErrorMessage
                          name="email"
                          render={(msg) => (
                            <div className="text-danger">{msg}</div>
                          )}
                        />
                        <span
                          style={{
                            color: "rgba(0, 0, 0, 0.50)",
                            fontSize: "15px",
                          }}
                        >
                          *Contacts whose email addresses contain these domain
                          will be associated with this company.
                        </span>
                      </Grid>

                      <div className="d-flex justify-content-start align-items-center fs-16">
                        {loading ? (
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <div className="mt-4 mb-3">
                            <Button
                              className="btn text-white  bg-primary"
                              type="submit"
                              style={{
                                borderRadius: "20px",
                                padding: "8px 40px",
                                fontWeight: "500",
                              }}
                            >
                              {props?.isEdit ? "Update" : "Create"}
                            </Button>
                          </div>
                        )}
                        <div className="mt-4 mb-3 ms-3">
                          <Button
                            className="btn text-primary"
                            // type="reset"
                            style={{
                              fontWeight: 500,
                              padding: "8px 0px",
                            }}
                            onClick={() => {
                              props?.isEdit
                                ? props?.closeAddPopup()
                                : props?.history.goBack();
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddClient;
