import React from "react";
import { Col, Row } from "reactstrap";
import { ChevronRight } from "react-bootstrap-icons";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import PublicHeader from "../../Layouts/PublicHeader";
import { cardsData } from "./Data";

const TmsStartPage = () => {
  const history = useHistory();
  const id = useParams();

  const questionData = cardsData?.find((item) => {
    return item?.route === id?.route;
  });

  return (
    <React.Fragment>
      <div
        className=" overflow-hidden"
        style={{ height: "100vh", background: "white" }}
      >
        <PublicHeader ticketPage={localStorage.getItem("role") === "user"} />
        <Row className="mt-3 mb-2" style={{ padding: "2%"}}>
          <Col className="d-flex justify-content-start align-items-center ff-primary">
            <Link
              to="/home"
              className="p-2"
              style={{
                color: "rgba(9, 53, 100, 0.50)",
                fontSize: "20px",
              }}
            >
              Home
            </Link>
            <ChevronRight />
            <Link
              to="/knowledge"
              className="p-2"
              style={{
                color: "rgba(9, 53, 100, 0.50)",
                fontSize: "20px",
              }}
            >
              Knowledge Base
            </Link>
            <ChevronRight />
            <Link
              to={`/knowledge/${questionData?.route}`}
              className="p-2"
              style={{ color: "#093564", fontSize: "20px" }}
            >
              {" "}
              {questionData?.title}
            </Link>
          </Col>
        </Row>
        <Row>
          <Col className="ff-primary">
            <div>
              <h3
                style={{
                  padding: "0% 2%",
                  color: "#093564",
                  fontWeight: 800,
                }}
              >
                {questionData?.title}
              </h3>
            </div>
          </Col>
        </Row>

        <div
          className="ff-primary"
          style={{
            padding: "2%",
          }}
        >
          {questionData?.points?.map((item) => {
            return (
              <>
                <div
                  style={{
                    fontSize: "16px",
                    padding: "5px",
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: "#093564",
                  }}
                  onClick={() =>
                    history.push(`/knowledge/${id?.route}/${item?.link}`)
                  }
                >
                  <p style={{ margin: "0px" }}>{item?.subTitle}</p>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default TmsStartPage;
