import React from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import {
  Button,
  Container,
  CssBaseline,
  Paper,
  Typography,
} from "@mui/material";
import { Copyright } from "@mui/icons-material";

const PublicFooter = () => {
  return (
    // <footer
    //   style={{
    //     position: "absolute",
    //     bottom: 0,
    //       width: "100%",
    //       marginTop: "auto"
    //   }}
    // >
    //   <div
    //     className="d-flex flex-column justify-content-center align-items-center pt-5"
    //     style={{ backgroundColor: "#093564", fontFamily: "Inter" }}
    //   >
    //     <h3 className="text-white text-center mb-1">
    //       Can't find what you are looking for?
    //     </h3>
    //     <h5
    //       style={{
    //         fontWeight: 500,
    //       }}
    //       className="text-white text-center mb-1 mt-2"
    //     >
    //       Let us help you right now!
    //     </h5>
    //     <Button
    //       className="bg-white border-0 mt-3"
    //       style={{
    //         color: "#093564",
    //         borderRadius: "5px",
    //         fontSize: "12px",
    //         fontWeight: 500,
    //       }}
    //     >
    //       Contact Us
    //     </Button>
    //   </div>

    //   <div
    //     className="d-flex justify-content-around text-white pt-5 pb-3"
    //     style={{ backgroundColor: "#093564" }}
    //   >
    //     <Typography
    //       style={{ color: "rgba(255, 255, 255, 0.50)", fontFamily: "Inter" }}
    //     >
    //       © 2023 All rights reserved by Colosseumtech Solutions Pvt Ltd
    //     </Typography>
    //     <div>
    //       <LinkedInIcon />
    //     </div>
    //   </div>
    // </footer>

    // <div style={{ display: "flex", flexDirection: "column" }}>
    //   <footer
    //     style={{
    //       padding: "theme.spacing(3, 2)",
    //       marginTop: "auto",
    //       backgroundColor: "#093564",
    //     }}
    //   >
    //     <Container maxWidth="lg" className="p-4">
    //       <div style={{ fontFamily: "Inter" }}>
    //         <h3 className="text-white text-center mb-1 mt-4">
    //           Can't find what you are looking for?
    //         </h3>
    //         <h5
    //           style={{
    //             fontWeight: 500,
    //           }}
    //           className="text-white text-center mb-1 mt-2"
    //         >
    //           Let us help you right now!
    //         </h5>
    //         <div className="text-center">
    //         <Button
    //           className="bg-white border-0 mt-3"
    //           style={{
    //             color: "#000",
    //             borderRadius: "5px",
    //             fontSize: "15px",
    //             fontWeight: 500,
    //             margin: "auto",
    //             textTransform: "capitalize"
    //           }}
    //         >
    //           Contact Us
    //         </Button>
    //         </div>

    //       </div>

    //       <div
    //         className="d-flex justify-content-around text-white pt-5 pb-3"
    //         style={{ backgroundColor: "#093564" }}
    //       >
    //         <Typography
    //           style={{
    //             color: "rgba(255, 255, 255, 0.50)",
    //             fontFamily: "Inter",
    //           }}
    //         >
    //           © 2023 All rights reserved by Colosseumtech Solutions Pvt Ltd
    //         </Typography>
    //         <div>
    //           <LinkedInIcon />
    //         </div>
    //       </div>
    //     </Container>
    //   </footer>
    // </div>

    <div>
      <footer
        className="pt-4 pb-1 mb-0 shadow-lg w-100"
        style={{
          backgroundColor: "#093564",
          fontFamily: "Inter",
          color: "#fff",
          backdropFilter: "blur(30px)",
        }}
      >
        <div style={{ fontFamily: "Inter" }}>
          <h3 className="text-white text-center mb-1 mt-2">
            Can't find what you are looking for?
          </h3>
          <h5
            style={{
              fontWeight: 500,
            }}
            className="text-white text-center mb-1 mt-2"
          >
            Let us help you right now!
          </h5>
          <div className="text-center">
            <Button
              className="bg-white border-0 mt-3"
              style={{
                color: "#000",
                borderRadius: "5px",
                fontSize: "15px",
                fontWeight: 500,
                margin: "auto",
                textTransform: "capitalize",
              }}
            >
              Contact Us
            </Button>
          </div>
        </div>

        <div
          className="d-flex justify-content-center text-white pt-4 pb-3 px-2 gap-5"
          style={{ backgroundColor: "#093564" }}
        >
          <Typography
            style={{
              color: "rgba(255, 255, 255, 0.50)",
              fontFamily: "Inter",
            }}
          >
            &copy; {new Date().getFullYear()} All rights reserved by
            Colosseumtech Solutions Pvt Ltd
          </Typography>
          <div>
            <LinkedInIcon />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default PublicFooter;
