import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const Navdata = () => {
  const history = useHistory();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isTickets, setIsTickets] = useState(false);
  const [isGroups, setisGroups] = useState(false);
  const [isAgents, setIsAgents] = useState(false);
  const [isUsers, setIsUsers] = useState(false);
  const [isClients, setIsClients] = useState(false);
  const [isCompany, setIsCompany] = useState(false);
  const [isProduct, setIsProduct] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");
  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Ticktes") {
      setIsTickets(false);
    }
    if (iscurrentState !== "Groups") {
      setisGroups(false);
    }
    if (iscurrentState !== "Agents") {
      setIsAgents(false);
    }
    if (iscurrentState !== "Users") {
      setIsUsers(false);
    }
    if (iscurrentState !== "Clients") {
      setIsClients(false);
    }
    if (iscurrentState !== "Products") {
      setIsProduct(false);
    }
    if (iscurrentState !== "Company") {
      setIsCompany(false);
    }

    if (iscurrentState === "Widgets") {
      history.push("/widgets");
      document.body.classList.add("twocolumn-panel");
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isTickets,
    isGroups,
    isAgents,
    isUsers,
    isClients,
    isCompany,
  ]);

  const menuItems = [
    {
      id: "dashboard",
      label: "Dashboard",
      src: "/sidebar/dash.svg",
      link: "/dashboard",
      stateVariables: isDashboard,
      roles: ["superadmin", "admin", "agent"],
    },
    {
      id: "tickets",
      label: "Tickets",
      src: "/sidebar/ticket.svg",
      link: "/tickets",
      stateVariables: isTickets,
      roles: ["superadmin", "admin", "agent"],
    },
    {
      id: "Groups",
      label: "Groups",
      link: "/groups",
      src: "/sidebar/groups.png",
      stateVariables: isGroups,
      roles: ["superadmin", "admin"],
    },
    {
      id: "Agents",
      label: "Agents",
      link: "/agents",
      src: "/sidebar/agents.png",
      stateVariables: isAgents,
      roles: ["superadmin", "admin"],
    },
    {
      id: "Users",
      label: "Users",
      link: "/users",
      src: "/sidebar/user.png",
      stateVariables: isUsers,
      roles: ["superadmin", "admin", "agent"],
    },
    {
      id: "Products",
      label: "Products",
      link: "/products",
      src: "/sidebar/products.png",
      stateVariables: isProduct,
      roles: ["superadmin", "admin", "agent"],
    },
    {
      id: "Clients",
      label: "Client",
      link: "/clients",
      src: "/sidebar/client.png",
      stateVariables: isClients,
      roles: ["superadmin", "admin", "agent"],
    },
    // {
    //   id: "Company",
    //   label: "Company",
    //   // icon: "ri-community-fill",
    //   link: "/companies",
    //   src: "/sidebar/company.png",
    //   stateVariables: isCompany,
    //   roles: ["superadmin", "admin", "user"],
    // },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
