import React, { useState, useEffect } from "react";
import { Input, InputGroup, InputGroupText, Row } from "reactstrap";
import { Colxx } from "../../components/Common/CustomBootstrap";
import Select from "react-select";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import apiAuth from "../../helpers/ApiAuth";
import moment from "moment";
import PublicHeader from "../../Layouts/PublicHeader";
import { Download } from "react-bootstrap-icons";
import { Button, Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import Footer from "../../Layouts/Footer";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: "10px",
    textAlign: "start",
  }),
  option: (provided, state) => ({
    ...provided,
    textAlign: "start",
  }),
};

const statusStyles = {
  In_Review: {
    color: "#B22B00",
    backgroundColor: "#b22b001a",
  },
  Waiting_for_Customer_Input: {
    color: "#FF0000",
    backgroundColor: "#ff00001a",
  },
  Resolved: {
    color: "#0033B6",
    backgroundColor: "#0033b61a",
  },
  Open: {
    color: "#0033B6",
    backgroundColor: "#0033b61a",
  },
  // Default styles
  Default: {
    color: "#353535",
    backgroundColor: "#3535351a",
  },
};

const priorityStyles = {
  Low: {
    background: "#A0D76A",
  },
  High: {
    background: "#FFD012",
  },
  Medium: {
    background: "#4DA1FF",
  },
  Urgent: {
    background: "#FF5959",
  },
  Default: {
    background: "#f3f3f3",
  },
};

const UserTicket = () => {
  const history = useHistory();
  const [ticketsData, setTicketsData] = useState([]);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState({
    label: "All Tickets",
    value: "All Tickets",
  });
  const [searchValue, setSearchValue] = useState("");
  const [selSortBy, setSelortBy] = useState({
    label: "Last Modified",
    value: "Last Modified",
  });
  const [loading, setLoading] = useState(true);

  const options = {
    weekday: "short",
    day: "numeric",
    month: "short",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const ticketsOptions = [
    {
      label: "All Tickets",
      value: "All Tickets",
    },
    {
      label: "Open or Pending",
      value: "Open or Pending",
    },
    {
      label: "Resolved or Closed",
      value: "Resolved or Closed",
    },
    {
      label: "Archive",
      value: "Archive",
    },
  ];

  const mapStatus = {
    Open: "This ticket is opened",
    Dropped: "This ticket has been closed",
    Resolved: "This ticket has been resolved",
    In_Review: "Being processed",
    Waiting_for_Customer_Input: "Awaiting for your reply",
  };

  const sortByOptions = [
    {
      label: "Date created",
      value: "Date created",
    },
    {
      label: "Last Modified",
      value: "Last Modified",
    },
    {
      label: "Priority",
      value: "Priority",
    },
    {
      label: "Status",
      value: "Status",
    },
  ];

  const getTicketsData = () => {
    apiAuth
      .get(`/api/master/ticket/?user=${localStorage.getItem("user_id")}`)
      .then((response) => {
        let data = response?.data?.results;
        setTicketsData(data);
        setFilteredTickets(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const isAuthenticated = () => {
    const authuser = localStorage.getItem("authUser");
    return !!authuser;
  };

  useEffect(() => {
    getTicketsData();
    if (!isAuthenticated()) {
      history.push("/login");
    }
  }, []);

  const exportData = () => {
    let apiData = ticketsData.map((item) => {
      let dataReport = {
        Subject: item?.subject,
        Date: moment(item?.created_at).format("DD/MM/YYYY"),
        Status: item?.status,
      };
      return dataReport;
    });

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = "Tickets Data";
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const getSortedTickets = (val) => {
    let sortTickets = [];
    if (val?.value === "Date created") {
      sortTickets = ticketsData.sort(
        (a, b) => moment(b?.created_at) - moment(a?.created_at)
      );
    } else if (val?.value === "Last Modified") {
      sortTickets = ticketsData.sort(
        (a, b) => moment(a?.updated_at) - moment(b?.updated_at)
      );
    } else if (val?.value === "Priority") {
      sortTickets = [...ticketsData].sort((a, b) => {
        const priorityOrder = ["Urgent", "High", "Medium", "Low"];
        return (
          priorityOrder.indexOf(a.priority) - priorityOrder.indexOf(b.priority)
        );
      });
      console.log("ssssss", sortTickets);
    } else if (val?.value === "Status") {
      sortTickets = [...ticketsData].sort((a, b) => {
        const priorityOrder = [
          "Open",
          "Waiting_for_Customer_Input",
          "In_Review",
          "Resolved",
          "Dropped",
        ];
        return (
          priorityOrder.indexOf(a.status) - priorityOrder.indexOf(b.status)
        );
      });
    }
    setFilteredTickets(sortTickets);
  };

  const getFilteredTickets = (data) => {
    let fillTickets = [];
    if (data?.value === "All Tickets") {
      fillTickets = ticketsData;
    } else if (data?.value === "Open or Pending") {
      fillTickets = ticketsData?.filter((item) => {
        return (
          item.status === "Open" ||
          item.status === "In_Review" ||
          item.status === "Waiting_for_Customer_Input"
        );
      });
    } else if (data?.value === "Resolved or Closed") {
      fillTickets = ticketsData?.filter((item) => {
        return item.status === "Resolved" || item.status === "Dropped";
      });
    } else {
      fillTickets = ticketsData?.filter((item) => {
        return item.status === data?.value;
      });
    }
    setFilteredTickets(fillTickets);
  };

  const getFormattedDate = (val) => {
    return new Date(val).toLocaleDateString("en-US", options);
  };

  return (
    <>
    <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh", overflow: "hidden" }}>
      <PublicHeader ticketPage />
      <div style={{ flex: "1" }}>
      <Row className="mt-1">
        <Colxx lg="12" className="p-0">
          <div
            className="px-5 py-4"
            style={{
              backgroundColor: "#EC1C24",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="pe-3">
              {" "}
              <h1 className="text-white m-0">Tickets</h1>
            </div>
            <div className="">
              <InputGroup className="w-100">
                <Input
                  className="p-2"
                  style={{
                    backgroundColor: "white",
                    color: "Black",
                    fontFamily: "Inter",
                    borderRadius: "8px",
                    fontSize: "16px",
                  }}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                  placeholder="Search your tickets here...."
                ></Input>
                <InputGroupText
                  className="border-0 ms-2"
                  style={{
                    backgroundColor: "#fff",
                    color: "#FF0000",
                    borderRadius: "8px",
                  }}
                >
                  <SearchIcon />
                </InputGroupText>
              </InputGroup>
            </div>
          </div>
        </Colxx>
      </Row>

      <Row className="m-4">
        {loading ? (
          <div className="loading"></div>
        ) : (
          <Colxx
            lg={10}
            xs={12}
            className="p-2"
            style={{
              backgroundColor: "#FBFBFB",
            }}
          >
            {filteredTickets?.length === 0 ? (
              <p className="pt-5">No Tickets Raised</p>
            ) : (
              filteredTickets
                .filter(
                  (item) =>
                    String(item.subject)
                      ?.toLowerCase()
                      .includes(String(searchValue)?.toLowerCase()) ||
                    item?.id === Number(searchValue)
                )
                .map((item, index) => {
                  const status =
                    item?.status in statusStyles ? item.status : "Default";
                  const style = statusStyles[status];
                  const priority =
                    item?.priority in priorityStyles
                      ? item.priority
                      : "Default";
                  const priorityStyle = priorityStyles[priority];
                  return (
                    <div
                      key={index}
                      style={{
                        backgroundColor: "#FBFBFB",
                      }}
                      className="pb-1"
                    >
                      <Grid container>
                        <Grid item lg={9} xs={12}>
                          <div className="ps-2 mb-2">
                            <div className="d-flex gap-3">
                              {/* <Link to="/ticket-detail"> */}
                              <p
                                className="text-primary fs-18 fw-bold m-0 cursor-pointer"
                                onClick={() =>
                                  history.push(
                                    `/ticket-detail/?id=${item?.id}`,
                                    item
                                  )
                                }
                              >
                                RE: {item?.subject}{" "}
                                <span style={{ fontWeight: "400" }}>
                                  #{item?.id}
                                </span>
                              </p>
                              {/* </Link> */}
                              <Button
                                variant="outlined"
                                style={{
                                  ...priorityStyle,
                                  textTransform: "capitalize",
                                  color: "#000",
                                  padding: "1px",
                                  border: "none",
                                  fontSize: "14px",
                                  fontWeight: 500,
                                }}
                              >
                                {item.priority
                                  ? item.priority.charAt(0).toUpperCase()
                                  : ""}
                              </Button>
                            </div>

                            <span className="text-primary fs-14">
                              Created on {getFormattedDate(item?.created_at)}
                            </span>
                          </div>
                        </Grid>
                        <Grid item lg={3} xs={12}>
                          <div className="me-5 d-flex text-center align-items-center">
                            <Button
                              variant="outlined"
                              style={{
                                color: style.color,
                                backgroundColor: style.backgroundColor,
                                border: "1px solid",
                                fontSize: "12px",
                                textTransform: "none"
                              }}
                            >
                              {mapStatus[item?.status]}
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  );
                })
            )}
            {}
          </Colxx>
        )}

        <Colxx
          className="p-0"
          lg={2}
          xs={12}
          style={{
            backgroundColor: "#F1F1F1",
          }}
        >
          <div className="d-flex text-center align-items-center flex-column p-2 h-75">
            <div className="my-4 w-100 px-2">
              <Button
                className="w-100 d-flex text-center align-items-center justify-content-center text-primary fw-bold rounded"
                onClick={exportData}
                style={{
                  backgroundColor: "white",
                }}
              >
                {" "}
                <span className="me-2">
                  <Download />
                </span>
                <span>Export Tickets</span>
              </Button>
            </div>
            <div className="my-4 w-100 px-2">
              <label
                htmlFor="status"
                className="text-primary d-flex align-items-center justify-content-start"
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  wordWrap: "break-word",
                }}
              >
                Status
              </label>
              <Select
                options={ticketsOptions}
                value={selectedTicket}
                onChange={(data) => {
                  setSelectedTicket(data);
                  getFilteredTickets(data);
                }}
                styles={customStyles}
                placeholder=""
              />
            </div>
            <div className="my-4 w-100 px-2">
              <label
                htmlFor="sortBy"
                className="text-primary d-flex align-items-center justify-content-start"
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  wordWrap: "break-word",
                }}
              >
                Sort By
              </label>
              <Select
                options={sortByOptions}
                value={selSortBy}
                onChange={(data) => {
                  setSelortBy(data);
                  getSortedTickets(data);
                }}
                styles={customStyles}
                placeholder=""
              />
            </div>
          </div>
        </Colxx>
      </Row>
      {/* </div> */}
      </div>
      <Footer/>
      </div>
    </>
  );
};

export default UserTicket;
