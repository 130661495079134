import React from "react";
import { Card, Col, Input, InputGroup, InputGroupText, Row } from "reactstrap";
import SearchIcon from "@mui/icons-material/Search";
import HomeBg from "../../assets/images/home-bg.png";
import ArticlesImg from "../../assets/images/browse-articles.png";
import TicketImg from "../../assets/images/submit-ticket.png";
import PublicHeader from "../../Layouts/PublicHeader";
import PublicFooter from "../../Layouts/PublicFooter";
import { Link } from "react-router-dom";
import { className } from "gridjs";

const Home = () => {
  const backgroundStyle = {
    backgroundImage: `url(${HomeBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: "currentColor",
    flex: "1",
  };
  return (
    <>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        overflowX: "hidden",
      }}
    >
      <PublicHeader transparent={true} />
      <div style={backgroundStyle}>
        <div className="container mt-5" style={{padding: "10px", margin: "auto" }}>
          <div className="d-flex flex-column justify-content-center align-items-center mt-5">
            <h1 className="text-white text-center">
              How Can We Help You Today?
            </h1>
          </div>
          <Row className="mt-2 mb-5">
            <Col lg={8} xs={12} className="m-auto">
              <InputGroup className="w-100 mt-4 rounded">
                <Input
                  style={{
                    backgroundColor: "white",
                    color: "Black",
                    fontFamily: "Inter",
                  }}
                  placeholder="Enter your search item here...."
                ></Input>
                <InputGroupText
                  className="border-0 rounded ms-2 text-white"
                  style={{
                    backgroundColor: "#EC1C2480",
                    cursor: "pointer",
                  }}
                >
                  <SearchIcon />
                </InputGroupText>
              </InputGroup>
            </Col>
          </Row>
          <Row className="pb-5">
            <Col lg={10} className="m-auto mt-5">
              <Row>
                <Col lg={6} xs={12} className="mb-4">
                  <Link to="/knowledge">
                    <Card className="w-100 m-0 p-4 d-flex flex-column justify-content-between align-items-center h-100">
                      <img
                        src={ArticlesImg}
                        alt="Articles"
                        className="img-fluid w-20"
                      />
                      <h5 className="text-danger text-center mt-2 ff-primary">
                        Browse Articles
                      </h5>
                      <p
                        className="text-center"
                        style={{ fontFamily: "Inter" }}
                      >
                        Explore How-To's and learn best practices <br /> from
                        our knowledge base
                      </p>
                    </Card>
                  </Link>
                </Col>
                <Col lg={6} xs={12} className="mb-4">
                  <Link to="/create-ticket">
                    <Card className="w-100 m-0 p-4 d-flex flex-column justify-content-between align-items-center h-100">
                      <img
                        src={TicketImg}
                        alt="Ticket"
                        className="img-fluid w-20"
                      />

                      <h5 className="text-danger text-center mt-2 ff-primary">
                        Submit A Ticket
                      </h5>

                      <p
                        className="text-center"
                        style={{ fontFamily: "Inter" }}
                      >
                        Describe your issue by filling up the support <br />{" "}
                        ticket team
                      </p>
                    </Card>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <PublicFooter />
    </div>
    </>
  );
};

export default Home;
