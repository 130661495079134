export const cardsData = [
  {
    title: "Getting Started with TMS",
    id: 1,
    route: "getting_started_tms",
    points: [
      {
        subTitle: "What is the Employee Transport Management System (TMS)?",
        link: "employee_transport",
        answer:
          "The Employee Transport Management System (TMS) is a comprehensive platform designed to streamline and optimize transportation services for employees within an organization. It facilitates the efficient management of employee rides, including booking, scheduling, tracking, and reporting.",
      },
      {
        subTitle: "How does the TMS benefit our organization?",
        link: "employee_benefit",
        answer: "The TMS offers several key benefits to your organization:",
        subPoints: [
          {
            heading: "Cost Efficiency",
            name: " It helps in optimizing routes, reducing idle time, and minimizing fuel expenses.",
          },
          {
            heading: "Improved Productivity",
            name: " It ensures timely and reliable transportation, leading to increased employee punctuality and productivity.",
          },
          {
            heading: "Enhanced Safety",
            name: "It provides features like real-time tracking and emergency support, ensuring the safety of employees during their commutes.",
          },
          {
            heading: "Data-Driven Insights",
            name: " It generates reports and analytics that can be used to make data-backed decisions for transportation management.",
          },
        ],
      },
      {
        subTitle: "How do I access the TMS platform?",
        link: "tms_platform",
        answer:
          "To access the TMS platform, you will be provided with login credentials. Visit our website and click on the 'Login' or 'Sign In' option. Enter your username and password, and you will be directed to your personalized dashboard.",
      },
      {
        subTitle: "What are the system requirements for using the TMS?",
        link: "employee_system_requirements",
        answer:
          "The TMS is designed to be accessible on various devices and browsers. It is compatible with most modern web browsers such as Chrome, Firefox, Safari, and Edge. Ensure that you have a stable internet connection for optimal performance. For mobile access, we recommend using the latest versions of iOS or Android.",
      },
      {
        subTitle: "How can I request a demo or trial period?",
        link: "employee_trial period",
        answer:
          "To request a demo or trial period, please contact our support team through the designated channels. They will guide you through the process and provide you with the necessary information to explore the TMS functionalities. This trial period will give you a hands-on experience to evaluate how the system can benefit your organization.",
      },
    ],
  },
  {
    title: "Account Setup and User Management",
    id: 2,
    route: "account_setup",
    points: [
      {
        subTitle: "How do I create a new account in the TMS?",
        link: "account_new",
        answer:
          "To create a new account, go to the TMS platform and click on the 'Sign Up' or 'Create Account' button. Fill in the required information, including your name, email, and a secure password. Follow the on-screen prompts to complete the registration process.",
      },
      {
        subTitle: "Can I add multiple users under one account?",
        link: "account_add",
        answer:
          "Yes, the TMS allows for multiple users to be added under a single account. The account owner or administrator can invite additional users by providing their email addresses. Each user will receive an invitation to create their own login credentials.",
      },
      {
        subTitle: "What roles and permissions are available for users?",
        link: "account_roles",
        answer:
          "The TMS offers different roles, such as Admin, Manager, and User. Admins have full access to all features and can manage users, while Managers may have restricted access. Users typically have limited access for booking and viewing their rides.",
      },
      {
        subTitle: "How can I reset my password or recover my account?",
        link: "account_reset",
        answer:
          "If you forget your password, you can click on the 'Forgot Password' link on the login page. You'll receive an email with instructions to reset your password. If you're still having trouble accessing your account, contact our support team for further assistance.",
      },
      {
        subTitle: "How do I update my contact information?",
        link: "employee_trial period",
        answer:
          "To update your contact information, log in to your account and navigate to the 'Profile' or 'Account Settings' section. Here, you can edit your name, email address, phone number, and any other relevant details. Make sure to save the changes before exiting.",
      },
    ],
  },
  {
    title: "Booking and Scheduling Rides",
    id: 3,
    route: "booking_rides",
    points: [
      {
        subTitle: "How do I book a ride using the TMS?",
        link: "book_ride",
        answer:
          "To book a ride, log in to your account and select the 'Book Ride' option. Choose your desired pick-up and drop-off locations, select a date and time, and confirm the booking. You will receive a confirmation notification.",
      },
      {
        subTitle: "Can I schedule rides for future dates?",
        link: "book_schedule_ride",
        answer:
          "Yes, you can schedule rides for future dates. When booking a ride, select the desired date and time, and the system will automatically arrange transportation accordingly.",
      },
      {
        subTitle: "What information do I need to provide when booking a ride?",
        link: "book_information",
        answer:
          "You'll need to specify the pick-up and drop-off locations, the date and time of the ride, and any special requests or preferences you may have, such as vehicle type or accessibility needs.",
      },
      {
        subTitle: "How do I make changes or cancel a booked ride?",
        link: "book_cancel",
        answer:
          "To make changes or cancel a booked ride, go to your ride history, locate the specific ride, and select the option to modify or cancel. Follow the prompts to confirm the changes.",
      },
      {
        subTitle: "Is there a limit on how many rides I can book in advance?",
        link: "book_limit",
        answer:
          "There may be policies regarding the maximum number of rides you can book in advance. Please refer to the TMS guidelines or contact support for specific details.",
      },
    ],
  },
  {
    title: "Managing Routes and Stops",
    id: 4,
    route: "managing_routes",
    points: [
      {
        subTitle: "How do I view available routes and stops?",
        link: "route_available",
        answer:
          "You can view available routes and stops by logging into the TMS platform and accessing the 'Routes' or 'Stops' section. Here, you'll find a list of all available options.",
      },
      {
        subTitle: "Can I request a new route or stop to be added?",
        link: "route_new",
        answer:
          "Yes, you can request a new route or stop to be added by contacting our support team. They will guide you through the process and evaluate the feasibility of the request.",
      },
      {
        subTitle: "What if my location is not listed in the available stops?",
        link: "route_location",
        answer:
          "If your location is not listed, please contact our support team. They will assist in finding the best solution for transportation to your location.",
      },
      {
        subTitle: "How can I suggest changes to existing routes or stops?",
        link: "route_suggestions",
        answer:
          "To suggest changes, navigate to the 'Feedback or 'Suggestions' section in the TMS platform. Provide details about the suggested changes, and our team will review and respond accordingly.",
      },
      {
        subTitle: "What happens if a route is temporarily unavailable?",
        link: "route_unavailable",
        answer:
          "In the event of a temporarily unavailable route, our support team will communicate alternative options or arrangements to ensure you reach your destination.",
      },
    ],
  },
  {
    title: "Tracking and Real-time Updates",
    id: 5,
    route: "tracking",
    points: [
      {
        subTitle: "How can I track the status of my ride in real-time?",
        link: "track_status",
        answer:
          "You can track your ride in real-time by logging into your account and accessing the 'Track Ride' feature. This will provide you with live updates on the location and status of your ride.",
      },

      {
        subTitle: "What kind of notifications will I receive about my ride?",
        link: "track_notifications",
        answer:
          "You will receive notifications about ride confirmation, pick-up details, and any significant updates or delays via email or SMS, depending on your notification preferences.",
      },
      {
        subTitle: "Can I share my ride status with someone else?",
        link: "track_share",
        answer:
          "Yes, you can share your ride status with someone else by using the 'Share Ride' option. This will provide them with a link to track your ride in real-time.",
      },
      {
        subTitle: "What should I do if my ride is significantly delayed?",
        link: "track_delayed",
        answer:
          "If your ride is significantly delayed, please contact our support team immediately. They will provide assistance and updates on the status of your ride.",
      },

      {
        subTitle:
          "Is there a way to track the location of the vehicle assigned to me?",
        link: "track_location",
        answer:
          "Yes, you can track the location of the vehicle assigned to you through the TMS platform. The 'Track Ride' feature provides real-time location updates.",
      },
    ],
  },
  {
    title: "Payment and Billing",
    id: 6,
    route: "payment",
    points: [
      {
        subTitle: "How does the payment process work for rides?",
        link: "payment_process",
        answer:
          "Payments for rides can typically be made through the TMS platform using various methods such as credit/debit cards, digital wallets, or other specified payment options. Follow the prompts to complete the payment process.      ",
      },

      {
        subTitle: "Can I view a history of my ride expenses?",
        linl: "payment_history",
        answer:
          "Yes, yxou can view a history of your ride expenses by accessing the 'Billing' or 'Ride History' section in the TMS platform. This will provide you with a detailed record of all your ride transactions.",
      },
      {
        subTitle: "What payment methods are accepted in the TMS?",
        link: "payment_methods",
        answer:
          "The TMS usually accepts various payment methods including major credit/debit cards, digital payment platforms, and other specified options. Check the platform for a list of accepted payment methods.",
      },
      {
        subTitle: "How do I request an invoice or receipt for my rides?",
        link: "payment_invoice",
        answer:
          "You can request an invoice or receipt for your rides by contacting our support team. They will assist you in generating the necessary documentation.",
      },
      {
        subTitle:
          "Are there any additional fees or charges I should be aware of?",
        link: "payment_charges",
        answer:
          "Be sure to review the TMS's terms and conditions for any applicable fees or charges, such as cancellation fees or additional service charges. These will be outlined in the platform's policies.",
      },
    ],
  },
  {
    title: "Feedback and Reporting Issues",
    id: 7,
    route: "feedback",
    points: [
      {
        subTitle:
          "  How can I provide feedback on my ride experience?          ",
        link: "feedback_provide",
        answer:
          " You can provide feedback on your ride experience through the TMS platform. Look for the 'Feedback' or 'Rate Your Ride' option and share your comments and ratings.      ",
      },

      {
        subTitle: "What should I do if I encounter an issue during my ride?",
        link: "feedback_issue",
        answer:
          "Is there a process for reporting lost items in the vehicle?      ",
      },

      {
        subTitle: "Is there a process for reporting lost items in the vehicle?",
        link: "feedback_contact",
        answer:
          "Yes, if you've lost an item during your ride, please contact our support team as soon as possible. They will guide you through the process of reporting and recovering lost items.",
      },
      {
        subTitle:
          "How quickly can I expect a response to my feedback or report?",
        link: "feedback_support",
        answer:
          "Our support team strives to respond promptly to feedback and reports. You can typically expect a response within [specified time frame], though this may vary depending on the nature of the inquiry.",
      },
      {
        link: "feedback_reported_issues",

        subTitle: " Can I track the status of my reported issues?",

        answer:
          " Yes, you can track the status of your reported issues by accessing the 'Support Tickets' or 'Feedback History' section in the TMS platform.",
      },
    ],
  },
  {
    title: "Troubleshooting and Technical Support",
    id: 8,
    route: "trobleshooting",
    points: [
      {
        subTitle:
          "What do I do if I'm experiencing technical difficulties with the TMS?      ",
        link: "trobleshooting_technical_difficulties",
        answer:
          " If you're experiencing technical difficulties, please refer to the TMS's troubleshooting guide or contact our support team for assistance.",
      },

      {
        subTitle: " How can I troubleshoot common issues on my own?      ",
        link: "trobleshooting_common_issues",

        answer:
          "The TMS platform may provide a troubleshooting guide or FAQ section that offers solutions to common issues. Refer to these resources for self-help.",
      },

      {
        subTitle:
          "Is there a helpline or support email for urgent matters?      ",
        link: "trobleshooting_support_email",

        answer:
          "Yes, in case of urgent matters, please contact our dedicated support helpline or email address for immediate assistance.",
      },
      {
        subTitle:
          "What information should I provide when seeking technical support?        ",
        link: "trobleshooting_information",

        answer:
          "When seeking technical support, be sure to provide details about the issue you're experiencing, including any error messages, your device and browser information, and steps you've already taken to try and resolve the problem.        ",
      },
      {
        subTitle:
          "Are there any known system outages or maintenance schedules?        ",
        link: "trobleshooting_maintenance",
        answer:
          "Any known system outages or maintenance schedules will be communicated in advance through the TMS platform or official communication channels.        ",
      },
    ],
  },
];
