import React from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Box, Typography } from "@mui/material";

const FileThumbnail = ({ attachment }) => {
  const parts = attachment?.file?.split(".");
  const lastWord = parts?.length ? parts[parts?.length - 1] : "";

  const handleDownloadFile = () => {
    if (!attachment?.file_type?.includes("image")) {
      const a = document.createElement("a");
      a.href = attachment?.file;
      a.download = attachment?.file.substring(
        attachment?.file.lastIndexOf("/") + 1
      );
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      window.open(attachment?.file, "_blank");
    }
  };

  const truncateFileName = (fileName) => {
    if (fileName.length > 20) {
      return `${fileName.slice(0, 20)}...`;
    }
    return fileName;
  };

  const renderThumbnail = () => {
    return attachment ? (
      <>
        <div
          className="d-flex align-items-center"
          style={{
            border: "0.2px solid #d6cdca",
            borderRadius: "2px",
            width: "300px",
          }}
        >
          <img
            src={
              lastWord?.includes("pdf")
                ? "/PDF-icon.svg"
                : lastWord?.includes("xlsx")
                ? "/Excel-icon.png"
                : lastWord?.includes("doc")
                ? "/word-icon.png"
                : attachment?.file_type?.includes("image")
                ? "/image-icon.png"
                : ""
            }
            width="40px"
            height="40px"
            style={{ objectFit: "contain", padding: "5px" }}
            alt="Icon"
          />
          <Typography
            sx={{
              width: "70%",
            }}
            className="mx-2"
            title={attachment?.file_name}
          >
            {truncateFileName(attachment?.file_name)}
          </Typography>
          <Box
            title="Download"
            sx={{
              padding: "12px",
              cursor: "pointer",
              borderLeft: "0.2px solid #d6cdca",
              ":hover": {
                background: "#f3f3f9",
              },
            }}
            onClick={handleDownloadFile}
            className="d-flex justify-content-end align-items-center border-1"
          >
            <FileDownloadIcon />
          </Box>
        </div>
      </>
    ) : (
      <></>
    );
  };

  return <div>{renderThumbnail()}</div>;
};

export default FileThumbnail;
