import React, { useState } from "react";
import * as Yup from "yup";
import { Box, Grid, Typography, Button } from "@mui/material";
import Select from "react-select";
import { Card, Container, Row } from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useHistory } from "react-router-dom";
import apiAuth from "../../helpers/ApiAuth";
import { Colxx } from "../../components/Common/CustomBootstrap";
import BreadCrumb from "../../components/Common/BreadCrumb";
import NotificationManager from "../../components/Common/NotificationManager";
import CompImg from "../../assets/images/company.png";

const AddCompany = (props) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const goBack = () => {
    history.push("/companies");
  };

  return (
    <React.Fragment>
      <div className={`${props?.isEdit ? "" : "page-content"}`}>
        {/* {props?.isEdit ? (
          <></>
        ) : (
          <Container fluid>
            <BreadCrumb
              title="Add Company"
              pageTitle="Settings"
              history={props?.history}
              back_button={true}
            />
          </Container>
        )} */}

        <div>
          <Grid container>
            <Grid xs={12}>
              <Card className="p-3">
                {/* Heading */}

                <Box className="d-flex align-items-center justify-content-start mb-3">
                  <img
                    src={CompImg}
                    height={50}
                    width={50}
                    alt="Agent"
                    style={{ marginRight: "10px" }}
                  />
                  <Typography
                    variant="h5"
                    className="text-primary"
                    style={{ color: "#093564", fontWeight: "700" }}
                  >
                    New Company
                  </Typography>
                </Box>
                <Formik
                  initialValues={{
                    name: props?.selCompany?.name || "",
                    description: props?.selCompany?.description || "",
                    domain: props?.selCompany?.domain || "",
                  }}
                  validationSchema={Yup.object({})}
                  onSubmit={(values) => {
                    setLoading(true);
                    props?.isEdit
                      ? apiAuth
                          .patch(
                            `/api/master/company/${props?.selCompany?.id}/`,
                            values
                          )
                          .then((res) => {
                            let data = res.data;
                            NotificationManager.success(
                              "Company Updation",
                              "Company Updated Successfully",
                              3000,
                              null,
                              null,
                              ""
                            );
                            props?.closePopUp(data);
                          })
                          .catch((err) => {
                            NotificationManager.error(
                              "Company Updation",
                              "Company Updation Error",
                              3000,
                              null,
                              null,
                              ""
                            );
                            props?.closePopUp();
                          })
                      : apiAuth
                          .post("/api/master/company/", values)
                          .then((res) => {
                            NotificationManager.success(
                              "Company Creation",
                              "Company Created Successfully",
                              3000,
                              null,
                              null,
                              ""
                            );
                            props?.history.goBack();
                          })
                          .catch((err) => {
                            NotificationManager.error(
                              "Company Creation",
                              "Company Create Error",
                              3000,
                              null,
                              null,
                              ""
                            );
                            props?.history.goBack();
                          });
                    setLoading(false);
                  }}
                >
                  {({ values, errors, touched, setFieldValue }) => (
                    <Form className="av-tooltip tooltip-label-bottom">
                      {/* Name */}

                      <Grid item lg={6} xs={12} className="mb-3">
                        <label
                          htmlFor="name"
                          className="form-label text-primary fs-16"
                          style={{
                            fontWeight: 600,
                            wordWrap: "break-word ",
                          }}
                        >
                          Name
                        </label>
                        <Field
                          className="form-control"
                          name="name"
                          placeholder="Name"
                          style={{
                            borderRadius: "10px",
                          }}
                        />
                        <ErrorMessage
                          name="name"
                          render={(msg) => (
                            <div className="text-danger">{msg}</div>
                          )}
                        />
                      </Grid>

                      {/* Description */}

                      <Grid item lg={6} xs={12} className="mb-3">
                        <label
                          htmlFor="description"
                          className="form-label text-primary fs-16"
                          style={{
                            fontWeight: 600,
                            wordWrap: "break-word ",
                          }}
                        >
                          Description
                        </label>
                        <Field
                          className="form-control"
                          name="description"
                          placeholder="Name"
                          style={{
                            borderRadius: "10px",
                          }}
                        />
                        <ErrorMessage
                          name="description"
                          render={(msg) => (
                            <div className="text-danger">{msg}</div>
                          )}
                        />
                      </Grid>

                      {/* Domain  */}

                      <Grid item lg={6} xs={12} className="mb-3">
                        <label
                          htmlFor="domain"
                          className="form-label text-primary fs-16"
                          style={{
                            fontWeight: 600,
                            wordWrap: "break-word ",
                          }}
                        >
                          Domain
                        </label>
                        <Field
                          className="form-control"
                          name="domain"
                          placeholder="Name"
                          style={{
                            borderRadius: "10px",
                          }}
                        />
                        <ErrorMessage
                          name="domain"
                          render={(msg) => (
                            <div className="text-danger">{msg}</div>
                          )}
                        />
                      </Grid>

                      {/* Submit & Cancel Buttons */}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                          fontSize: "16px",
                        }}
                      >
                        {loading ? (
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <div className="mt-4 mb-3">
                            <Button
                              className="btn text-white font-weight-medium bg-primary px-5 py-2"
                              type="submit"
                              style={{
                                fontWeight: 500,
                                borderRadius: "20px",
                              }}
                            >
                              {props?.isEdit ? "Update" : "Create"}
                            </Button>
                          </div>
                        )}
                        <div className="mt-4 mb-3 ms-3">
                          <Button
                            className="btn text-primary"
                            type="submit"
                            style={{
                              fontWeight: 500,
                              padding: "8px 0px",
                            }}
                            onClick={() => history.push("/companies")}
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddCompany;
