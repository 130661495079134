import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Card, Col, Input } from "reactstrap";
import { Colxx } from "../../components/Common/CustomBootstrap";
import apiAuth from "../../helpers/ApiAuth";
import DataTable from "react-data-table-component";
import { customStyles } from "../../assets/CustomTableStyles";

const UploadUsers = () => {
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const handleChange = (event) => {
    setState({
      ...state,
      file: URL.createObjectURL(event.target.files[0]),
      picfile: event.target.files[0],
      showData: false,
      users: [],
    });
  };

  // useEffect(() => {}, []);

  const handleFileData = async () => {
    if (state.picfile) {
      setLoading(true);

      let formData = new FormData();

      formData.append("excel_file", state.picfile);
      await apiAuth
        .post("api/userbulkupload/", formData)
        .then((res) => {
          console.log("response", res);
          let data = res.data;
          setState({
            ...state,
            showData: true,
            users: data?.created_users,
          });
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Row className="m-1">
          <Col sm="12">
            <Row mb="4">
              <Col lg="12">
                <div
                  className="card mt-2"
                  style={{ backgroundColor: "#f1f1f1", borderRadius: "5px" }}
                >
                  <div className="card-body">
                    <div className="card-subtitle">
                      <div className="input-group">
                        <div className="my-1 mx-1">
                          <Input
                            className="form-control border"
                            type="file"
                            id="formFile"
                            onChange={handleChange}
                            style={{
                              background: "#d9d9d9",
                              borderRadius: "10px",
                            }}
                          />
                        </div>

                        <div className="input-group-prepend ml-3 me-2 my-1">
                          <span
                            className="btn"
                            onClick={handleFileData}
                            style={{
                              background:
                                "linear-gradient(180deg, #093564 0%, #0D3662 100%)",
                              color: "#fff",
                              borderRadius: "11px",
                            }}
                          >
                            Upload
                          </span>
                        </div>

                        <div className="input-group-prepend mx-1 my-1">
                          <a
                            href="/UsersUploadTemplate.xlsx"
                            download="UsersUploadTemplate.xlsx"
                            className="btn btn-secondary"
                            style={{
                              background:
                                "linear-gradient(180deg, #093564 0%, #0D3662 100%)",
                              color: "#fff",
                              borderRadius: "11px",
                            }}
                          >
                            Download Template File
                          </a>
                          {/* <span
                            className="btn btn-secondary"
                            onClick={() => {}}
                            style={{
                              background:
                                "linear-gradient(180deg, #093564 0%, #0D3662 100%)",
                              color: "#fff",
                              borderRadius: "11px",
                            }}
                          >
                            Download Template File
                          </span> */}
                        </div>
                        <div className="input-group-prepend mx-1 my-1">
                          <Link to="/users">
                            <span
                              className="btn btn-secondary"
                              onClick={() => {}}
                              style={{
                                background:
                                  "linear-gradient(180deg, #093564 0%, #0D3662 100%)",
                                color: "#fff",
                                borderRadius: "11px",
                              }}
                            >
                              Back
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="mt-2 w-75">
                  <DataTable
                    // columns={state?.failed ? columns : columns.slice(0, -1)}
                    // data={showData}
                    // pagination={showData?.length > 10 ? true : false}
                    customStyles={customStyles}
                  />
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default UploadUsers;
