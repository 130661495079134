import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";

//import images
import avatar1 from "../../assets/images/users/user-dummy-img.jpg";

const ProfileDropdown = (props) => {
  const { user } = useSelector((state) => ({
    user: state.Login.user,
  }));

  const [userName, setUserName] = useState("Admin");
  const [loginRole, setLoginRole] = useState("admin");
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));

      if (!isEmpty(user)) {
        obj.fullname = user?.fullname;
        localStorage.removeItem("authUser");
        localStorage.setItem("authUser", JSON.stringify(obj));
      }

      if (obj?.role[0] === "admin") {
        setUserName(obj?.fullname || "Admin");
      } else if (obj?.role[0] === "agent") {
        setUserName(obj?.fullname || "Agent");
      } else {
        setUserName(obj?.fullname || "User");
      }

      setLoginRole(obj?.role[0]);
    }
  }, [user]);

  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };
  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="header-item "
      >
        <DropdownToggle
          tag="button"
          type="button"
          className="btn"
          style={{ margin: "auto", padding: "6px 8px", fontWeight: 600 }}
        >
          <div className="d-flex align-items-center">
            {loginRole === "user" ? (
              <div className="text-white bg-primary px-3 py-2 rounded fw-600">
                {userName?.length > 0 && userName[0].toLocaleUpperCase()}
              </div>
            ) : (
              <>
                <PersonOutlineOutlinedIcon
                  style={{
                    color:
                      localStorage.getItem("role") === "user" &&
                      !props.ticketPage
                        ? "white"
                        : "inherit",
                  }}
                />
                {userName && (
                  <span
                    style={{
                      fontSize: "16px",
                      marginLeft: "3px",
                      color:
                        localStorage.getItem("role") === "user" &&
                        !props.ticketPage
                          ? "white"
                          : "inherit",
                    }}
                  >
                    {userName.charAt(0).toUpperCase() + userName.slice(1)}
                  </span>
                )}
              </>
            )}
          </div>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header">Welcome {userName}!</h6>
          {loginRole === "user" && (
            <>
              {" "}
              <DropdownItem href="/profile">
                <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                <span className="align-middle">Profile</span>
              </DropdownItem>
              <div className="dropdown-divider"></div>
            </>
          )}

          <DropdownItem href="/logout">
            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle" data-key="t-logout">
              Logout
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileDropdown;
