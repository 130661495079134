import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Select from "react-select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Box, Grid, Radio, Typography, Button } from "@mui/material";
import { Card, Container, Label, Row } from "reactstrap";

import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";

import AgentImg from "../../assets/images/agent.svg";
import BreadCrumb from "../../components/Common/BreadCrumb";
import { Colxx } from "../../components/Common/CustomBootstrap";
import NoImage from "../../assets/images/no-img.jpg";
import apiAuth from "../../helpers/ApiAuth";
import NotificationManager from "../../components/Common/NotificationManager";

const RadioComp = ({ data, selGroup, setSelGroup, groupOptions, onChange }) => {
  //   console.log("sssss", data);
  return (
    <Row>
      <Colxx className="my-2">
        <input
          type="radio"
          checked={data?.isChecked}
          onChange={() => onChange(data?.id)}
        />{" "}
        <span>{data?.title}</span>
        <div>
          <span style={{ fontSize: "12px", marginLeft: "14px" }}>
            {data?.desc}
          </span>
        </div>
        {/* {data?.id === 1 ? (
          <Colxx lg={6} xs={12}>
            <div style={{ marginLeft: "12px", marginTop: "4px" }}>
              <Label>Choose groups that the agent is a part of</Label>
              <Select
                options={groupOptions}
                value={selGroup}
                onChange={(val) => setSelGroup(val)}
              />
            </div>
          </Colxx>
        ) : (
          <></>
        )} */}
      </Colxx>
    </Row>
  );
};

const AddAgent = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [selGroup, setSelGroup] = useState(null);
  const [selRole, setSelRole] = useState(null);
  const [selManager, setSelManager] = useState(null);
  const [signature, setSignature] = useState("");
  const [selImg, setSelImg] = useState(NoImage);
  const [groupOptions, setGroupOptions] = useState([]);
  const [managerOptions, setManagerOptions] = useState([]);

  const roleOptions = [
    { label: "Admin", value: "admin" },
    { label: "Agent", value: "agent" },
    { label: "Customer", value: "customer" },
    { label: "Supervisor", value: "supervisor" },
    { label: "Adminstrator", value: "adminstrator" },
    { label: "Account Adminstrator", value: "account_adminstrator" },
  ];

  const [scopeOptions, setScopeOptions] = useState([
    {
      id: 1,
      title: "Global Access",
      desc: "Can access all tickets in Helpdesk",
      isChecked: true,
    },
    {
      id: 2,
      title: "Assigned to their Group",
      desc: "Can view and edit tickets in their group(s) and tickets assigned to them",
      isChecked: false,
    },
    {
      id: 3,
      title: "Restricted Access",
      desc: "Can Only access the tickets assigned to them",
      isChecked: false,
    },
  ]);

  useEffect(() => {
    getGroups();
    getManagers();
  }, []);

  const getGroups = () => {
    apiAuth
      .get("/api/master/group")
      .then((res) => {
        let { data } = res;
        data = data.map((dd) => {
          return {
            label: dd?.name,
            value: dd?.value,
          };
        });
        setGroupOptions(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getManagers = () => {
    apiAuth
      .get("/api/users/")
      .then((res) => {
        let { data } = res;
        data = data.map((dd) => {
          return {
            label: dd?.name,
            value: dd?.value,
          };
        });
        setManagerOptions(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeScopeStatus = (id) => {
    const opts = scopeOptions.map((dd) => {
      const sel = { ...dd };
      if (dd?.id === id) {
        sel["isChecked"] = true;
      } else {
        sel["isChecked"] = false;
      }
      return sel;
    });
    // console.log("oooooo", opts);
    setScopeOptions(opts);
  };

  const handleUpload = () => {
    // Trigger the file input click event when the "Upload" text is clicked
    document.getElementById("fileInput").click();
  };

  const getPic = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      console.log("uuuuuuu", url);
      setSelImg(url);
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "10px",
    }),
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Grid container spacing={2}>
          <Grid item lg={12}>
            <Card className="p-3">
              {props?.isEdit ? (
                ""
              ) : (
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    marginBottom: "2%",
                  }}
                >
                  <img
                    src={AgentImg}
                    alt="Agent"
                    height="20"
                    width="20"
                    style={{ marginRight: "10px" }}
                  />
                  <Typography
                    variant="h5"
                    style={{ color: "#093564", fontWeight: "700" }}
                  >
                    New Agent
                  </Typography>
                </Box>
              )}
              <Formik
                initialValues={{
                  reporting_manager: "",
                  email: "",
                  name: "",
                  job_title: "",
                  mobile: "",
                  group: "",
                  scope: "",
                  image: "",
                  role: "",
                }}
                validationSchema={Yup.object({})}
                onSubmit={(values) => {
                  values["image"] = selImg;
                  console.log("eeeeeee", values);

                  setLoading(true);
                  props?.isEdit
                    ? apiAuth
                        .patch(`/api/master/group/${props?.data?.id}/`, values)
                        .then((res) => {
                          NotificationManager.success(
                            "Agent Updation",
                            "Agent Updated Successfully",
                            3000,
                            null,
                            null,
                            ""
                          );
                          props?.closePopUp();
                        })
                        .catch((err) => {
                          NotificationManager.error(
                            "Agent Updation",
                            "Agent Updation Error",
                            3000,
                            null,
                            null,
                            ""
                          );
                          // props?.closePopUp();
                        })
                    : apiAuth
                        .post("/api/register/", values)
                        .then((res) => {
                          NotificationManager.success(
                            "Agent Creation",
                            "Agent Created Successfully",
                            3000,
                            null,
                            null,
                            ""
                          );
                          props?.history.goBack();
                        })
                        .catch((err) => {
                          NotificationManager.error(
                            "Agent Creation",
                            "Agent Create Error",
                            3000,
                            null,
                            null,
                            ""
                          );
                          // props?.history.goBack();
                        });
                  setLoading(false);
                }}
              >
                {({ values, errors, touched, setFieldValue }) => (
                  <Form className="av-tooltip tooltip-label-bottom">
                    <Row>
                      {/* Agent Details */}
                      {/* <Colxx xs={12}>
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontSize: "20px",
                            marginBottom: "20px",
                          }}
                        >
                          Agent Details
                        </span>
                      </Colxx> */}

                      {/* Row-1 (Name & upload Photo) */}
                      <Grid container spacing={2} className="mb-2">
                        {/* Name */}
                        <Grid item lg={6} xs={12} className="mb-3">
                          <label
                            htmlFor="name"
                            className="form-label"
                            style={{
                              color: "#093564",
                              fontSize: "16px",
                              fontWeight: 600,
                              wordWrap: "break-word ",
                            }}
                          >
                            Name
                          </label>
                          <Field
                            className="form-control"
                            name="name"
                            placeholder="Name..."
                            style={{
                              borderRadius: "10px",
                            }}
                          />
                          <ErrorMessage
                            name="name"
                            render={(msg) => (
                              <div className="text-danger">{msg}</div>
                            )}
                          />
                        </Grid>

                        {/* Upload Photo */}
                        <Grid item lg={6} xs={12} className="mb-3">
                          <div className="d-flex justify-content-around">
                            <div>
                              <img
                                width="70px"
                                height="70px"
                                src={selImg}
                                alt="Img"
                              />
                            </div>
                            <div>
                              <div className="my-1">
                                <span
                                  onClick={handleUpload}
                                  className="text-secondary cursor-pointer"
                                >
                                  Upload Photo
                                </span>
                                <input
                                  type="file"
                                  id="fileInput"
                                  style={{ display: "none" }}
                                  onChange={getPic}
                                />
                              </div>
                              <div>
                                <span>
                                  An image of the person, it's best if it has
                                  the same length and width.
                                </span>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      </Grid>

                      {/* Row-2 (Email & Phone) */}
                      <Grid container spacing={2} className="mb-2">
                        {/* Email */}
                        <Grid item lg={6} xs={12} className="mb-3">
                          <label
                            htmlFor="email"
                            className="form-label"
                            style={{
                              color: "#093564",
                              fontSize: "16px",
                              fontWeight: 600,
                              wordWrap: "break-word ",
                            }}
                          >
                            Email Address
                          </label>
                          <Field
                            className="form-control"
                            name="email"
                            placeholder="Email..."
                            style={{
                              borderRadius: "10px",
                            }}
                          />
                          <ErrorMessage
                            name="email"
                            render={(msg) => (
                              <div className="text-danger">{msg}</div>
                            )}
                          />
                        </Grid>

                        {/* Phone */}
                        <Grid item lg={6} xs={12} className="mb-3">
                          <label
                            htmlFor="mobile"
                            className="form-label"
                            style={{
                              color: "#093564",
                              fontSize: "16px",
                              fontWeight: 600,
                              wordWrap: "break-word ",
                            }}
                          >
                            Mobile
                          </label>
                          <Field
                            className="form-control"
                            name="mobile"
                            placeholder="Mobile..."
                            style={{
                              borderRadius: "10px",
                            }}
                          />
                          <ErrorMessage
                            name="mobile"
                            render={(msg) => (
                              <div className="text-danger">{msg}</div>
                            )}
                          />
                        </Grid>
                      </Grid>

                      {/* Row-3 (Job Title & Reporting Manager) */}
                      <Grid container spacing={2} className="mb-2">
                        {/* Job Title */}
                        <Grid item lg={6} xs={12} className="mb-3">
                          <label
                            htmlFor="job_title"
                            className="form-label"
                            style={{
                              color: "#093564",
                              fontSize: "16px",
                              fontWeight: 600,
                              wordWrap: "break-word ",
                            }}
                          >
                            Job Title
                          </label>
                          <Field
                            className="form-control"
                            name="job_title"
                            placeholder="Job Title..."
                            style={{
                              borderRadius: "10px",
                            }}
                          />
                          <ErrorMessage
                            name="job_title"
                            render={(msg) => (
                              <div className="text-danger">{msg}</div>
                            )}
                          />
                        </Grid>

                        {/* Reporting Manager */}
                        <Grid item lg={6} xs={12} className="mb-3">
                          <div className="mb-3">
                            <label
                              htmlFor="reporting_manager"
                              className="form-label"
                              style={{
                                color: "#093564",
                                fontSize: "16px",
                                fontWeight: 600,
                                wordWrap: "break-word ",
                              }}
                            >
                              Reporting Manager
                            </label>
                            {/* <span className="text-danger">*</span> */}
                            <Select
                              options={managerOptions}
                              value={selManager}
                              onChange={(data) => {
                                setFieldValue("reporting_manager", data?.label);
                                setSelManager(data);
                              }}
                              styles={customStyles}
                            />
                            <ErrorMessage
                              name="reporting_manager"
                              render={(msg) => (
                                <div className="text-danger">{msg}</div>
                              )}
                            />
                          </div>
                        </Grid>
                      </Grid>

                      {/* Row-4 (Roles & Groups) */}
                      <Grid container spacing={2} className="mb-2">
                        {/* Group */}
                        <Grid item lg={6} xs={12} className="mb-3">
                          <div className="mb-3">
                            <label
                              htmlFor="group"
                              className="form-label"
                              style={{
                                color: "#093564",
                                fontSize: "16px",
                                fontWeight: 600,
                                wordWrap: "break-word ",
                              }}
                            >
                              Groups
                            </label>
                            {/* <span className="text-danger">*</span> */}
                            <Select
                              options={groupOptions}
                              value={selGroup}
                              onChange={(data) => {
                                setFieldValue("group", data?.label);
                                setSelGroup(data);
                              }}
                              styles={customStyles}
                            />
                            <ErrorMessage
                              name="group"
                              render={(msg) => (
                                <div className="text-danger">{msg}</div>
                              )}
                            />
                          </div>
                        </Grid>

                        {/* Roles */}
                        <Grid item lg={6} xs={12} className="mb-3">
                          <div className="mb-3">
                            <label
                              htmlFor="role"
                              className="form-label"
                              style={{
                                color: "#093564",
                                fontSize: "16px",
                                fontWeight: 600,
                                wordWrap: "break-word ",
                              }}
                            >
                              Roles
                            </label>
                            <span className="text-danger">*</span>
                            <Select
                              options={roleOptions}
                              value={selRole}
                              onChange={(data) => {
                                setFieldValue("role", data?.label);
                                setSelRole(data);
                              }}
                              styles={customStyles}
                            />
                            <ErrorMessage
                              name="role"
                              render={(msg) => (
                                <div className="text-danger">{msg}</div>
                              )}
                            />
                          </div>
                        </Grid>
                      </Grid>

                      {/* Row-5 (scope) */}
                      <div className="d-flex gap-2">
                        {/* Scope */}
                        <Colxx>
                          <label
                            htmlFor="email"
                            className="form-label"
                            style={{
                              color: "#093564",
                              fontSize: "16px",
                              fontWeight: 600,
                              wordWrap: "break-word ",
                            }}
                          >
                            Scope
                          </label>
                          {scopeOptions.map((dd) => (
                            <div key={dd?.id}>
                              <RadioComp
                                data={dd}
                                selGroup={selGroup}
                                setSelGroup={setSelGroup}
                                groupOptions={groupOptions}
                                onChange={(id) => {
                                  changeScopeStatus(id);
                                  setFieldValue("scope", id);
                                }}
                              />
                            </div>
                          ))}
                        </Colxx>
                      </div>
                    </Row>

                    {/* Submit & Cancel Buttons */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        fontSize: "16px",
                      }}
                    >
                      {loading ? (
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <div className="mt-4 mb-3">
                          <Button
                            className="btn"
                            type="submit"
                            style={{
                              color: "white",
                              fontWeight: 500,
                              background: "#093564",
                              borderRadius: "20px",
                              padding: "8px 40px",
                            }}
                          >
                            {props?.isEdit ? "Update" : "Create"}
                          </Button>
                        </div>
                      )}
                      <div className="mt-4 mb-3 ms-3">
                        <Button
                          className="btn"
                          type="submit"
                          style={{
                            color: "#093564",
                            fontWeight: 500,
                            padding: "8px 0px",
                          }}
                          onClick={() => history.push("/agents")}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Card>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default AddAgent;
