import React, { useState, useEffect } from "react";
import PublicHeader from "../../Layouts/PublicHeader";
import Footer from "../../Layouts/Footer";
import "react-quill/dist/quill.snow.css";
import {
  Button,
  Card,
  Container,
  Grid,
  Typography,
  Input,
  IconButton,
} from "@mui/material";
import styled from "styled-components";
import { InputGroup, InputGroupText } from "reactstrap";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import { useLocation, useHistory } from "react-router-dom";
import apiAuth from "../../helpers/ApiAuth";
import NotificationManager from "../../components/Common/NotificationManager";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const statusStyles = {
  In_Review: {
    color: "#B22B00",
    backgroundColor: "#b22b001a",
  },
  Waiting_for_Customer_Input: {
    color: "#FF0000",
    backgroundColor: "#ff00001a",
  },
  Resolved: {
    color: "#0033B6",
    backgroundColor: "#0033b61a",
  },
  Open: {
    color: "#0033B6",
    backgroundColor: "#0033b61a",
  },
  // Default styles
  Default: {
    color: "#353535",
    backgroundColor: "#3535351a",
  },
};

const TicketDetail = () => {
  const history = useHistory();

  const [selectedFile, setSelectedFile] = useState(null);
  const [commentsData, setCommentsData] = useState([]);
  const [commentValue, setCommentValue] = useState("");
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [data, setdata] = useState("");

  const location = useLocation();
  const mapStatus = {
    Open: "This ticket is Opened",
    Dropped: "This ticket has been Closed",
    Resolved: "This ticket has been resolved",
    In_Review: "Being Processed",
    Waiting_for_Customer_Input: "Awaiting your reply",
  };
  const userData = location.state || {};

  const getBadgeValue = (str) => {
    let list = str?.split(" ") || "";
    if (list?.length > 0) {
      return (
        list[0]?.charAt(0).toUpperCase() + list[1]?.charAt(0)?.toUpperCase()
      );
    } else {
      return list[0]?.charAt(0)?.toUpperCase();
    }
  };
  const hanlderFunc = (e) => {
    setCommentValue(e.target.value);
  };

  const getUserComments = () => {
    apiAuth
      .get(`/api/commentlist/?ticket_id=${userData?.id}`)
      .then((res) => {
        const data = res?.data;
        setCommentsData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("authUser"));
    setdata(data);
    getUserComments();
  }, []);

  const commentHanlder = () => {
    if (commentValue.trim() !== "") {
      const newItem = {
        // description: commentValue,
        // status: userData?.status,
        // created_at: new Date().toISOString(),
        text: commentValue,
        user: data?.id,
        ticket: userData?.id,
      };
      apiAuth
        .post(`/api/master/comment/`, newItem)
        .then((res) => {
          NotificationManager.success(
            "Comment",
            "Comment Added Successfully",
            3000,
            null,
            null,
            ""
          );
          getUserComments();
          setCommentValue("");
        })
        .catch((err) => {
          console.log(err);
          NotificationManager.error(
            "Comment",
            "Comment  Error",
            3000,
            null,
            null,
            ""
          );
        });
    }
  };

  const generateRandomColor = (input) => {
    const colors = ["#c72135", "#e16033", "#dba355", "#324b7b"];

    const randomIndex = input.charCodeAt(0) % colors.length;
    return colors[randomIndex];
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          overflow: "hidden",
        }}
      >
        <PublicHeader ticketPage />
        <div style={{ flex: "1" }}>
          <Card className="ps-5 py-3" style={{ background: "#EC1C24" }}>
            <Grid container>
              <Grid item lg={8} md={7} xs={12}>
                <h1
                  className="mb-1"
                  style={{ margin: "auto", color: "#fff", fontWeight: 900 }}
                >
                  RE: {userData?.subject} #{userData?.id}
                </h1>
                <p
                  className="text-light"
                  style={{ fontSize: "17px", fontWeight: 400 }}
                >
                  Created on{" "}
                  {moment(userData?.created_at).format("ddd D MMM [at] h:mm A")}{" "}
                  - via Portal
                </p>
              </Grid>
            </Grid>
          </Card>
          <Card
            className="mt-5 mb-5"
            style={{
              width: "94%",
              margin: "auto",
              maxHeight: "500px",
              overflowY: "auto",
            }}
          >
            <Grid container>
              <Grid
                item
                lg={8}
                style={{ background: "#FBFBFB", fontSize: "15px" }}
                className="p-4"
              >
                <Grid container>
                  <Grid item lg={12}>
                    {commentsData?.map((item, index) => {
                      const backgroundColor = generateRandomColor(item?.user);

                      return (
                        <>
                          <div
                            className="d-flex mb-3 align-items-center"
                            key={index}
                          >
                            <div
                              style={{
                                width: "35px",
                                height: "35px",
                                backgroundColor: backgroundColor,
                                color: "#fff",
                                display: "flex",
                                borderRadius: "5px",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {item?.user[0].toUpperCase()}
                            </div>
                            <div className="d-flex gap-2">
                              <div
                                className="d-flex flex-column "
                                style={{ paddingLeft: "10px" }}
                              >
                                <div className="d-flex justify-content-between gap-3">
                                  <span
                                    style={{ fontSize: "14px", color: "#000" }}
                                  >
                                    <span
                                      className="fw-bold"
                                      style={{
                                        fontSize: "16px",
                                        color: "#000",
                                      }}
                                    >
                                      {item?.user}
                                    </span>{" "}
                                    reported{" "}
                                    {moment(item?.created_at).fromNow()}
                                  </span>
                                  {index === 0 ? (
                                    <div className="me-5 d-flex text-center align-items-center">
                                      <Button
                                        variant="outlined"
                                        style={{
                                          color:
                                            statusStyles[userData?.status]
                                              ?.color ||
                                            statusStyles.Default.color,
                                          backgroundColor:
                                            statusStyles[userData?.status]
                                              ?.backgroundColor ||
                                            statusStyles.Default
                                              .backgroundColor,
                                          border: "1px solid",
                                          fontSize: "12px",
                                          textTransform: "none",
                                        }}
                                      >
                                        {mapStatus[userData?.status]}
                                      </Button>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <span style={{ color: "#000" }}>
                                  {item?.comment}
                                </span>
                              </div>

                              {/* <div className="me-5 d-flex text-center align-items-center">
                        <Button
                          variant="outlined"
                          style={{
                            color: style.color,
                            backgroundColor: style.backgroundColor,
                            border: "1px solid",
                            fontSize: "12px",
                          }}
                        >
                          {mapStatus[item?.status]}
                        </Button>
                      </div> */}
                              {/* <Button
                              className="px-2"
                              variant="outlined"
                              style={{
                                background: "rgba(178, 43, 0, 0.10)",
                                color: "#B22B00",
                                border: "1px solid rgba(178, 43, 0, 0.50)",
                                fontSize: "14px",
                                textTransform: "capitalize",
                                height: "40%",
                              }}
                            >
                              {userData?.status}
                            </Button> */}
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </Grid>
                  {/* <Grid item lg={6} xs={12}>
                  <div className="d-flex gap-2 justify-content-end">
                    <Button
                      className="px-4"
                      variant="contained"
                      style={{
                        background: "#EB3939",
                        textTransform: "capitalize",
                        borderRadius: "8px",
                      }}
                    >
                      Reply
                    </Button>
                    <Button
                      className="px-4"
                      variant="outlined"
                      style={{
                        background: "#f3f3f3",
                        color: "#000",
                        border: "1px solid rgba(0, 0, 0, 0.30)",
                        fontSize: "14px",
                        textTransform: "capitalize",
                        borderRadius: "8px",
                      }}
                    >
                      Add People
                    </Button>
                    <Button
                      className="px-4"
                      variant="outlined"
                      style={{
                        background: "#f3f3f3",
                        color: "#000",
                        border: "1px solid rgba(0, 0, 0, 0.30)",
                        fontSize: "14px",
                        textTransform: "capitalize",
                        borderRadius: "8px",
                      }}
                    >
                      Close Issue
                    </Button>
                  </div>
                </Grid> */}
                </Grid>
                <div className="d-flex mt-2 mb-3 align-items-center">
                  <div
                    style={{
                      width: "35px",
                      height: "35px",
                      backgroundColor: generateRandomColor(
                        userData?.user_object?.username
                      ),
                      color: "#fff",
                      display: "flex",
                      borderRadius: "5px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {userData?.user_object?.username[0].toUpperCase()}
                  </div>
                  {/* <div style={{ paddingLeft: "10px", width: "50%" }}>
                  <Input
                    className="form-control"
                    name="subject"
                    placeholder="Click here to add a comment"
                    style={{
                      borderRadius: "12px",
                    }}
                    value={commentValue}
                    onBlur={commentHanlder}
                    onChange={hanlderFunc}
                  />
                </div> */}

                  <div style={{ paddingLeft: "10px", width: "50%" }}>
                    <InputGroup className="">
                      <Input
                        className="form-control"
                        name="subject"
                        placeholder="Add Comment"
                        style={
                          {
                            // borderRadius: "12px",
                          }
                        }
                        value={commentValue}
                        // onBlur={commentHanlder}
                        onChange={hanlderFunc}
                      />
                      <InputGroupText
                        className="border-0"
                        onClick={commentHanlder}
                        style={{
                          color: "#fff",
                          background: "#EB3939",
                          fontWeight: 500,
                          textTransform: "capitalize",
                          cursor: "pointer",
                        }}
                      >
                        Add
                      </InputGroupText>
                    </InputGroup>
                  </div>
                </div>
              </Grid>
              <Grid
                item
                lg={4}
                xs={12}
                style={{ background: "#F1F1F1" }}
                className="p-4"
              >
                <h5 className="mb-4 fw-800" style={{ color: "#000" }}>
                  Ticket Details
                </h5>
                <h6 className="mt-3 fw-600" style={{ color: "#000" }}>
                  Status
                </h6>
                <p
                  className="text-muted"
                  style={{ fontSize: "14px", color: "#000" }}
                >
                  {userData?.status}
                </p>
                <h6 className="mt-3 fw-600" style={{ color: "#000" }}>
                  Priority
                </h6>
                <p
                  className="text-muted"
                  style={{ fontSize: "14px", color: "#000" }}
                >
                  {userData?.priority}
                </p>
              </Grid>
            </Grid>
          </Card>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default TicketDetail;
