import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  Input,
  IconButton,
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Colxx } from "../../components/Common/CustomBootstrap";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Card, Container, Label, Row } from "reactstrap";
import BreadCrumb from "../../components/Common/BreadCrumb";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import TicketImg from "../../assets/images/ticket.png";
import apiAuth from "../../helpers/ApiAuth";
import NotificationManager from "../../components/Common/NotificationManager";
import moment from "moment-timezone";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import PublicHeader from "../../Layouts/PublicHeader";
import AttachmentIcon from "@mui/icons-material/Attachment";
import Footer from "../../Layouts/Footer";

const CreateTicket = (props) => {
  const history = useHistory();
  const curDate = new Date();
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [descriptionData, setDescriptionData] = useState("");
  const [selectedTimezone, setSelectedTimezone] = useState(null);
  const [userData, setUserData] = useState({});
  const [userImage, setUserImage] = useState(null);
  const [uploadLogo, setUploadLogo] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [projectOptions, setprojetsOptions] = useState("");
  const [selectedProject, setSelectedProject] = useState(null);
  const handleFileChange = (e) => {
    const file = e?.target?.files[0];
    setSelectedFile(file);
  };
  console.log("uploadLogo", uploadLogo);
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "10px",
    }),
  };

  const handleImageUpload = (event) => {
    console.log("event-----", event);
    const file = event.target.files[0];
    setUserImage(file);
    setUploadLogo(URL.createObjectURL(file));
  };
  const handleButtonClick = (e) => {
    fileInputRef.current.click();
  };

  const isAuthenticated = () => {
    const authuser = localStorage.getItem("authUser");
    return !!authuser; // Return true if authuser is present, false otherwise
  };

  useEffect(() => {
    if (!isAuthenticated()) {
      history.push("/login");
    } else {
      const data = JSON.parse(localStorage.getItem("authUser"));
      setUserData(data);
      getProjects();
    }
  }, []);
  const extractTextFromHtml = (html) => {
    const temporaryElement = document.createElement("div");
    temporaryElement.innerHTML = html;
    return temporaryElement.textContent || temporaryElement.innerText || "";
  };
  const getProjects = () => {
    apiAuth
      .get("/api/master/project")
      .then((res) => {
        const data = res?.data?.results;
        const projectOpts = data?.map((item) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });
        setprojetsOptions(projectOpts);
        const sel = projectOpts?.find(
          (item) => item?.value === props?.selectedTicket?.assigned_agent
        );
        setSelectedProject(sel);
      })
      .catch((err) => {
        setLoading(false);
        NotificationManager.error(
          "Projects",
          "Projects Get Error",
          3000,
          null,
          null,
          ""
        );
      });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          overflow: "hidden",
        }}
      >
        <PublicHeader ticketPage />
        <div style={{ flex: "1" }}>
          <Row className="mt-1">
            <Colxx lg="12" className="p-0">
              <div
                className="ps-5 py-3"
                style={{
                  backgroundColor: "#EC1C24",
                }}
              >
                <h1 className="text-white">Submit a Ticket</h1>
              </div>

              <div className="bg-white">
                <Grid lg={12}>
                  <div className="p-5">
                    <Formik
                      initialValues={{
                        user: userData?.email,
                        subject: "",
                        description: "",
                        project: "",
                        date: curDate,
                        time: moment(curDate).toDate(),
                      }}
                      validationSchema={Yup.object({
                        subject: Yup.string().required("Required!"),
                        project: Yup.string().ensure().required("Required!"),
                      })}
                      onSubmit={(values) => {
                        setLoading(true);
                        const textContent =
                          extractTextFromHtml(descriptionData);
                        let formdata = new FormData();
                        if (values) {
                          formdata.append("user", userData?.id || "");
                          formdata.append("subject", values.subject || "");
                          formdata.append("description", textContent || "");
                          formdata.append("attachment", selectedFile);
                          formdata.append("file_type", "image");
                          formdata.append("project", values?.project);
                          // formdata.append("date", values["date"]?.toISOString());
                          // formdata.append(
                          //   "attachment",
                          //   moment
                          //     ?.tz(values["time"], values["timezonee"])
                          //     ?.format("HH:mm:ss")
                          // );
                        } else {
                          console.log("values object is null or undefined");
                        }
                        apiAuth
                          .post("/api/Userticket/", formdata)
                          .then((res) => {
                            const { data } = res;
                            setLoading(false);
                            NotificationManager.success(
                              "Ticket",
                              "Ticket Created Successfully",
                              3000,
                              null,
                              null,
                              ""
                            );
                            history.push("/user_tickets");
                          })
                          .catch((err) => {
                            setLoading(false);
                            NotificationManager.error(
                              "Ticket",
                              "Ticket Create Error",
                              3000,
                              null,
                              null,
                              ""
                            );
                          });
                      }}
                    >
                      {({ values, errors, touched, setFieldValue }) => (
                        <Form className="av-tooltip tooltip-label-bottom">
                          <Row className="my-1">
                            <Colxx xs={12} lg={10}>
                              <Grid container>
                                <Grid item lg={4} xs={12} className="mb-3">
                                  <label
                                    htmlFor="user"
                                    className="form-label text-primary"
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: 600,
                                      wordWrap: "break-word ",
                                    }}
                                  >
                                    Requester
                                  </label>
                                  <Field
                                    className="form-control"
                                    name="user"
                                    placeholder="sandeep16.redd@gmail.com"
                                    style={{
                                      borderRadius: "10px",
                                      background: "#ffffff",
                                      border: "none",
                                    }}
                                    value={userData?.email}
                                    disabled={true}
                                  />
                                </Grid>
                                <Grid item lg={4} xs={12}>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="date"
                                      className="form-label text-primary"
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: 600,
                                        wordWrap: "break-word ",
                                      }}
                                    >
                                      Date
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div
                                      style={{
                                        display: "flex",
                                      }}
                                    >
                                      <DatePicker
                                        customInput={
                                          <input
                                            style={{
                                              borderRadius: "10px",
                                              height: "40px",
                                              paddingLeft: "20px",
                                              border:
                                                "1px solid var(--vz-input-border)",
                                            }}
                                          />
                                        }
                                        selected={values["date"]}
                                        onChange={(date) => {
                                          setFieldValue("date", date);
                                          // setSelectedDate(date);
                                        }}
                                        dateFormat="d MMMM yyyy"
                                      />
                                      <div
                                        style={{
                                          position: "relative",
                                        }}
                                      >
                                        <span
                                          style={{
                                            position: "absolute",
                                            top: 8,
                                            right: 10,
                                            fill: "red",
                                          }}
                                        >
                                          <img
                                            src="/calendar.svg"
                                            alt="calendar"
                                            width="20px"
                                            height="20px"
                                          />
                                        </span>
                                      </div>
                                    </div>
                                    {errors.date && touched.date && (
                                      <div className="invalid-feedback d-block">
                                        {errors.date}
                                      </div>
                                    )}
                                  </div>
                                </Grid>
                                <Grid item lg={4} xs={12}>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="time"
                                      className="form-label text-primary"
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: 600,
                                        wordWrap: "break-word ",
                                      }}
                                    >
                                      Time
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div
                                      style={{
                                        display: "flex",
                                      }}
                                    >
                                      <DatePicker
                                        customInput={
                                          <input
                                            style={{
                                              borderRadius: "10px",
                                              height: "40px",
                                              paddingLeft: "20px",
                                              border:
                                                "1px solid var(--vz-input-border)",
                                            }}
                                          />
                                        }
                                        selected={values["time"]}
                                        onChange={(date) => {
                                          setFieldValue("time", date);
                                          // console.log("ss-s--s-s", date);
                                          // setSelectedTime(date);
                                        }}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15} // Optional: Set the time interval between selections (in minutes)
                                        timeCaption="time"
                                        timeFormat="h:mm aa"
                                        dateFormat="h:mm aa"
                                      />
                                      <div
                                        style={{
                                          position: "relative",
                                        }}
                                      >
                                        <span
                                          style={{
                                            position: "absolute",
                                            top: 8,
                                            right: 10,
                                            fill: "red",
                                          }}
                                        >
                                          <AccessTimeIcon />
                                        </span>
                                      </div>
                                    </div>
                                    {errors.time && touched.time && (
                                      <div className="invalid-feedback d-block">
                                        {errors.time}
                                      </div>
                                    )}
                                  </div>
                                </Grid>
                              </Grid>

                              <Grid item lg={10} xs={12} className="mb-3">
                                <div className="mb-3">
                                  <label
                                    htmlFor="project"
                                    className="form-label text-primary"
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: 600,
                                      wordWrap: "break-word ",
                                    }}
                                  >
                                    Project
                                    <span className="text-danger">*</span>
                                  </label>
                                  <Select
                                    options={projectOptions}
                                    value={selectedProject}
                                    onChange={(data) => {
                                      setFieldValue("project", data?.value);
                                      setSelectedProject(data);
                                    }}
                                    styles={customStyles}
                                    placeholder="Select Project"
                                  />
                                  <ErrorMessage
                                    name="project"
                                    render={(msg) => (
                                      <div className="text-danger">{msg}</div>
                                    )}
                                  />
                                </div>
                              </Grid>

                              <Grid item lg={10} xs={12} className="mb-3">
                                <label
                                  htmlFor="subject"
                                  className="form-label text-primary"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: 600,
                                    wordWrap: "break-word ",
                                  }}
                                >
                                  Subject
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  className="form-control"
                                  name="subject"
                                  placeholder="Write something that describes this ticket."
                                  style={{
                                    borderRadius: "10px",
                                  }}
                                />
                                <ErrorMessage
                                  name="subject"
                                  render={(msg) => (
                                    <div className="text-danger">{msg}</div>
                                  )}
                                />
                              </Grid>
                            </Colxx>
                          </Row>
                          <Row className="my-1">
                            <Colxx xs={12} lg={10}>
                              <div
                                className="my-1"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  wordWrap: "break-word ",
                                }}
                              >
                                Description
                              </div>
                              <div
                                style={{
                                  height: "150px",
                                  overflowY: "hidden",
                                  border: "0.5px solid #D3D3D3",
                                  borderRadius: "10px",
                                }}
                              >
                                <ReactQuill
                                  theme="snow"
                                  value={descriptionData}
                                  onChange={(data) => {
                                    setDescriptionData(data);
                                  }}
                                  placeholder="Write something that describes this ticket."
                                  style={{ height: "100%" }}
                                />
                              </div>
                            </Colxx>
                          </Row>

                          <div>
                            <Input
                              type="file"
                              accept=".pdf, .doc, .docx"
                              onChange={handleFileChange}
                              style={{ display: "none" }}
                              id="file-input"
                            />
                            <label
                              htmlFor="file-input"
                              style={{ cursor: "pointer" }}
                            >
                              <IconButton
                                color="primary"
                                aria-label="upload file"
                                component="span"
                              >
                                {/* <AttachFileIcon /> */}
                                <AttachmentIcon />
                              </IconButton>
                              <span
                                style={{
                                  textTransform: "capitalize",
                                  color: "#093564",
                                }}
                              >
                                Add Attachment
                              </span>
                            </label>
                            <Typography variant="body1">
                              {selectedFile
                                ? selectedFile.name
                                : "No file selected"}
                            </Typography>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "center",
                              fontSize: "16px",
                            }}
                          >
                            {loading ? (
                              <div
                                className="spinner-border text-primary"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              <div className="mt-4 mb-3">
                                <Button
                                  className="btn "
                                  type="submit"
                                  style={{
                                    color: "white",
                                    fontWeight: 500,
                                    background: "#093564",
                                    borderRadius: "20px",
                                    padding: "8px 40px",
                                  }}
                                >
                                  {props?.isEdit ? "Update" : "Submit"}
                                </Button>
                              </div>
                            )}
                            <div className="mt-4 mb-3 ms-3">
                              <Button
                                className="btn text-primary"
                                type="submit"
                                style={{
                                  fontWeight: 500,
                                  padding: "8px 0px",
                                }}
                                onClick={() => history.push("/user_tickets")}
                              >
                                Cancel
                              </Button>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </Grid>
              </div>
            </Colxx>
          </Row>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CreateTicket;
