import React, { useState, useEffect } from "react";
import { Card, Container, Row } from "reactstrap";
import { Colxx } from "../../components/Common/CustomBootstrap";

import BreadCrumb from "../../components/Common/BreadCrumb";
import apiAuth from "../../helpers/ApiAuth";
import NotificationManager from "../../components/Common/NotificationManager";
import UserTable from "./UserTable";

const Users = () => {
  const [usersData, setusersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [managerOptions, setManagerOptions] = useState([]);

  useEffect(() => {
    getUsersData();
    getALLReportingManagers();
  }, []);

  const getUsersData = () => {
    apiAuth
      .get(`/api/get-users/`)
      .then((response) => {
        let data = response?.data;
        // data = data.filter((dd) => dd?.id !== 56);
        setusersData(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const deleteUser = (id) => {
    let url = `/api/register/${id}/`;
    apiAuth
      .delete(url)
      .then((response) => {
        const newdata = response.data;
        NotificationManager.success(
          "",
          "User Deleted Successfully",
          3000,
          null,
          null,
          ""
        );
        getUsersData();
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response?.data);
        console.log(error.response?.status);
        console.log(error.response?.headers);
      });
  };

  const getALLReportingManagers = () => {
    apiAuth
      .get("/api/manager/")
      .then((res) => {
        const data = res?.data;
        const managerOpts = data?.map((item) => {
          return {
            label: item?.username,
            value: item?.id,
          };
        });
        setManagerOptions(managerOpts);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Users"
            pageTitle="Settings"
            add_new={true}
            add_new_url={`/users/add`}
            search_functionality={true}
            searchValue={searchValue}
            // add_upload={true}
            // add_upload_url={"/users/upload"}
            setSearchValue={(val) => {
              setSearchValue(val);
            }}
          />
        </Container>
        <Row>
          <Colxx lg="12">
            <>
              {loading ? (
                <div className="loading"></div>
              ) : (
                <>
                  {" "}
                  <Card>
                    <UserTable
                      // usersData={usersData}
                      usersData={usersData.filter((item) =>
                        String(item.full_name)
                          ?.toLowerCase()
                          .includes(String(searchValue)?.toLowerCase())
                      )}
                      deleteUser={deleteUser}
                      getUsersData={() => getUsersData()}
                      managerOptions={managerOptions}
                    />
                  </Card>
                </>
              )}
            </>
          </Colxx>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Users;
