import React from "react";
import { useHistory } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import {
  AppBar,
  Box,
  Button,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import PropTypes from "prop-types";
import ProfileDropdown from "../components/Common/ProfileDropdown";
import SimpliforceLogo1 from "../assets/images/SimpliforceLogo1.png";

const drawerWidth = 260;

const PublicHeader = (props) => {
  const history = useHistory();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <div>
      <nav
        className="navbar navbar-expand-lg shadow-lg"
        style={{ backgroundColor: "#E9E9E9" }}
      >
        <div className="container-fluid mx-4 p-0 d-flex gap-2">
          <a
            className="navbar-brand"
            href={
              localStorage.getItem("role") === "user" ? "/home" : "/dashboard"
            }
          >
            <img
              src={props.transparent ? SimpliforceLogo1 : SimpliforceLogo1}
              alt=""
              width={200}
            />
          </a>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
            <ul
              className="navbar-nav mb-2 mb-lg-0"
              style={{ marginLeft: "auto", fontFamily: "Inter" }}
            >
              <li className="nav-item m-auto">
                {/* <a className="nav-link" href="/knowledge">
                  <SearchIcon /> Knowledge Base
                </a> */}
                <Button
                  style={{
                    color: "#000",
                    cursor: "pointer",
                    margin: "auto",
                    fontSize: "16px",
                    fontFamily: "Inter",
                    textTransform: "capitalize",
                    padding: "6px 8px",
                    fontWeight: 600,
                  }}
                  onClick={() => history.push("/knowledge")}
                >
                  <SearchIcon /> Knowledge Base
                </Button>
              </li>
              {localStorage.getItem("role") !== "user" && (
                <li className="nav-item m-auto">
                  <Button
                    variant="outlined"
                    onClick={() => history.push("/login")}
                    // className={[

                    //   props.transparent ? "text-dark" : "text-dark",
                    // ].join(" ")}
                    style={{
                      border: "1px solid red",
                      fontWeight: "600",
                      fontFamily: "Inter",
                      margin: "auto",
                      padding: "6px 8px",
                      fontSize: "16px",
                      textTransform: "none",
                      color: props.transparent ? "#000" : "#000",
                    }}
                  >
                    Login to Support Portal
                  </Button>
                </li>
              )}
              <li className="nav-item m-auto">
                <Button
                  sx={{
                    color: "#000",
                    cursor: "pointer",
                    margin: "auto",
                    fontSize: "16px",
                    // marginLeft: "10px",
                    fontFamily: "Inter",
                    textTransform: "capitalize",
                    padding: "6px 8px",
                  }}
                  onClick={() => history.push("/home")}
                >
                  Home
                </Button>
              </li>
              {localStorage.getItem("authUser") ? (
                <></>
              ) : (
                <li className="nav-item m-auto">
                  <Button
                    variant="contained"
                    onClick={() => history.push("/login")}
                    style={{
                      color: "#fff",
                      background: "#EB3939",
                      fontWeight: 600,
                      textTransform: "capitalize",
                      borderRadius: "30px",
                      margin: "auto",
                      padding: "6px 8px",
                      fontFamily: "Inter",
                    }}
                  >
                    Login
                  </Button>
                </li>
              )}
              {localStorage.getItem("role") === "user" && (
                <li className="nav-item m-auto">
                  <Button
                    sx={{
                      color: "#000",
                      cursor: "pointer",
                      margin: "auto",
                      fontSize: "16px",
                      // marginLeft: "10px",
                      fontFamily: "Inter",
                      textTransform: "capitalize",
                      padding: "6px 8px",
                    }}
                    onClick={() => history.push("/user_tickets")}
                  >
                    Tickets
                  </Button>
                </li>
              )}
              {localStorage.getItem("role") === "user" && (
                <li className="nav-item m-auto p-2">
                  <Button
                    variant="contained"
                    onClick={() => history.push("/create-ticket")}
                    style={{
                      color: "#fff",
                      background: "#EB3939",
                      fontWeight: 600,
                      textTransform: "capitalize",
                      borderRadius: "30px",
                      height: "40px",
                      margin: "auto",
                      padding: "6px 8px",
                      fontFamily: "Inter",
                    }}
                  >
                    Submit a Ticket
                  </Button>
                </li>
              )}

              {localStorage.getItem("role") === "user" && (
                <li className="nav-item m-auto">
                  <ProfileDropdown ticketPage={props?.ticketPage} />
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default PublicHeader;
