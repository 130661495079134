import { PriorityLabel } from "./Helpers";

export const priorityOptions = [
  {
    label: <PriorityLabel label={"Low"} bg="#34eb46" />,
    value: "Low",
  },
  { label: <PriorityLabel label={"Medium"} bg="#dbeb34" />, value: "Medium" },
  { label: <PriorityLabel label={"High"} bg="#eb7134" />, value: "High" },
  { label: <PriorityLabel label={"Urgent"} bg="#eb3434" />, value: "Urgent" },
];

export const statusOptions = [
  { label: "Open", value: "Open" },
  { label: "In Review", value: "In_Review" },
  {
    label: "Waiting for Customer Input",
    value: "Waiting_for_Customer_Input",
  },
  { label: "Resolved", value: "Resolved" },
  { label: "Dropped", value: "Dropped" },
];

export const typeOptions = [
  { label: "Incident", value: "Incident" },
  { label: "Request", value: "Request" },
  { label: "Issue", value: "Issue" },
  { label: "Question", value: "Question" },
];
