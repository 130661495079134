export const customStyles = {
  table: {
    style: {
      minHeight: "170px",
      maxHeight: "100%",
      backgroundColor: "#fff !important",
      overflowY: "scroll",
    },
  },

  th: {
    style: {
      fontSize: "30px",
    },
  },
  // title: {
  //   style: {
  //     fontColor: "red",
  //     fontWeight: "900",
  //   },
  // },
  headCells: {
    style: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      marginTop: "10px",
      fontSize: "14px",
      margin: "auto",
    },
  },
  contextMenu: {
    style: {
      // fontSize: " 200px",
    },
  },
  cells: {
    style: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      overflow: "none",
      minHeight: "56px",
      fontSize: "17px",
    },
  },
  headRow: {
    style: {
      // color: "#1062fe",
      backgroundColor: "#093564",
      color: "#fff",
      display: "flex",
    },
  },
  rows: {
    style: {
      color: "#000",
      backgroundColor: "#fff",
      border: "1px solid #d3d3d3",
    },
  },

  pagination: {
    style: {
      color: "#000",
      backgroundColor: "#f3f3f9",
    },
  },
};
