import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Col, Container, Row, Input, Label } from "reactstrap";
import AuthSlider from "../authCarousel";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  apiError,
  registerPublicUser,
  loginUser,
} from "../../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import NotificationManager from "../../../components/Common/NotificationManager";
import { Country, State, City } from "country-state-city";
import Select from "react-select";
import { Grid } from "@mui/material";

const CoverSignUp = (props) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const { logError } = useSelector((state) => ({
    logError: state.Login.error,
  }));

  useEffect(() => {
    if (logError) {
      NotificationManager.error("", logError, 3000, null, null, "");
      dispatch(apiError(""));
    }
  }, [logError]);

  const validateEmail = (value) => {
    let error;
    if (!value) {
      error = "Please enter your email address";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  };

  const validatePassword = (value) => {
    let error;
    let regExp = new RegExp(
      "^(?=.*\\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$"
    );

    if (!value) {
      error = "Please enter your password";
    } else if (value.length < 6) {
      error = "Value must be longer than 5 characters";
    } else if (!regExp.test(value)) {
      error =
        "Password must have at least One Uppercase, One Number, One Lowercase, And One Special Character";
    }

    return error;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const responseSuccessGoogle = (response) => {
    dispatch(loginUser(response, props.history, "google"));
  };

  useEffect(() => {
    /* global google */
    if (window.google) {
      google.accounts.id.initialize({
        client_id:
          "618283004370-4o0tkge5ja8b9bkti0alkv3rcajo8bjj.apps.googleusercontent.com",
        callback: responseSuccessGoogle,
      });

      google.accounts.id.renderButton(document.getElementById("loginDiv"), {
        theme: "filled_black",
        text: "continue_with",
        shape: "pill",
      });
    }
  }, [responseSuccessGoogle]);

  return (
    <React.Fragment>
      <div className="auth-page-wrapper">
        <div className="bg-overlay"></div>
        <div className="auth-page-content overflow-hidden pb-0">
          <Row>
            <Col lg={12}>
              <Card className="overflow-hidden m-0">
                <Row className="justify-content-center g-0">
                  <AuthSlider />

                  <Col lg={6} style={{ overflowY: "scroll", height: "100vh" }}>
                    <div className="p-lg-5 p-4">
                      <div>
                        <h1 className="text-dark">Sign Up</h1>
                      </div>

                      <div className="mt-4">
                        <Formik
                          initialValues={{
                            first_name: "",
                            last_name: "",
                            email: "",
                            password: "",
                            confirmPassword: "",
                            mobile: "",
                            company_name: "",
                            company_email: "",
                            company_address: "",
                            state: "",
                            country: "",
                          }}
                          validationSchema={Yup.object({
                            first_name: Yup.string()
                              .max(20, "Must be 20 characters or less")
                              .trim()
                              .required("First name is Required"),
                            last_name: Yup.string()
                              .max(20, "Must be 20 characters or less")
                              .trim()
                              .required("Last name is Required"),
                            email: Yup.string()
                              .email()
                              .required("Email is Required"),
                            password: Yup.string().required(
                              "Password is Required"
                            ),
                            confirmPassword: Yup.string()
                              .oneOf(
                                [Yup.ref("password"), null],
                                "Passwords must match"
                              )
                              .required("Enter your password again"),
                            mobile: Yup.string()
                              .max(10, "Must be 10 characters")
                              .trim()
                              .required("Mobile Number is Required"),
                            company_name: Yup.string()
                              .trim()
                              .required("Company Name is Required"),
                            company_email: Yup.string()
                              .email()
                              .required("Company Email is Required"),
                            company_address: Yup.string()
                              .trim()
                              .required("Company Address is Required"),

                            country: Yup.string()
                              .ensure()
                              .required("Country is Required"),
                            state: Yup.string()
                              .ensure()
                              .required("State is Required"),
                          })}
                          onSubmit={(values) => {
                            values.state = values.state
                              ? values.state
                              : undefined;
                            values.country = values.country
                              ? values.country
                              : undefined;
                            console.log("values", values);
                            dispatch(registerPublicUser(values, props.history));
                          }}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            setFieldValue,
                            handleBlur,
                          }) => (
                            <Form className="av-tooltip tooltip-label-bottom">
                              <Grid container spacing={2}>
                                <Grid item lg={6} xs={12}>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="first_name"
                                      className="form-label"
                                    >
                                      First Name
                                      <span className="text-danger">*</span>
                                    </label>
                                    <Field
                                      className="form-control"
                                      placeholder="Robert"
                                      name="first_name"
                                    />
                                    {errors.first_name &&
                                      touched.first_name && (
                                        <div className="invalid-feedback d-block">
                                          {errors.first_name}
                                        </div>
                                      )}
                                  </div>
                                </Grid>

                                <Grid item lg={6} xs={12}>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="last_name"
                                      className="form-label"
                                    >
                                      Last Name
                                      <span className="text-danger">*</span>
                                    </label>
                                    <Field
                                      className="form-control"
                                      placeholder="Downey"
                                      name="last_name"
                                    />
                                    {errors.last_name && touched.last_name && (
                                      <div className="invalid-feedback d-block">
                                        {errors.last_name}
                                      </div>
                                    )}
                                  </div>
                                </Grid>
                              </Grid>

                              <Grid container spacing={2}>
                                <Grid item lg={6} xs={12}>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="email"
                                      className="form-label"
                                    >
                                      Email Address
                                      <span className="text-danger">*</span>
                                    </label>
                                    <Field
                                      className="form-control"
                                      placeholder="robert@gmail.com"
                                      name="email"
                                    />
                                    {errors.email && touched.email && (
                                      <div className="invalid-feedback d-block">
                                        {errors.email}
                                      </div>
                                    )}
                                  </div>
                                </Grid>
                                <Grid item lg={6} xs={12}>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="mobile"
                                      className="form-label"
                                    >
                                      Mobile Number
                                      <span className="text-danger">*</span>
                                    </label>
                                    <Field
                                      className="form-control"
                                      placeholder="99876543210"
                                      name="mobile"
                                    />
                                    {errors.mobile && touched.mobile && (
                                      <div className="invalid-feedback d-block">
                                        {errors.mobile}
                                      </div>
                                    )}
                                  </div>
                                </Grid>
                              </Grid>

                              <Grid container spacing={2}>
                                <Grid item lg={6} xs={12}>
                                  <div className="mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="password"
                                    >
                                      Enter Password{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div className="position-relative auth-pass-inputgroup">
                                      <Field
                                        className="form-control"
                                        placeholder="aBcd1@34"
                                        type={
                                          showPassword ? "text" : "password"
                                        }
                                        name="password"
                                        validate={validatePassword()}
                                      />

                                      <div
                                        className={[
                                          "btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted",
                                        ].join(" ")}
                                        onClick={() => {
                                          setShowPassword((prev) => !prev);
                                        }}
                                        id="password-addon"
                                      >
                                        <i
                                          className={[
                                            showPassword
                                              ? "ri-eye-close-line"
                                              : "ri-eye-fill",
                                            "align-middle",
                                          ].join(" ")}
                                        ></i>
                                      </div>

                                      {errors.password && touched.password && (
                                        <div className="invalid-feedback d-block">
                                          {errors.password}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </Grid>

                                <Grid item lg="6" xs="12">
                                  <div className="mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="confirmPassword"
                                    >
                                      Confirm Password{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div className="position-relative auth-pass-inputgroup">
                                      <Field
                                        className="form-control"
                                        placeholder="aBcd1@34"
                                        type={
                                          showPassword ? "text" : "password"
                                        }
                                        name="confirmPassword"
                                        validate={validatePassword()}
                                      />

                                      <div
                                        className={[
                                          "btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted",
                                        ].join(" ")}
                                        onClick={() => {
                                          setShowPassword((prev) => !prev);
                                        }}
                                        id="password-addon"
                                      >
                                        <i
                                          className={[
                                            showPassword
                                              ? "ri-eye-close-line"
                                              : "ri-eye-fill",
                                            "align-middle",
                                          ].join(" ")}
                                        ></i>
                                      </div>

                                      {errors.confirmPassword &&
                                        touched.confirmPassword && (
                                          <div className="invalid-feedback d-block">
                                            {errors.confirmPassword}
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>

                              <Grid container spacing={2}>
                                <Grid item lg={6} xs={12}>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="company_name"
                                      className="form-label"
                                    >
                                      Company Name
                                      <span className="text-danger">*</span>
                                    </label>
                                    <Field
                                      className="form-control"
                                      placeholder="FalconAVL"
                                      name="company_name"
                                    />
                                    {errors.company_name &&
                                      touched.company_name && (
                                        <div className="invalid-feedback d-block">
                                          {errors.company_name}
                                        </div>
                                      )}
                                  </div>
                                </Grid>
                                <Grid item lg={6} xs={12}>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="company_email"
                                      className="form-label"
                                    >
                                      Company Email Address
                                      <span className="text-danger">*</span>
                                    </label>
                                    <Field
                                      className="form-control"
                                      placeholder="falcon.org@gmail.com"
                                      name="company_email"
                                    />
                                    {errors.company_email &&
                                      touched.company_email && (
                                        <div className="invalid-feedback d-block">
                                          {errors.company_email}
                                        </div>
                                      )}
                                  </div>
                                </Grid>
                              </Grid>

                              <div className="mb-3">
                                <label
                                  htmlFor="company_address"
                                  className="form-label"
                                >
                                  Company Address
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  className="form-control"
                                  placeholder="740 Ave Baker street, Manhattan"
                                  name="company_address"
                                />
                                {errors.company_address &&
                                  touched.company_address && (
                                    <div className="invalid-feedback d-block">
                                      {errors.company_address}
                                    </div>
                                  )}
                              </div>

                              <Grid container spacing={2}>
                                <Grid
                                  item
                                  xs={12}
                                  lg={6}
                                  style={{ zIndex: "200" }}
                                >
                                  <div className="mb-3">
                                    <label
                                      htmlFor="country"
                                      className="form-label"
                                    >
                                      Country
                                      <span className="text-danger">*</span>
                                    </label>
                                    <Select
                                      options={Country.getAllCountries().map(
                                        (state) => {
                                          return {
                                            label: state.name,
                                            value: state.isoCode,
                                          };
                                        }
                                      )}
                                      onBlur={handleBlur}
                                      value={selectedCountry}
                                      onChange={(data) => {
                                        setFieldValue("country", data.label);
                                        setSelectedCountry(data);
                                      }}
                                    />
                                    {errors.country && touched.country && (
                                      <div className="invalid-feedback d-block">
                                        {errors.country}
                                      </div>
                                    )}
                                  </div>
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  lg={6}
                                  style={{ zIndex: "100" }}
                                >
                                  <div className="mb-3">
                                    <label
                                      htmlFor="state"
                                      className="form-label"
                                    >
                                      State
                                      <span className="text-danger">*</span>
                                    </label>
                                    <Select
                                      options={State.getStatesOfCountry(
                                        selectedCountry?.value
                                      )?.map((state) => {
                                        return {
                                          label: state.name,
                                          value: state.isoCode,
                                        };
                                      })}
                                      required
                                      value={selectedState}
                                      onChange={(data) => {
                                        setFieldValue("state", data.label);
                                        setSelectedState(data);
                                      }}
                                    />
                                    {errors.state && touched.state && (
                                      <div className="invalid-feedback d-block">
                                        {errors.state}
                                      </div>
                                    )}
                                  </div>
                                </Grid>
                              </Grid>

                              <div className="mt-4 mb-3">
                                <button
                                  className="btn btn-success w-100"
                                  type="submit"
                                >
                                  Sign Up
                                </button>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>

                      <div className="mt-4 text-center">
                        <div>
                          <div className="signin-other-title mt-4">
                            <h5
                              className="fs-13 mb-4 title"
                              style={{ color: "#666" }}
                            >
                              OR
                            </h5>
                          </div>
                        </div>
                      </div>

                      <div
                        id="loginDiv"
                        style={{
                          textAlign: "-webkit-center",
                          marginTop: "25px",
                        }}
                      ></div>

                      <div className="mt-5 text-center">
                        <p className="mb-0">
                          Have an account ?{" "}
                          <Link
                            to="/auth-signin-cover"
                            className="fw-semibold text-primary text-decoration-underline"
                          >
                            {" "}
                            Login
                          </Link>{" "}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>     
      </div>
    </React.Fragment>
  );
};

export default CoverSignUp;
