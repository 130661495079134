import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LoginSocialFacebook } from "reactjs-social-login";
import { FacebookLoginButton } from "react-social-login-buttons";
import { Card, Col, Container, Input, Label, Row, Button } from "reactstrap";
import { Formik, Form, Field } from "formik";
import { apiError, loginUser } from "../../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import * as MicrosoftGraph from "@microsoft/microsoft-graph-client";
// import * as Msal from "msal";
import { PublicClientApplication } from "@azure/msal-browser";
import { Microsoft } from "react-bootstrap-icons";
import NotificationManager from "../../../components/Common/NotificationManager";
import * as Yup from "yup";
import LoginBg from "../../../assets/images/login-bg.png";
import LinkedIn from "../../../assets/images/LinkedIn.png";
import SimpliforceLogo1 from "../../../assets/images/SimpliforceLogo1.png";
import Header from "../../../Layouts/Header";
import Footer from "../../../Layouts/Footer";
import HeaderTwo from "../../../Layouts/HeaderTwo";

const CoverSignIn = (props) => {
  const dispatch = useDispatch();
  const { logError } = useSelector((state) => ({
    logError: state.Login.error,
  }));
  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    if (logError) {
      NotificationManager.error("", logError, 3000, null, null, "");
      dispatch(apiError(""));
    }
  }, [logError]);

  const validateEmail = (value) => {
    let error;
    if (!value) {
      error = "Please enter your email address";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  };

  const validatePassword = (value) => {
    let error;
    let regExp = new RegExp(
      "^(?=.*\\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$"
    );

    if (!value) {
      error = "Please enter your password";
    } else if (value.length < 6) {
      error = "Value must be longer than 5 characters";
    } else if (!regExp.test(value)) {
      error =
        "Password must have at least One Uppercase, One Number, One Lowercase, And One Special Character";
    }

    return error;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const responseSuccessGoogle = (response) => {
    console.log("google response------", response);
    dispatch(loginUser(response, props.history, "google"));
  };

  useEffect(() => {
    /* global google */
    if (window.google) {
      google.accounts.id.initialize({
        client_id:
          "578586401941-afd5dm86n6sh978s3bi8f1hk37tsur3f.apps.googleusercontent.com",
        callback: responseSuccessGoogle,
      });

      google.accounts.id.renderButton(document.getElementById("loginDiv"), {
        // theme: "filled_black",
        text: "Sign in with Google",
        width: "210px",
        // shape: "pill",
        style: {
          display: "block",
          width: "100%",
          border: "none !important",
          outline: "none",
          boxShadow: "none",
        },
      });
    }
  }, [responseSuccessGoogle]);

  const responseSuccessMicrosoft = (response) => {
    dispatch(loginUser(response, props.history, "microsoft"));
  };

  useEffect(() => {
    const msalConfig = {
      auth: {
        clientId: "28478444-26ec-415c-89c9-812e11b98d1b",
        authority:
          "https://login.microsoftonline.com/53f0be9d-edae-4d1b-a791-ffc50b431d28",
        redirectUri: "https://helpdesk.simpliforce.com",
      },
    };

    const msalInstance = new PublicClientApplication(msalConfig);

    const loginRequest = {
      scopes: ["User.Read"],
    };

    const initMicrosoftSignIn = () => {
      const msLoginButton = document.getElementById("msLoginButton");
      if (msLoginButton) {
        msLoginButton.addEventListener("click", microsoftLogin);
      }
    };

    const microsoftLogin = async () => {
      try {
        console.log("inside login ms");
        // Ensure MSAL instance is initialized before making API calls
        await msalInstance.initialize();

        const response = await msalInstance.loginPopup(loginRequest);
        console.log("ressss", response);
        responseSuccessMicrosoft(response);
      } catch (error) {
        console.log("inside catch block", error);
        console.error("Error during Microsoft sign-in: ", error);
      }
    };

    initMicrosoftSignIn();

    // Cleanup function to remove event listener
    return () => {
      const msLoginButton = document.getElementById("msLoginButton");
      if (msLoginButton) {
        msLoginButton.removeEventListener("click", microsoftLogin);
      }
    };
  }, [responseSuccessMicrosoft]);

  return (
    <>
      <div
        className="w-100 bg-white"
        style={{
          // height: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <HeaderTwo />
        <div className="mt-2" style={{ flex: "1" }}>
          <Row style={{ placeItems: "center" }}>
            <Col lg={6}>
              <div className="p-lg-5 p-4 d-flex flex-column align-items-center">
                <div className="d-flex justify-content-center">
                  <h2
                    className="text-primary text-center"
                    style={{ fontFamily: "Inter", fontWeight: "bold" }}
                  >
                    Sign In to <br />
                    Simpliforce Help Desk
                  </h2>
                </div>

                <div className="mt-5 w-80">
                  <Formik
                    initialValues={{
                      email: "",
                      password: "",
                    }}
                    validationSchema={Yup.object({
                      email: Yup.string().email().required("Email is Required"),
                      password: Yup.string().required("Password is Required"),
                    })}
                    onSubmit={(values) => {
                      if (values.email && values.password)
                        dispatch(loginUser(values, props.history));
                    }}
                  >
                    {({ errors, touched }) => (
                      <Form className="av-tooltip tooltip-label-bottom">
                        <div className="position-relative auth-pass-inputgroup mb-4">
                          <Field
                            className="form-control ps-5"
                            name="email"
                            placeholder="username@example.com"
                            validate={validateEmail()}
                            style={{ fontFamily: "Inter" }}
                          />
                          <div
                            className={[
                              "btn btn-link position-absolute left-0 top-0 text-decoration-none text-muted",
                            ].join(" ")}
                            id="email-addon"
                          >
                            <i className={"ri-mail-fill align-middle"}></i>
                          </div>
                          {errors.email && touched.email && (
                            <div className="invalid-feedback d-block">
                              {errors.email}
                            </div>
                          )}
                        </div>

                        <div className="mb-4">
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <Field
                              className="form-control"
                              type={showPassword ? "text" : "password"}
                              name="password"
                              validate={validatePassword()}
                              style={{ fontFamily: "Inter" }}
                            />

                            <div
                              className={[
                                "btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted",
                              ].join(" ")}
                              onClick={(e) => {
                                setShowPassword((prev) => !prev);
                              }}
                              id="password-addon"
                            >
                              <i
                                className={[
                                  showPassword
                                    ? "ri-eye-close-line"
                                    : "ri-eye-fill",
                                  "align-middle",
                                ].join(" ")}
                              ></i>
                            </div>

                            {errors.password && touched.password && (
                              <div className="invalid-feedback d-block">
                                {errors.password}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="form-check text-primary">
                          <Input
                            className="form-check-input"
                            type="radio"
                            value=""
                            id="auth-remember-check"
                          />
                          <Label
                            className="form-check-label"
                            htmlFor="auth-remember-check"
                            style={{ fontFamily: "Inter" }}
                          >
                            Remember me
                          </Label>
                          <div className="float-end">
                            <Link
                              to="/auth-pass-reset-cover"
                              className="text-primary"
                              style={{ fontFamily: "Inter" }}
                            >
                              Forgot password?
                            </Link>
                          </div>
                        </div>

                        <div className="mt-4">
                          <Button
                            color="danger"
                            className="w-100 fw-600"
                            type="submit"
                            style={{ fontFamily: "Inter" }}
                          >
                            Sign In
                          </Button>
                        </div>

                        <div className="mt-4 text-center">
                          <Button
                            // className="w-50"
                            type="submit"
                            style={{
                              fontFamily: "Google Sans,arial,sans-serif",
                              backgroundColor: "white",
                              color: "black",
                              border: "1px solid #dadce0",
                              // boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                              // borderRadius: "10px",
                              letterSpacing: "0.25px",
                              width: "210px",
                            }}
                            id="msLoginButton"
                          >
                            <img
                              src="/Microsoft_logo.png"
                              alt="microsoft"
                              width="25px"
                              height="25px"
                            />
                            <span style={{ paddingLeft: "5px" }}>
                              {" "}
                              Sign In with Microsoft
                            </span>
                          </Button>
                        </div>
                        <div className="mt-4 text-center border-0">
                          <div
                            id="loginDiv"
                            className="d-flex align-items-center justify-content-center"
                            style={
                              {
                                // boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                                // borderRadius: "10px",
                                // border: "none",
                              }
                            }
                          ></div>
                        </div>
                        {/* <div className=" text-center ">
                          <LoginSocialFacebook
                            appId="690651849500322"
                            onResolve={(response) => {
                              console.log(response);
                              // setProfile(response.data);
                            }}
                            onReject={(error) => {
                              console.log(error);
                            }}
                          >
                            <FacebookLoginButton />
                          </LoginSocialFacebook>
                        </div> */}
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <img
                className="m-auto w-75"
                src={LoginBg}
                alt="Login-Cover"
                // height="100%"
              />
            </Col>
          </Row>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CoverSignIn;
