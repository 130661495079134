import React, { useState, useRef, useEffect } from "react";
import { Grid, Box, Typography, Button } from "@mui/material";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Card, Container, Row } from "reactstrap";
import BreadCrumb from "../../components/Common/BreadCrumb";
import Select from "react-select";
import UsersImg from "../../assets/images/user.png";
import apiAuth from "../../helpers/ApiAuth";
import NotificationManager from "../../components/Common/NotificationManager";
import { Colxx } from "../../components/Common/CustomBootstrap";

const customStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderRadius: "8px",
    fontSize: "14px",
    fontWeight: "500",
  }),
  menu: (baseStyles, state) => ({
    ...baseStyles,
    fontSize: "14px", // Set the font size for the options here
  }),
};

const RadioComp = ({
  data,
  selectedGroup,
  setSelectedGroup,
  groupOptions,
  onChange,
  selRole,
  setFieldValue,
}) => {
  return (
    <Row>
      <Colxx>
        <div className="d-flex align-items-center">
          <input
            name={data?.type}
            type="radio"
            checked={data?.isChecked}
            onChange={() => {
              onChange(data?.id);
              if (data.type === "scope") {
                setSelectedGroup([]);
              }
            }}
            style={{ marginRight: "8px" }}
          />{" "}
          <span
            style={{
              fontSize: "14px",
              fontWeight: 600,
              wordWrap: "break-word ",
              marginRight: "8px",
            }}
            className="text-primary"
          >
            {data?.title}
          </span>
        </div>
        {data?.desc && (
          <span className="fs-12 ml-20 text-muted">{data?.desc}</span>
        )}
        {data.type === "scope" && data.isChecked && data.id !== 3 && (
          <>
            <Colxx lg={6} xs={12} className="fs-12 ml-20 text-primary mt-3">
              <span className="fs-12 ml-15 text-primary mb-2">
                {`Choose ${data?.id === 1 ? "groups" : "group"} that the ${
                  selRole?.label
                } is a part of`}
              </span>
              <Select
                options={groupOptions}
                value={selectedGroup}
                onChange={(val) => {
                  let finalVal;

                  if (Array.isArray(val)) {
                    finalVal = val.map((item) => item.value);
                  } else if (val) {
                    finalVal = [val.value];
                  } else {
                    finalVal = []; // Handle the case when val is null or undefined
                  }

                  setSelectedGroup(val);

                  setFieldValue("group", finalVal.join(","));
                  // setFieldValue("group", val);
                }}
                styles={customStyles}
                isMulti={data?.id === 1}
              />
            </Colxx>
          </>
        )}
      </Colxx>
    </Row>
  );
};

const AddUser = (props) => {
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [uploadLogo, setUploadLogo] = useState("");
  const [userImage, setUserImage] = useState(null);
  const [getCompanies, setGetCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [getGroups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [selRole, setSelRole] = useState({
    label: "User",
    value: "user",
  });
  const [managerOptions, setManagerOptions] = useState([]);
  const [selManager, setSelManager] = useState({});
  const roleOptions = [
    { label: "Agent", value: "agent" },

    { label: "User", value: "user" },
    { label: "Group Admin", value: "group_admin" },
  ];

  const [scopeOptions, setScopeOptions] = useState([
    {
      id: 1,
      title: "Global access",
      desc: "Can access all tickets in Helpdesk",
      isChecked: false,
      type: "scope",
    },
    {
      id: 2,
      title: "Assigned to their Group",
      desc: "Can view and edit tickets in their group(s) and tickets assigned to them",
      isChecked: false,
      type: "scope",
    },
    {
      id: 3,
      title: "Restricted Access",
      desc: "Can Only access the tickets assigned to them",
      isChecked: false,
      type: "scope",
    },
  ]);

  const [channelOptions, setChannelOptions] = useState([
    {
      id: 1,
      title: "Helpdesk",
      isChecked: false,
      type: "support_channels",
    },
    {
      id: 2,
      title: "CRM",
      isChecked: false,
      type: "support_channels",
    },
    {
      id: 3,
      title: "Timesheets",
      isChecked: false,
      type: "support_channels",
    },
  ]);

  const changeStatus = (id, options, setOptions) => {
    const opts = options.map((dd) => {
      const sel = { ...dd };
      if (dd?.id === id) {
        sel["isChecked"] = true;
      } else {
        sel["isChecked"] = false;
      }
      return sel;
    });
    setOptions(opts);
  };

  const getALLCompanies = () => {
    apiAuth
      .get("/api/master/client")
      .then((res) => {
        const data = res?.data;
        const companyOptions = data?.map((item) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });
        const selectCompany = companyOptions.find(
          (cur) => cur.value === props?.selectedUser?.client_id[0]
        );

        setSelectedCompany(selectCompany);
        setGetCompanies(companyOptions);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getALLGroups = () => {
    apiAuth
      .get("/api/master/group")
      .then((res) => {
        const data = res?.data;
        const groupOptions = data?.map((item) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });
        if (props?.data?.group.length) {
          const sel = groupOptions.filter((gp) =>
            props?.data?.group.some((item) => gp.label === item)
          );
          setSelectedGroup(sel);
        }
        setGroups(groupOptions);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getALLReportingManagers = () => {
    apiAuth
      .get("/api/manager/")
      .then((res) => {
        const data = res?.data;
        const managerOpts = data?.map((item) => {
          return {
            label: item?.username,
            value: item?.id,
          };
        });

        setManagerOptions(managerOpts);
        const selectManager = managerOpts.find(
          (cur) => cur.value == props?.selectedUser?.report_manager
        );

        setSelManager(selectManager);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    // console.log("props", props);
    let scope = [...scopeOptions];
    scope = scope.map((dd) => {
      if (props?.data?.group.length > 1 && dd?.id === 1) {
        dd["isChecked"] = true;
      } else if (props?.data?.group.length === 1 && dd?.id === 2) {
        dd["isChecked"] = true;
      } else if (
        props?.data?.role === "agent" &&
        props?.data?.group.length === 0 &&
        dd?.id === 3
      ) {
        dd["isChecked"] = true;
      } else {
        dd["isChecked"] = false;
      }
      return dd;
    });
    // console.log("sssssssss", scope);
    setScopeOptions(scope);
    getALLCompanies();
    getALLGroups();
    getALLReportingManagers();
    if (props?.isEdit) {
      setSelRole({
        label: props?.selectedUser?.role[0] || "user",
        value: props?.selectedUser?.role[0] || "user",
      });

      const updatedChannelOptions = channelOptions.map((option) => {
        if (option.title === props?.selectedUser?.channels) {
          return { ...option, isChecked: true };
        }
        return option;
      });

      setChannelOptions(updatedChannelOptions);
      // const updatedScopeOptions = scopeOptions.map((option) => {
      //   if (option.title === props?.selectedUser?.scope) {
      //     return { ...option, isChecked: true };
      //   }
      //   return option;
      // });

      // setScopeOptions(updatedScopeOptions);
    }
  }, []);

  const handleImageUpload = (event) => {
    const file = event?.target?.files[0];
    setUserImage(file);
    setUploadLogo(URL?.createObjectURL(file));
  };
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <React.Fragment>
      <div className={props?.isEdit ? "" : "page-content"}>
        <div>
          <Grid container>
            <Grid lg={12}>
              <Card
                style={{
                  marginBottom: "0",
                  padding: "20px",
                  fontSize: "20px",
                  fontWeight: 500,
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    fontFamily: "Inter",
                  }}
                >
                  <img
                    src={UsersImg}
                    height="25"
                    width="25"
                    alt=""
                    style={{ marginRight: "10px" }}
                  />
                  <Typography
                    variant="h5"
                    style={{ color: "#093564", fontWeight: "700" }}
                  >
                    User
                  </Typography>
                </Box>
                <Box style={{ margin: "20px 0px ", fontFamily: "Inter" }}>
                  <Typography
                    style={{
                      color: "rgba(0, 0, 0, 0.50)",
                      fontWeight: 500,
                      lineHeight: "24px",
                      wordWrap: "breakWord",
                      fontSize: "18px",
                      fontFamily: "Inter",
                    }}
                  >
                    When someone reaches out to you, they become a contact in
                    your account. You can create companies and associate
                    contacts with them.
                  </Typography>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    marginBottom: "30px",
                  }}
                >
                  <img
                    width="60px"
                    height="60px"
                    key={Math.random()}
                    src={uploadLogo || props?.selectedUser?.image}
                    alt=""
                    style={{
                      border: " 1px solid black",
                      borderRadius: "10%",
                      objectFit: "cover",
                      marginRight: "10px",
                    }}
                  />
                  <input
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={(e) => handleImageUpload(e)}
                  />
                  <Box>
                    <Typography
                      variant="h6"
                      style={{
                        color: "#093564",
                        fontWeight: "700",
                        cursor: "pointer",
                        fontFamily: "Inter",
                      }}
                      onClick={() => handleButtonClick()}
                    >
                      Upload Photo
                    </Typography>

                    <Typography
                      style={{
                        color: "rgba(0, 0, 0, 0.50)",
                        fontWeight: 500,
                        lineHeight: "24px",
                        wordWrap: "breakWord",
                        fontFamily: "Inter",
                      }}
                    >
                      An image of the person, it's best if it has the
                      <br />
                      same length and height
                    </Typography>
                  </Box>
                </Box>
                <Formik
                  initialValues={{
                    company: localStorage.getItem("company"),
                    fullname: props?.selectedUser?.full_name || "",
                    mobile: props?.selectedUser?.mobile || "",
                    email: props?.selectedUser?.email || "",
                    role: props?.selectedUser?.role[0] || "user",
                    job_title: props?.selectedUser?.job_title || "",
                    client_id:
                      (props?.selectedUser?.client &&
                        props?.selectedUser?.client[0]) ||
                      "",
                  }}
                  validationSchema={Yup.object({
                    fullname: Yup.string().ensure().required("Required!"),
                    email: Yup.string()
                      .email("Invalid email address") // Use the email method for email validation
                      .required("Email is required"),
                    role: Yup.string().ensure().required("Required!"),
                    job_title: Yup.string().ensure().required("Required!"),
                    mobile: Yup.string()
                      .matches(
                        /^[0-9]{10}$/,
                        "Mobile number must be exactly 10 digits"
                      )
                      .required("Mobile number is required"),
                  })}
                  onSubmit={(values) => {
                    console.log("values", values);
                    const formData = new FormData();

                    // Iterate through the values object and append each field to FormData
                    const keys = Object.keys(values);
                    for (let i = 0; i < keys.length; i++) {
                      const key = keys[i];
                      formData.append(key, values[key]);
                    }
                    // Append the image file
                    if (userImage) {
                      formData.append("image", userImage);
                    }

                    if (values.scope === "Global access") {
                      // Check if "group" field is not present in formData
                      if (!formData.has("group")) {
                        const allGroups = getGroups.map((opt) => opt.value);
                        formData.append("group", allGroups.join(","));
                      }
                    }

                    values.scope === "Restricted Access" &&
                      formData.delete("group");

                    props?.isEdit
                      ? apiAuth
                          .patch(`/api/register/${props?.data?.id}/`, formData)
                          .then((res) => {
                            let data = res.data;
                            NotificationManager.success(
                              "User Updation",
                              "User Updated Successfully",
                              3000,
                              null,
                              null,
                              ""
                            );
                            props?.closeAddPopup();
                          })
                          .catch((err) => {
                            NotificationManager.error(
                              "User Updation",
                              "User Updation Error",
                              3000,
                              null,
                              null,
                              ""
                            );
                          })
                      : apiAuth
                          .post("/api/register/", formData)
                          .then((res) => {
                            const { data } = res;
                            setLoading(false);
                            NotificationManager.success(
                              "User",
                              "User Created Successfully",
                              3000,
                              null,
                              null,
                              ""
                            );

                            props?.history.goBack();
                          })
                          .catch((err) => {
                            setLoading(false);
                            NotificationManager.error(
                              "User",
                              "User Create Error",
                              3000,
                              null,
                              null,
                              ""
                            );
                          });
                  }}
                >
                  {({ values, errors, touched, setFieldValue }) => (
                    <Form className="av-tooltip tooltip-label-bottom">
                      <Grid>
                        <Grid container spacing={2}>
                          <Grid item lg={6} xs={12} className=" mt-3 mb-3">
                            <div className="mb-3">
                              <label
                                htmlFor="fullname"
                                className="form-label text-primary"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  wordWrap: "break-word ",
                                }}
                              >
                                Full Name
                                <span className="text-danger">*</span>
                              </label>
                              <Field
                                className="form-control"
                                name="fullname"
                                placeholder="Enter the name of this person"
                                style={{
                                  borderRadius: "10px",
                                }}
                              />
                              <ErrorMessage
                                name="fullname"
                                render={(msg) => (
                                  <div className="text-danger">{msg}</div>
                                )}
                              />
                            </div>
                          </Grid>

                          <Grid item lg={6} xs={12} className=" mt-3 mb-3">
                            <div className="mb-3">
                              <label
                                htmlFor="email"
                                className="form-label text-primary"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  wordWrap: "break-word ",
                                }}
                              >
                                Email
                                <span className="text-danger">*</span>
                              </label>
                              <Field
                                className="form-control"
                                name="email"
                                placeholder="Enter the email of this person"
                                style={{
                                  borderRadius: "10px",
                                }}
                              />
                              <ErrorMessage
                                name="email"
                                render={(msg) => (
                                  <div className="text-danger">{msg}</div>
                                )}
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item lg={6} xs={12} className=" mb-3">
                            <div className="mb-3">
                              <label
                                htmlFor="mobile"
                                className="form-label text-primary"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  wordWrap: "break-word ",
                                }}
                              >
                                Mobile
                              </label>
                              <Field
                                className="form-control"
                                name="mobile"
                                placeholder="Enter the mobile of this person"
                                style={{
                                  borderRadius: "10px",
                                }}
                              />
                              <ErrorMessage
                                name="mobile"
                                render={(msg) => (
                                  <div className="text-danger">{msg}</div>
                                )}
                              />
                            </div>
                          </Grid>
                          <Grid item lg={6} xs={12} className="mb-3">
                            <label
                              htmlFor="role"
                              className="form-label"
                              style={{
                                color: "#093564",
                                fontSize: "16px",
                                fontWeight: 600,
                                wordWrap: "break-word ",
                              }}
                            >
                              Role
                            </label>
                            <Select
                              options={roleOptions}
                              value={selRole}
                              onChange={(data) => {
                                setFieldValue("role", data?.value);
                                setSelRole(data);
                              }}
                              styles={customStyles}
                            />
                            <ErrorMessage
                              name="role"
                              render={(msg) => (
                                <div className="text-danger">{msg}</div>
                              )}
                            />
                          </Grid>
                        </Grid>

                        {/* job title */}
                        <Grid item lg={6} xs={12} className="mb-3">
                          <label
                            htmlFor="job_title"
                            className="form-label"
                            style={{
                              color: "#093564",
                              fontSize: "16px",
                              fontWeight: 600,
                              wordWrap: "break-word ",
                            }}
                          >
                            Job Title
                            <span className="text-danger">*</span>
                          </label>
                          <Field
                            className="form-control"
                            name="job_title"
                            placeholder="Job Title"
                            style={{
                              borderRadius: "10px",
                            }}
                          />
                          <ErrorMessage
                            name="job_title"
                            render={(msg) => (
                              <div className="text-danger">{msg}</div>
                            )}
                          />
                        </Grid>

                        <hr
                          style={{
                            color: "#000000",
                          }}
                          className="separator mt-5 mb-4"
                        />

                        {selRole?.value === "user" ? (
                          <>
                            <Grid container spacing={2}>
                              <Grid item lg={6} xs={12} className="mb-3">
                                <label
                                  htmlFor="client_id"
                                  className="form-label"
                                  style={{
                                    color: "#093564",
                                    fontSize: "16px",
                                    fontWeight: 600,
                                    wordWrap: "break-word ",
                                  }}
                                >
                                  Client
                                </label>
                                <Select
                                  styles={customStyles}
                                  options={getCompanies}
                                  value={selectedCompany}
                                  onChange={(data) => {
                                    setFieldValue("client_id", data?.value);
                                    setSelectedCompany(data);
                                  }}
                                />
                                <ErrorMessage
                                  name="client_id"
                                  render={(msg) => (
                                    <div className="text-danger">{msg}</div>
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid item lg={6} xs={12} className="mb-3">
                              <label
                                htmlFor="reporting_manager_id"
                                className="form-label"
                                style={{
                                  color: "#093564",
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  wordWrap: "break-word ",
                                }}
                              >
                                Reporting Manager
                              </label>
                              <Select
                                styles={customStyles}
                                options={managerOptions}
                                value={selManager}
                                onChange={(data) => {
                                  setFieldValue(
                                    "reporting_manager_id",
                                    data?.value
                                  );
                                  setSelectedCompany(data);
                                }}
                              />
                              <ErrorMessage
                                name="reporting_manager_id"
                                render={(msg) => (
                                  <div className="text-danger">{msg}</div>
                                )}
                              />
                            </Grid>
                            {/* scope */}
                            <div className="d-flex gap-2">
                              <Colxx>
                                <label
                                  htmlFor="scope"
                                  className="form-label"
                                  style={{
                                    color: "#093564",
                                    fontSize: "16px",
                                    fontWeight: 600,
                                    wordWrap: "break-word ",
                                  }}
                                >
                                  Scope
                                </label>
                                {scopeOptions.map((dd) => (
                                  <div key={dd?.id} className="mb-3">
                                    {/* {console.log("ddddd", dd)} */}
                                    <RadioComp
                                      data={dd}
                                      selectedGroup={selectedGroup}
                                      setSelectedGroup={setSelectedGroup}
                                      groupOptions={getGroups}
                                      onChange={(id) => {
                                        changeStatus(
                                          id,
                                          scopeOptions,
                                          setScopeOptions
                                        );
                                        setFieldValue("scope", dd.title);
                                      }}
                                      selRole={selRole}
                                      setFieldValue={setFieldValue}
                                    />
                                  </div>
                                ))}
                              </Colxx>
                            </div>
                            {/* support channels */}
                            <div className="d-flex gap-2 mt-3 mb-3">
                              <Colxx>
                                <label
                                  htmlFor="channels"
                                  className="form-label"
                                  style={{
                                    color: "#093564",
                                    fontSize: "16px",
                                    fontWeight: 600,
                                    wordWrap: "break-word ",
                                  }}
                                >
                                  Channels
                                </label>
                                {channelOptions.map((dd) => (
                                  <div key={dd?.id}>
                                    <RadioComp
                                      data={dd}
                                      onChange={(id) => {
                                        changeStatus(
                                          id,
                                          channelOptions,
                                          setChannelOptions
                                        );
                                        setFieldValue("channels", dd?.title);
                                      }}
                                    />
                                  </div>
                                ))}
                              </Colxx>
                            </div>{" "}
                          </>
                        )}
                      </Grid>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                          fontSize: "16px",
                        }}
                      >
                        {loading ? (
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <div className="mt-4 mb-3">
                            <Button
                              className="btn"
                              type="submit"
                              style={{
                                color: "white",
                                fontWeight: 500,
                                background: "#093564",
                                borderRadius: "20px",
                                padding: "8px 40px",
                              }}
                            >
                              {props?.isEdit ? "Update" : "Create"}
                            </Button>
                          </div>
                        )}
                        <div className="mt-4 mb-3 ms-3">
                          <Button
                            className="btn"
                            onClick={() => {
                              props?.isEdit
                                ? props?.closeAddPopup()
                                : props?.history.goBack();
                            }}
                            style={{
                              color: "#093564",
                              fontWeight: 500,
                              padding: "8px 0px",
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddUser;
