import * as XLSX from "xlsx";

/**
 * Adjusts the column widths in an Excel worksheet.
 *
 * @param {Object} ws - The Excel worksheet.
 * @param {number} multiplier - A multiplier to adjust the width.
 */

const adjustWidthInExcel = (ws, multiplier) => {
  const columnWidths = [];

  // Iterate through data to find the maximum width needed for each column
  XLSX.utils.sheet_to_json(ws, { header: 1 }).forEach((row, rowIndex) => {
    row.forEach((cellValue, colIndex) => {
      const cellWidth = (cellValue.toString().length + 2) * multiplier; // Adjust the multiplier as needed
      if (!columnWidths[colIndex] || cellWidth > columnWidths[colIndex]) {
        columnWidths[colIndex] = cellWidth;
      }
    });
  });

  // Set the column widths in the worksheet
  ws["!cols"] = columnWidths.map((width) => ({ width }));
};

export default adjustWidthInExcel;
