import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, Card, Col, Container, Row } from "reactstrap";
import API from "../../../helpers/API";
import NotificationManager from "../../../components/Common/NotificationManager";
import AuthSlider from "../authCarousel";
import PublicHeader from "../../../Layouts/PublicHeader";
import Footer from "../../../Layouts/Footer";
import bgImage from "../../../assets/images/login-bg.png"

const CoverPasswReset = () => {
  const history = useHistory();

  const [openFields, setOpenFields] = useState(false);
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newOtp, setNewOpt] = useState("");

  const getOpt = () => {
    const sendEmail = {
      email: email,
    };
    API.post("/api/forgot/password/", sendEmail)
      .then((response) => {
        const data = response.data;
        setOpenFields(true);
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status === 400) {
          NotificationManager.error(
            "Forgot Password",
            err.response.data.Error,
            3000,
            null,
            null,
            ""
          );
        }
      });
  };

  const verifyOtp = async () => {
    const passwordObj = {
      email: email,
      new_password: newPassword,
      confirm_password: confirmPassword,
      otp: newOtp,
    };
    await API.post("/api/forgot/password/verify/", passwordObj)
      .then((response) => {
        const data = response.data;
        NotificationManager.success(
          "",
          "Password updated successfully.",
          3000,
          null,
          null,
          ""
        );
        history.push("/login");
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 400) {
          NotificationManager.error(
            "",
            "Bad Credentials",
            3000,
            null,
            null,
            ""
          );
        }
        if (error.response.status === 500) {
          NotificationManager.error(
            "",
            "Request failed with status 500",
            3000,
            null,
            null,
            ""
          );
        }
      });

    setOpenFields(false);
    setNewOpt("");
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          overflow: "hidden",
        }}
      >
        <PublicHeader />
        <div className="bg-white" style={{ flex: "1" }}>
          {/* <Card className=""> */}
            <Row
            className="mt-2"
              // className="g-0 h-100"
              style={{placeItems: "center"}}
            >
              {/* <AuthSlider /> */}

              <Col lg={6}>
              <div className="text-center">
                <img className="m-auto w-75" src={bgImage} alt=""/>
                </div>
              </Col>

              <Col lg={6} style={{ margin: "auto" }}>
                <div className="p-lg-5 p-4">
                  <h2 className="text-dark mb-3">Enter your email address</h2>

                  <div className="mt-2 text-center">
                    <lord-icon
                      src="https://cdn.lordicon.com/rhvddzym.json"
                      trigger="loop"
                      colors="primary:#0ab39c"
                      className="avatar-xl"
                      style={{ width: "120px", height: "120px" }}
                    ></lord-icon>
                  </div>
                  <div className="p-2">
                    <form>
                      <div className="">
                        <label className="form-label">Email Address</label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          required
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        {openFields && (
                          <div className="d-flex justify-content-end align-items-center mt-3">
                            <button
                              className="btn fw-600"
                              onClick={(e) => {
                                getOpt();
                              }}
                              style={{
                                background: "#EC1C24",
                                border: "#EC1C24",
                                color: "#fff",
                              }}
                            >
                              Resend OTP
                            </button>
                          </div>
                        )}
                      </div>

                      {openFields && (
                        <>
                          <div className="mb-4">
                            <label className="form-label">OTP</label>
                            <input
                              type="text"
                              className="form-control"
                              id="otp"
                              placeholder="Enter OTP"
                              required
                              onChange={(e) => setNewOpt(e.target.value)}
                            />
                          </div>
                          <div className="mb-4">
                            <label className="form-label">
                              Change Password
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="change-password"
                              placeholder="Enter New Password"
                              required
                              onChange={(e) => setNewPassword(e.target.value)}
                            />
                          </div>
                          <div className="mb-4">
                            <label className="form-label">
                              Confirm New Password
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="confirm_new_password"
                              placeholder=""
                              required
                              onChange={(e) => {
                                setConfirmPassword(e.target.value);
                              }}
                            />
                          </div>
                        </>
                      )}

                      <div className="text-center mt-4">
                        <Button
                          // color="success"
                          className="w-100 fw-600"
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            if (openFields) verifyOtp();
                            else getOpt();
                          }}
                          style={{
                            background: "#EC1C24",
                            border: "#EC1C24",
                          }}
                        >
                          {openFields === true ? "Submit" : "Send OTP"}
                        </Button>
                      </div>
                    </form>
                  </div>

                  <div className="mt-5 text-left">
                    <p className="mb-0">
                      Remember the password?
                      <Link
                        to="/auth-signin-cover"
                        className="fw-bold text-dark text-decoration-underline"
                      >
                        {" "}
                        Sign in{" "}
                      </Link>{" "}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          {/* </Card> */}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CoverPasswReset;
