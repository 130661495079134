import { Button, Grid } from "@mui/material";
import * as Yup from "yup";
import Select from "react-select";
import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import { priorityOptions, statusOptions, typeOptions } from "./options";
import apiAuth from "../../helpers/ApiAuth";
import NotificationManager from "../../components/Common/NotificationManager";

const CustomUpdate = (props) => {
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedPriority, setSelectedPriority] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [groupOptions, setGroupOptions] = useState([]);

  useEffect(() => {
    if (props?.curItem === "Group" || props?.curItem === "All") {
      getALLGroups();
    }

    switch (props?.curItem) {
      case "Assign":
        setSelectedAgent(props?.selObj);
        break;
      case "Close":
        setSelectedStatus(props?.selObj);
        break;
      case "Priority":
        setSelectedPriority(props?.selObj);
        break;
      case "Type":
        setSelectedType(props?.selObj);
        break;
      default:
        break;
    }
  }, [props]);

  const getALLGroups = () => {
    apiAuth
      .get("/api/master/group")
      .then((res) => {
        const data = res?.data;
        const groupOptions = data?.map((item) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });
        setGroupOptions(groupOptions);
        const group = groupOptions?.find(
          (item) => item?.value === props?.selObj?.value
        );

        setSelectedGroup(group);
      })
      .catch((err) => {
        NotificationManager.error(
          "Groups",
          "Groups Get Error",
          3000,
          null,
          null,
          ""
        );
      });
  };

  return (
    <div>
      <Grid container>
        <Grid lg={12}>
          <div className="p-3 bg-white">
            {/* {console.log("props", props)} */}
            <Formik
              initialValues={{
                assigned_agent:
                  props?.curItem === "Assign" ? props?.selObj?.value : "",
                group: props?.curItem === "Group" ? props?.selObj?.value : "",
                type: props?.curItem === "Type" ? props?.selObj?.value : "",
                status: props?.curItem === "Close" ? props?.selObj?.value : "",
                priority:
                  props?.curItem === "Priority" ? props?.selObj?.value : "",
                remarks: props?.curItem === "Delete" ? "remarks" : "",
              }}
              validationSchema={Yup.object({
                // assigned_agent: Yup.string().ensure().required("Required!"),
                remarks: Yup.string().trim().required("Required!"),
              })}
              onSubmit={(values) => {
                setLoading(true);
                // console.log("valuesssss", values);
                const totalItems = props?.checkedItems.length;
                let updatedCount = 0;
                props?.checkedItems.forEach((dd) => {
                  if (props.curItem === "Delete") {
                    let url = `/api/master/ticket/${dd?.id}/`;
                    apiAuth
                      .delete(url)
                      .then((response) => {
                        updatedCount++;
                        if (updatedCount === totalItems) {
                          NotificationManager.success(
                            "Ticket",
                            "Tickets Deleted Successfully",
                            3000,
                            null,
                            null,
                            ""
                          );
                        }
                        setLoading(false);
                        props.closeAddPopup();
                      })
                      .catch(function (error) {
                        console.log(error);
                        console.log(error.response?.data);
                        console.log(error.response?.status);
                        console.log(error.response?.headers);
                        setLoading(false);
                      });
                  } else {
                    const nonEmptyValues = Object.keys(values).reduce(
                      (acc, key) => {
                        if (values[key] !== "") {
                          acc[key] = values[key];
                        }
                        return acc;
                      },
                      {}
                    );
                    if (Object.keys(nonEmptyValues).length > 0) {
                      apiAuth
                        .patch(`/api/master/ticket/${dd?.id}/`, nonEmptyValues)
                        .then((res) => {
                          updatedCount++;
                          if (updatedCount === totalItems) {
                            NotificationManager.success(
                              "Ticket",
                              "Tickets Updated Successfully",
                              3000,
                              null,
                              null,
                              ""
                            );
                            setLoading(false);
                            props.closeAddPopup();
                          }
                        })
                        .catch((err) => {
                          updatedCount++;
                          if (updatedCount === totalItems) {
                            NotificationManager.error(
                              "Ticket",
                              "Tickets Update Error",
                              3000,
                              null,
                              null,
                              ""
                            );
                          }
                          console.log(err);
                          setLoading(false);
                          props.closeAddPopup();
                        });
                    }
                  }
                });
              }}
            >
              {({ values, errors, touched, setFieldValue }) => (
                <Form className="av-tooltip tooltip-label-bottom">
                  {/* Agent */}
                  {props?.curItem === "All" || props?.curItem === "Assign" ? (
                    <Grid item lg={12} xs={12} className="mb-3">
                      <div className="mb-3">
                        <label
                          htmlFor="assigned_agent"
                          className="form-label text-primary"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            wordWrap: "break-word ",
                          }}
                        >
                          Agent
                        </label>
                        <Select
                          options={props?.agentOptions.filter((dd) =>
                            dd?.groups.includes(props?.checkedItems[0]?.group)
                          )}
                          value={selectedAgent}
                          onChange={(data) => {
                            setFieldValue("assigned_agent", data?.value);
                            setSelectedAgent(data);
                          }}
                          placeholder=""
                        />
                        <ErrorMessage
                          name="assigned_agent"
                          render={(msg) => (
                            <div className="text-danger">{msg}</div>
                          )}
                        />
                      </div>
                    </Grid>
                  ) : (
                    <></>
                  )}

                  {/* Status */}
                  {props?.curItem === "All" || props?.curItem === "Close" ? (
                    <Grid item lg={12} xs={12} className="mb-3">
                      <div className="mb-3">
                        <label
                          htmlFor="status"
                          className="form-label text-primary"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            wordWrap: "break-word ",
                          }}
                        >
                          Status
                        </label>
                        <Select
                          options={statusOptions}
                          value={selectedStatus}
                          onChange={(data) => {
                            setFieldValue("status", data?.value);
                            setSelectedStatus(data);
                          }}
                          placeholder=""
                        />
                        <ErrorMessage
                          name="status"
                          render={(msg) => (
                            <div className="text-danger">{msg}</div>
                          )}
                        />
                      </div>
                    </Grid>
                  ) : (
                    <></>
                  )}

                  {/* Priority */}
                  {props?.curItem === "All" || props?.curItem === "Priority" ? (
                    <Grid item lg={12} xs={12} className="mb-3">
                      <div className="mb-3">
                        <label
                          htmlFor="priority"
                          className="form-label text-primary"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            wordWrap: "break-word ",
                          }}
                        >
                          Priority
                        </label>
                        <Select
                          options={priorityOptions}
                          value={selectedPriority}
                          onChange={(data) => {
                            setFieldValue("priority", data?.value);
                            setSelectedPriority(data);
                          }}
                          placeholder=""
                        />
                        <ErrorMessage
                          name="priority"
                          render={(msg) => (
                            <div className="text-danger">{msg}</div>
                          )}
                        />
                      </div>
                    </Grid>
                  ) : (
                    <></>
                  )}

                  {/* Group */}
                  {props?.curItem === "All" || props?.curItem === "Group" ? (
                    <>
                      <Grid item lg={12} xs={12} className="mb-3">
                        <div className="mb-3">
                          <label
                            htmlFor="group"
                            className="form-label text-primary"
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              wordWrap: "break-word ",
                            }}
                          >
                            Group
                          </label>
                          <Select
                            options={groupOptions}
                            value={selectedGroup}
                            onChange={(data) => {
                              setFieldValue("group", data?.value);
                              setSelectedGroup(data);
                            }}
                            placeholder=""
                          />
                          <ErrorMessage
                            name="group"
                            render={(msg) => (
                              <div className="text-danger">{msg}</div>
                            )}
                          />
                        </div>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}

                  {/* Type */}
                  {props?.curItem === "All" || props?.curItem === "Type" ? (
                    <>
                      <Grid item lg={12} xs={12} className="mb-3">
                        <div className="mb-3">
                          <label
                            htmlFor="type"
                            className="form-label text-primary"
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              wordWrap: "break-word ",
                            }}
                          >
                            Type
                          </label>
                          <Select
                            options={typeOptions}
                            value={selectedType}
                            onChange={(data) => {
                              setFieldValue("type", data?.value);
                              setSelectedType(data);
                            }}
                            placeholder=""
                          />
                          <ErrorMessage
                            name="type"
                            render={(msg) => (
                              <div className="text-danger">{msg}</div>
                            )}
                          />
                        </div>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}

                  {/* Remarks */}
                  {props?.curItem !== "Delete" ? (
                    <Grid item lg={12} xs={12} className="mb-3">
                      <label
                        htmlFor="remarks"
                        className="form-label text-primary"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          wordWrap: "break-word ",
                        }}
                      >
                        Remarks
                        <span className="text-danger">*</span>
                      </label>
                      <Field
                        className="form-control"
                        name="remarks"
                        as="textarea"
                        placeholder="Write remarks here!"
                        style={{
                          borderRadius: "10px",
                        }}
                      />
                      <ErrorMessage
                        name="remarks"
                        render={(msg) => (
                          <div className="text-danger">{msg}</div>
                        )}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}

                  {/* Delete Message */}
                  {props?.curItem === "Delete" ? (
                    <Grid item lg={12} xs={12} className="mb-3">
                      <label
                        htmlFor="remarks"
                        className="form-label text-primary"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          wordWrap: "break-word ",
                        }}
                      >
                        Are you sure you want to Delete?
                      </label>
                    </Grid>
                  ) : (
                    <></>
                  )}

                  {/* Submit & Cancel Buttons */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      fontSize: "16px",
                    }}
                  >
                    <div className="mt-4 mb-3">
                      {loading ? (
                        <div className="spinner-border text-dark" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <Button
                          className="btn "
                          type="submit"
                          style={{
                            color: "white",
                            fontWeight: 500,
                            background: "#093564",
                            borderRadius: "20px",
                            padding: "8px 40px",
                          }}
                        >
                          {props?.curItem === "Delete" ? "Yes" : "Update"}
                        </Button>
                      )}
                    </div>

                    <div className="mt-4 mb-3 ms-3">
                      <Button
                        className="btn text-primary"
                        // type="submit"
                        style={{
                          fontWeight: 500,
                          padding: "8px 0px",
                        }}
                        onClick={() => {
                          props?.closeAddPopup("cancel");
                        }}
                      >
                        {props?.curItem === "Delete" ? "No" : "Cancel"}
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default CustomUpdate;
