import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Grid, Button, Box, Typography } from "@mui/material";
import Select from "react-select";
import { Card, Container, Row } from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import apiAuth from "../../helpers/ApiAuth";
import { Colxx } from "../../components/Common/CustomBootstrap";
import BreadCrumb from "../../components/Common/BreadCrumb";
import NotificationManager from "../../components/Common/NotificationManager";
import GroupImg from "../../assets/images/groups.png";

const AddProduct = (props) => {
  const [loading, setLoading] = useState(false);

  return (
    <React.Fragment>
      <div className={`${props?.isEdit ? "" : "page-content"}`}>
        <div>
          <Grid container>
            <Grid xs={12}>
              <Card className="p-3">
                {props?.isEdit ? (
                  ""
                ) : (
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      marginBottom: "2%",
                    }}
                  >
                    <img
                      src={GroupImg}
                      alt="Agent"
                      height="30"
                      width="30"
                      style={{
                        objectFit: "contain",
                      }}
                    />
                    <Typography
                      variant="h5"
                      className="text-primary fw-bold"
                      style={{ fontFamily: "Inter" }}
                    >
                      New Product
                    </Typography>
                  </Box>
                )}

                <Formik
                  initialValues={{
                    name: props?.data?.name || "",
                    Description: props?.data?.Description || "",
                  }}
                  validationSchema={Yup.object({
                    name: Yup.string().ensure().required("Required!"),
                    Description: Yup.string().ensure().required("Required!"),
                  })}
                  onSubmit={(values) => {
                    setLoading(true);

                    props?.isEdit
                      ? apiAuth
                          .patch(
                            `/api/master/project/${props?.data?.id}/`,
                            values
                          )
                          .then((res) => {
                            let data = res.data;
                            NotificationManager.success(
                              "Project Updation",
                              "Project Updated Successfully",
                              3000,
                              null,
                              null,
                              ""
                            );
                            props?.closePopUp(data);
                          })
                          .catch((err) => {
                            NotificationManager.error(
                              "Project Updation",
                              "Project Updation Error",
                              3000,
                              null,
                              null,
                              ""
                            );
                            props?.closePopUp();
                          })
                      : apiAuth
                          .post("/api/master/project/", values)
                          .then((res) => {
                            NotificationManager.success(
                              "Product Creation",
                              "Product Created Successfully",
                              3000,
                              null,
                              null,
                              ""
                            );
                            props?.history.goBack();
                          })
                          .catch((err) => {
                            NotificationManager.error(
                              "Product Creation",
                              "Product Create Error",
                              3000,
                              null,
                              null,
                              ""
                            );
                            props?.history.goBack();
                          });
                  }}
                >
                  {({ values, errors, touched, setFieldValue }) => (
                    <Form className="av-tooltip tooltip-label-bottom">
                      <Grid item lg={12} xs={12} className="mb-3">
                        <label
                          htmlFor="name"
                          className="form-label text-primary fs-16 fw-600 custom-word-wrap"
                        >
                          Product Name
                        </label>
                        <Field
                          className="form-control"
                          name="name"
                          type="text"
                          placeholder="e.g. Refund Group"
                          style={{
                            borderRadius: "10px",
                          }}
                        />
                        <ErrorMessage
                          name="name"
                          render={(msg) => (
                            <div className="text-danger">{msg}</div>
                          )}
                        />
                      </Grid>
                      <Grid item lg={12} xs={12} className="mb-3">
                        <label
                          htmlFor="Description"
                          className="form-label text-primary fs-16 fw-600 custom-word-wrap"
                        >
                          Description
                        </label>
                        <Field
                          as="textarea"
                          className="form-control"
                          name="Description"
                          placeholder="e.g. This group will answer all your queries related to refunds"
                          style={{
                            borderRadius: "10px",
                          }}
                        />
                        <ErrorMessage
                          name="Description"
                          render={(msg) => (
                            <div className="text-danger">{msg}</div>
                          )}
                        />
                      </Grid>

                      <div className="d-flex justify-content-start align-items-center fs-16">
                        {loading ? (
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <div className="mt-4 mb-3">
                            <Button
                              className="btn bg-primary text-white"
                              type="submit"
                              style={{
                                fontWeight: 500,
                                borderRadius: "20px",
                                padding: "8px 40px",
                              }}
                            >
                              {props?.isEdit ? "Update" : "Create Product"}
                            </Button>
                          </div>
                        )}
                        <div className="mt-4 mb-3 ms-3">
                          <Button
                            className="btn text-primary fw-500"
                            // type="submit"
                            onClick={() => {
                              props?.isEdit
                                ? props?.closePopUp()
                                : props?.history.goBack();
                            }}
                            style={{
                              padding: "8px 0px",
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddProduct;
