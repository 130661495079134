import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useHistory } from "react-router-dom";

const customSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    border: "none",
    boxShadow: "none",
    background: "transparent",
    color: "#093564",
    fontSize: "14px"
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#093564" : "white",
    color: state.isSelected ? "#fff" : "#093564",
    "&:hover": {
      backgroundColor: "rgba(9, 53, 100, 0.7)",
      color: "white",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#093564",
    fontWeight: 500,
    fontSize: "14px",
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#093564",
    marginRight: "4px",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#093564",
  }),
};

export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: "none",
    boxShadow: "none",
    borderRadius: "0",
    background: "none",
    color: "#093564",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#093564",
    fontWeight: 600,
    fontSize: "18px",
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#093564" : "white",
    color: state.isSelected ? "#fff" : "#093564",
    "&:hover": {
      backgroundColor: "rgba(9, 53, 100, 0.7)",
      color: "white",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#093564",
    fontWeight: 600,
    fontSize: "18px",
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#093564",
    marginRight: "4px",
  }),
};

export const SelectComp = ({
  width = "150px",
  options,
  val = null,
  onChange,
  setSelObj,
}) => {
  const [selState, setSelState] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (val) {
      const sel = options.find((dd) => dd.value === val);
      setSelState(sel);
    }
  }, [val, options]);

  return (
    <>
      <div
      className="fs-14"
        style={{
          width: width,
        }}
      >
        <Select
          value={selState}
          onChange={(val) => {
            setSelObj(val);
            onChange();
          }}
          options={options}
          styles={customSelectStyles}
        />
      </div>
    </>
  );
};

export const PriorityLabel = ({ label, bg }) => {
  return (
    <div className="d-flex  align-items-center">
      <div
        style={{
          width: "15px",
          height: "15px",
          background: bg,
          borderRadius: "1px",
          marginRight: "5px",
        }}
      ></div>
      <span>{label}</span>
    </div>
  );
};

export const ContactComp = ({ name }) => {
  const firstLetter = name?.charAt(0).toUpperCase();

  const generateRandomColor = () => {
    const colors = [
      "#c72135",
      "#e16033",
      "#dba355",
      "#324b7b",
    ];

    const randomIndex = firstLetter.charCodeAt(0) % colors.length;
    return colors[randomIndex];
  };

  const backgroundColor = generateRandomColor();

  return (
    <div className="d-flex align-items-center justify-content-start">
      <p
        className="px-2 py-1 m-0"
        style={{
          padding: "10px",
          background: backgroundColor,
          borderRadius: "5px",
          marginRight: "7px",
          fontWeight: 600,
          fontSize: "14px",
          width: "26px",
          height: "26px",
          textAlign: "center",
          margin: "auto",
          color: "#fff"
        }}
      >
        {firstLetter}
      </p>
      <p className="w-100 text-left ms-2 m-0 fs-14">
        {name?.charAt(0).toUpperCase() + name?.slice(1).toLowerCase()}
      </p>
    </div>
  );
};

export const HeaderSelectComp = ({
  label,
  options,
  getTickets,
  checkedIds,
  curSelector,
}) => {
  return (
    <div
      style={{
        zIndex: 100,
        width: "100%",
        position: "absolute",
        height: "65px",
        top: "1px",
        right: "40px",
      }}
      className="d-flex flex-column align-items-center w-100"
    >
      <div>
        <span className="font-weight-bold fs-13 text-white">{label}</span>
      </div>
      <div style={{ position: "absolute", bottom: 3, right: 0, zIndex: 200 }}>
        <SelectComp
          width={"150px"}
          options={options}
          curSelector={curSelector}
          getTickets={getTickets}
          checkedIds={checkedIds}
        />
      </div>
    </div>
  );
};

export const UpdateButton = ({ onClick, icon, label }) => {
  return (
    <>
      <div
        style={{
          border: "0.5px solid #E6E6E6",
          borderRadius: "5px",
        }}
        className="btn btn-light text-primary"
        onClick={onClick}
      >
        {icon}
        <span
          style={{
            fontSize: "14px",
            fontWeight: 600,
          }}
        >
          {label}
        </span>
      </div>
    </>
  );
};
