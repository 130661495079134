import React, { useState, useEffect, useRef } from "react";
import { Row, Card } from "reactstrap";
import { Colxx } from "../../components/Common/CustomBootstrap";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Grid, Button } from "@mui/material";
import apiAuth from "../../helpers/ApiAuth";
import PublicHeader from "../../Layouts/PublicHeader";
import NotificationManager from "../../components/Common/NotificationManager";
import { useHistory } from "react-router-dom";
import profile from "../../assets/images/users/profile.png";
import ChangePassword from "./ChangePassword";
import Footer from "../../Layouts/Footer";

const Profile = () => {
  const [profileData, setProfileData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loginMember, setLoginMember] = useState("Edit_Profile");
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );
  const history = useHistory();
  const [uploadLogo, setUploadLogo] = useState("");
  const [userImage, setUserImage] = useState(null);
  const fileInputRef = useRef(null);

  const handleRemove = () => {
    setUploadLogo(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null; // Clear the file input
    }
  };

  const handleImageUpload = (event) => {
    const file = event?.target?.files[0];
    setUserImage(file);
    setUploadLogo(URL?.createObjectURL(file));
  };
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleLoginMember = (button) => {
    setLoginMember(button);
  };

  const getProfileData = () => {
    apiAuth
      .get(`/api/register/${userData?.id}`)
      .then((response) => {
        const data = response?.data;
        const updatedData = {
          Response: userData?.Response,
          token: userData?.token,
          id: userData?.id,
          email: userData?.email,
          mobile: data?.mobile,
          fullname: data?.full_name,
          role: userData?.role,
          groups: userData?.groups,
          client: userData?.client,
          is_manager: userData?.is_manager,
          company: userData?.company,
          job_title: data?.job_title,
          image: data?.image,
        };
        localStorage.setItem("authUser", JSON.stringify(updatedData));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const isAuthenticated = () => {
    const authuser = localStorage.getItem("authUser");
    return !!authuser;
  };
  useEffect(() => {
    // getProfileData();
    if (!isAuthenticated()) {
      history.push("/login");
    } else {
      setUploadLogo(userData?.image);
    }
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          overflow: "hidden",
        }}
      >
        <PublicHeader ticketPage />
        <div style={{ flex: "1" }}>
          <Row className="mt-1">
            <Colxx lg="12" className="p-0">
              <div
                className="ps-5 py-4"
                style={{
                  backgroundColor: "#EC1C24",
                }}
              >
                <h1 className="text-white m-0">My Profile</h1>
              </div>
            </Colxx>
          </Row>

          <Row className="m-4">
            <Colxx lg={12} xs={12}>
              <div className="d-flex justify-content-start align-items-center">
                <Button
                  onClick={() => handleLoginMember("Edit_Profile")}
                  style={{
                    background:
                      loginMember === "Edit_Profile" ? "#093564" : "#00000033",
                    color:
                      loginMember === "Edit_Profile"
                        ? "#fff"
                        : "rgba(0, 0, 0, 0.50)",
                    border: "none",
                    marginRight: "10px",
                    textTransform: "none",
                  }}
                >
                  Edit profile
                </Button>
                <Button
                  onClick={() => handleLoginMember("Change_Password")}
                  style={{
                    background:
                      loginMember === "Change_Password"
                        ? "#093564"
                        : "#00000033",
                    color:
                      loginMember === "Change_Password"
                        ? "#fff"
                        : "rgba(0, 0, 0, 0.50)",
                    border: "none",
                    fontFamily: "Inter",
                    textTransform: "none",
                  }}
                >
                  Change password
                </Button>
              </div>
            </Colxx>
          </Row>
          {loginMember === "Edit_Profile" ? (
            <Row className="m-4 p-3" style={{ backgroundColor: "#FBFBFB" }}>
              <Colxx
                lg={12}
                xs={12}
                className="p-0"
                style={{
                  backgroundColor: "#FBFBFB",
                }}
              >
                <div className="">
                  <Grid lg={12}>
                    <div className="p-5">
                      <Formik
                        initialValues={{
                          fullname: userData?.fullname || "",
                          email: userData?.email || "",
                          mobile: userData?.mobile || "",
                          job_title: userData?.job_title || "",
                          image: "",
                        }}
                        validationSchema={Yup.object({
                          mobile: Yup.string()
                            .matches(
                              /^[0-9]{10}$/,
                              "Mobile number must be exactly 10 digits"
                            )
                            .required("Mobile number is required"),
                          image: Yup.mixed().required("Please select a file"),
                        })}
                        onSubmit={(values, { resetForm }) => {
                          let formdata = new FormData();
                          if (values) {
                            formdata.append("fullname", values?.fullname || "");
                            formdata.append("email", userData?.email || "");
                            formdata.append(
                              "job_title",
                              values?.job_title || ""
                            );
                            formdata.append("mobile", values?.mobile || "");
                            formdata.append("image", userImage);
                          } else {
                            console.log("values object is null or undefined");
                          }
                          setLoading(true);
                          apiAuth
                            .put(`/api/register/${userData?.id}/`, formdata)
                            .then((res) => {
                              const { data } = res;
                              setLoading(false);
                              NotificationManager.success(
                                "Profile",
                                "Profile Updated Successfully",
                                3000,
                                null,
                                null,
                                ""
                              );
                              // resetForm();
                              getProfileData();
                              history.push("/user_tickets");
                            })
                            .catch((err) => {
                              setLoading(false);
                              NotificationManager.error(
                                "Profile",
                                "Profile Create Error",
                                3000,
                                null,
                                null,
                                ""
                              );
                            });
                        }}
                      >
                        {({ resetForm, setFieldValue }) => (
                          <Form className="av-tooltip tooltip-label-bottom">
                            <Row className="my-1 ">
                              <Colxx xs={12} lg={6}>
                                <Grid item lg={10} xs={12} className="mb-3">
                                  <label
                                    htmlFor="fullname"
                                    className="form-label text-primary"
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: 600,
                                      wordWrap: "break-word ",
                                    }}
                                  >
                                    Full name
                                  </label>
                                  <Field
                                    className="form-control"
                                    name="fullname"
                                    placeholder="Enter the name of this person"
                                    style={{
                                      borderRadius: "10px",
                                    }}
                                  />
                                  <ErrorMessage
                                    name="fullname"
                                    render={(msg) => (
                                      <div className="text-danger">{msg}</div>
                                    )}
                                  />
                                </Grid>
                                <Grid item lg={10} xs={12} className="mb-3">
                                  <label
                                    htmlFor="job_title"
                                    className="form-label text-primary"
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: 600,
                                      wordWrap: "break-word ",
                                    }}
                                  >
                                    Job Title
                                  </label>
                                  <Field
                                    className="form-control"
                                    name="job_title"
                                    placeholder="Enter a title"
                                    style={{
                                      borderRadius: "10px",
                                    }}
                                  />
                                  <ErrorMessage
                                    name="job_title"
                                    render={(msg) => (
                                      <div className="text-danger">{msg}</div>
                                    )}
                                  />
                                </Grid>
                                <Grid item lg={10} xs={12} className="mb-3">
                                  <label
                                    htmlFor="user"
                                    className="form-label text-primary"
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: 600,
                                      wordWrap: "break-word ",
                                    }}
                                  >
                                    Email
                                  </label>
                                  <Field
                                    className="form-control"
                                    name="user"
                                    placeholder="sandeep16.redd@gmail.com"
                                    style={{
                                      borderRadius: "10px",
                                      backgroundColor: "#FBFBFB",
                                      border: "none",
                                    }}
                                    value={userData?.email}
                                    disabled={true}
                                  />
                                </Grid>
                                <Grid item lg={10} xs={12} className="mb-3">
                                  <label
                                    htmlFor="mobile"
                                    className="form-label text-primary"
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: 600,
                                      wordWrap: "break-word ",
                                    }}
                                  >
                                    Mobile Number
                                    <span className="text-danger">*</span>
                                  </label>
                                  <Field
                                    className="form-control"
                                    name="mobile"
                                    placeholder="Enter Mobile Number here"
                                    style={{
                                      borderRadius: "10px",
                                    }}
                                  />
                                  <ErrorMessage
                                    name="mobile"
                                    render={(msg) => (
                                      <div className="text-danger">{msg}</div>
                                    )}
                                  />
                                </Grid>
                              </Colxx>
                              <Colxx
                                xs={12}
                                lg={6}
                                className="d-flex flex-column justify-content-center align-items-center pt-5"
                              >
                                <Card className="my-2 d-flex justify-content-center align-items-center mb-3">
                                  {uploadLogo?.length > 0 ? (
                                    <div>
                                      <img
                                        src={uploadLogo ? uploadLogo : profile}
                                        alt="Ticket"
                                        style={{
                                          width: "12rem",
                                          height: "15rem",
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      className="px-3 py-5   h-100 w-100 rounded-2"
                                      style={{
                                        backgroundColor: "#fbfbfb",
                                      }}
                                    >
                                      <h3 className="my-5 text-primary">
                                        Upload Image
                                      </h3>
                                    </div>
                                  )}
                                </Card>

                                <div className="d-flex align-items-center mt-2 ">
                                  <input
                                    type="file"
                                    accept="image/*"
                                    name="image"
                                    style={{ display: "none" }}
                                    ref={fileInputRef}
                                    onChange={(event) => {
                                      handleImageUpload(event);
                                      setFieldValue(
                                        "image",
                                        event.currentTarget.files[0]
                                      );
                                    }}
                                  />
                                  <Button
                                    className="btn"
                                    onClick={handleButtonClick}
                                    style={{
                                      color: "#000",
                                      fontWeight: 500,
                                      background: "rgba(9, 53, 100, 0.20)",
                                      width: "91px",
                                      height: "38px",
                                      borderRadius: "10px",
                                      marginRight: "10px",
                                      textTransform: "none",
                                    }}
                                  >
                                    Change
                                  </Button>
                                  <Button
                                    className="btn"
                                    type="button"
                                    onClick={handleRemove}
                                    style={{
                                      color: "#000",
                                      fontWeight: 500,
                                      background: "rgba(9, 53, 100, 0.20)",
                                      width: "91px",
                                      height: "38px",
                                      borderRadius: "10px",
                                      textTransform: "none",
                                    }}
                                  >
                                    Remove
                                  </Button>
                                </div>
                                <ErrorMessage
                                  name="image"
                                  render={(msg) => (
                                    <div className="text-danger">{msg}</div>
                                  )}
                                />
                              </Colxx>
                            </Row>

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                fontSize: "16px",
                              }}
                            >
                              {loading ? (
                                <div
                                  className="spinner-border text-primary"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              ) : (
                                <div className="mt-4 mb-3">
                                  <Button
                                    className="btn "
                                    type="submit"
                                    style={{
                                      color: "white",
                                      fontWeight: 500,
                                      background: "#093564",
                                      borderRadius: "20px",
                                      padding: "8px 40px",
                                    }}
                                  >
                                    Submit
                                  </Button>
                                </div>
                              )}
                              <div className="mt-4 mb-3 ms-3">
                                <Button
                                  className="btn text-primary"
                                  style={{
                                    fontWeight: 500,
                                    padding: "8px 0px",
                                  }}
                                  onClick={() => {
                                    history.push("/user_tickets");
                                    // resetForm()
                                  }}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </Grid>
                </div>
              </Colxx>
            </Row>
          ) : (
            <ChangePassword
              userData={userData}
              loading={loading}
              setLoading={setLoading}
              history={history}
            />
          )}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Profile;
