import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Grid, Button, Box, Typography } from "@mui/material";
import Select from "react-select";
import { Card, Container, Row } from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import apiAuth from "../../helpers/ApiAuth";
import { Colxx } from "../../components/Common/CustomBootstrap";
import BreadCrumb from "../../components/Common/BreadCrumb";
import NotificationManager from "../../components/Common/NotificationManager";
import GroupImg from "../../assets/images/groups.png";

const AddGroup = (props) => {
  const [loading, setLoading] = useState(false);
  const [agentOptions, setAgentOptions] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState([]);

  const getALLAgents = () => {
    apiAuth
      .get("/api/get-agents")
      .then((res) => {
        const data = res?.data;
        const agentOptions = data?.map((item) => {
          return {
            label: item?.full_name,
            value: item?.id,
          };
        });
        setAgentOptions(agentOptions);

        if (props?.isEdit) {
          const agentsopts = agentOptions.filter((opt) =>
            props?.data?.users?.some((type) => type === opt?.value)
          );
          setSelectedAgent(agentsopts);
        }
      })
      .catch((err) => {
        setLoading(false);
        NotificationManager.error(
          "Agents",
          "Agents Update Error",
          3000,
          null,
          null,
          ""
        );
      });
  };

  useEffect(() => {
    getALLAgents();
  }, []);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "10px",
    }),
  };
  return (
    <React.Fragment>
      <div className={`${props?.isEdit ? "" : "page-content"}`}>
        <div>
          <Grid container>
            <Grid xs={12}>
              <Card className="p-3">
                {props?.isEdit ? (
                  ""
                ) : (
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      marginBottom: "2%",
                    }}
                  >
                    <img
                      src={GroupImg}
                      alt="Agent"
                      height="30"
                      width="30"
                      style={{
                        objectFit: "contain",
                      }}
                    />
                    <Typography
                      variant="h5"
                      className="text-primary fw-bold"
                      style={{ fontFamily: "Inter" }}
                    >
                      New Group
                    </Typography>
                  </Box>
                )}

                <Formik
                  initialValues={{
                    name: props?.data?.name || "",
                    description: props?.data?.description || "",
                    users: props?.data?.users || [],
                  }}
                  validationSchema={Yup.object({
                    name: Yup.string().ensure().required("Required!"),
                    description: Yup.string().ensure().required("Required!"),
                  })}
                  onSubmit={(values) => {
                    setLoading(true);
                    props?.isEdit
                      ? apiAuth
                          .patch(
                            `/api/master/group/${props?.data?.id}/`,
                            values
                          )
                          .then((res) => {
                            let data = res.data;
                            NotificationManager.success(
                              "Group Updation",
                              "Group Updated Successfully",
                              3000,
                              null,
                              null,
                              ""
                            );
                            props?.closePopUp(data);
                          })
                          .catch((err) => {
                            NotificationManager.error(
                              "Group Updation",
                              "Group Updation Error",
                              3000,
                              null,
                              null,
                              ""
                            );
                            props?.closePopUp();
                          })
                      : apiAuth
                          .post("/api/master/group/", values)
                          .then((res) => {
                            NotificationManager.success(
                              "Group Creation",
                              "Group Created Successfully",
                              3000,
                              null,
                              null,
                              ""
                            );
                            props?.history.goBack();
                          })
                          .catch((err) => {
                            NotificationManager.error(
                              "Group Creation",
                              "Group Create Error",
                              3000,
                              null,
                              null,
                              ""
                            );
                            props?.history.goBack();
                          });
                    setLoading(false);
                  }}
                >
                  {({ values, errors, touched, setFieldValue }) => (
                    <Form className="av-tooltip tooltip-label-bottom">
                      <Grid item lg={12} xs={12} className="mb-3">
                        <label
                          htmlFor="name"
                          className="form-label text-primary fs-16 fw-600 custom-word-wrap"
                        >
                          Group Name
                        </label>
                        <Field
                          className="form-control"
                          name="name"
                          type="text"
                          placeholder="e.g. Refund Group"
                          style={{
                            borderRadius: "10px",
                          }}
                        />
                        <ErrorMessage
                          name="name"
                          render={(msg) => (
                            <div className="text-danger">{msg}</div>
                          )}
                        />
                      </Grid>
                      <Grid item lg={12} xs={12} className="mb-3">
                        <label
                          htmlFor="description"
                          className="form-label text-primary fs-16 fw-600 custom-word-wrap"
                        >
                          Description
                        </label>
                        <Field
                          as="textarea"
                          className="form-control"
                          name="description"
                          placeholder="e.g. This group will answer all your queries related to refunds"
                          style={{
                            borderRadius: "10px",
                          }}
                        />
                        <ErrorMessage
                          name="description"
                          render={(msg) => (
                            <div className="text-danger">{msg}</div>
                          )}
                        />
                      </Grid>

                      {/* Use for Edit Page */}
                      <Grid item lg={6} xs={12} className="mb-3">
                        <div className="mb-3">
                          <label
                            htmlFor="group"
                            className="form-label"
                            style={{
                              color: "#093564",
                              fontSize: "16px",
                              fontWeight: 600,
                              wordWrap: "break-word ",
                            }}
                          >
                            Add Agents
                          </label>
                          <Select
                            isMulti
                            options={agentOptions}
                            value={selectedAgent}
                            onChange={(data) => {
                              let agentData = data.map((d) => d.value);
                              setFieldValue("users", agentData);
                              setSelectedAgent(data);
                            }}
                            components={{
                              DropdownIndicator: "",
                            }}
                            styles={customStyles}
                            placeholder=""
                          />
                          <ErrorMessage
                            name="users"
                            render={(msg) => (
                              <div className="text-danger">{msg}</div>
                            )}
                          />
                          <p
                            className="mt-2"
                            style={{ color: "rgba(0, 0, 0, 0.50)" }}
                          >
                            Paste as many email addresses, separated by comma or
                            space.
                          </p>
                        </div>
                      </Grid>

                      <div className="d-flex justify-content-start align-items-center fs-16">
                        {loading ? (
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <div className="mt-4 mb-3">
                            <Button
                              className="btn bg-primary text-white"
                              type="submit"
                              style={{
                                fontWeight: 500,
                                borderRadius: "20px",
                                padding: "8px 40px",
                              }}
                            >
                              {props?.isEdit ? "Update" : "Create Group"}
                            </Button>
                          </div>
                        )}
                        <div className="mt-4 mb-3 ms-3">
                          <Button
                            className="btn text-primary fw-500"
                            // type="submit"
                            onClick={() => {
                              props?.isEdit
                                ? props?.closePopUp()
                                : props?.history.goBack();
                            }}
                            style={{
                              padding: "8px 0px",
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddGroup;
