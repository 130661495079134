import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import {
  Card,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

import BreadCrumb from "../../components/Common/BreadCrumb";
import AddGroup from "./AddGroup";
import { Colxx } from "../../components/Common/CustomBootstrap";
import GroupTable from "./GroupTable";
import NotificationManager from "../../components/Common/NotificationManager";
import apiAuth from "../../helpers/ApiAuth";

const Groups = () => {
  const [createModal, setCreateModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    getGroups();
  }, []);

  const getGroups = () => {
    apiAuth
      .get("/api/master/group")
      .then((res) => {
        console.log(res);
        const { data } = res;
        setGroups(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);

        // NotificationManager.error(
        //   "Groups",
        //   "Group Get Error",
        //   3000,
        //   null,
        //   null,
        //   ""
        // );
      });
  };

  const deleteGroup = (id, index) => {
    console.log("inside delete group");
    apiAuth
      .delete(`/api/master/group/${id}`)
      .then((res) => {
        NotificationManager.success(
          "Group Deletion",
          "Group deleted Successfully",
          3000,
          null,
          null,
          ""
        );
        let grps = [...groups];
        grps.splice(index, 1);
        setGroups([...grps]);
      })
      .catch((err) =>
        NotificationManager.error(
          "Group Deletion",
          "Group Delete Error",
          3000,
          null,
          null,
          ""
        )
      );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Groups"
            pageTitle="Settings"
            add_new={true}
            add_new_url={`/groups/add`}
            search_functionality={true}
            searchValue={searchValue}
            setSearchValue={(val) => {
              setSearchValue(val);
            }}
          />
        </Container>
        <Row>
          <Colxx lg="12">
            <>
              {loading ? (
                <div className="loading"></div>
              ) : (
                <>
                  <Card>
                    <GroupTable
                      groups={groups.filter((item) =>
                        String(item.name)
                          ?.toLowerCase()
                          .includes(String(searchValue)?.toLowerCase())
                      )}
                      deleteGroup={(id, index) => deleteGroup(id, index)}
                      getGroups={() => getGroups()}
                      updateGroup={(data, index) => {
                        let grps = [...groups];
                        grps.splice(index, 1, data);
                        setGroups([...grps]);
                      }}
                    />
                  </Card>
                </>
              )}
            </>
          </Colxx>
        </Row>
      </div>
      <Modal
        id="signupModals"
        tabIndex="-1"
        className="md"
        isOpen={createModal}
        toggle={() => {
          setCreateModal((prev) => !prev);
        }}
      >
        <ModalHeader
          className="p-3"
          toggle={() => {
            setCreateModal((prev) => !prev);
          }}
        >
          Add Group
        </ModalHeader>
        <ModalBody>
          <AddGroup
            closePopUp={() => {
              setCreateModal(false);
            }}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Groups;
