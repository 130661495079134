import { Button, Grid } from "@mui/material";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Container, Tooltip } from "reactstrap";
import LanguageIcon from "@mui/icons-material/Language";
import CircleIcon from "@mui/icons-material/Circle";
import { useLocation } from "react-router-dom";
import apiAuth from "../../helpers/ApiAuth";
import moment from "moment";
import { PriorityHighRounded } from "@mui/icons-material";
import NotificationManager from "../../components/Common/NotificationManager";

const TicketTimeline = () => {
  const location = useLocation();
  // const [ticketData, setTicketData] = useState(location.state || {});
  // const ticketIssues = ticketData?.data;
  const [ticketsLogsData, setTicketsLogsdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState();
  const [tooltipOpen, setTooltipOpen] = useState([]);

  // Function to toggle the tooltip for a specific item
  const toggleTooltip = (index) => {
    const newTooltipOpen = [...tooltipOpen];
    newTooltipOpen[index] = !newTooltipOpen[index];
    setTooltipOpen(newTooltipOpen);
  };

  const getCapitalise = (str) => {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    } else {
      return str;
    }
  };

  const getTicketsLogs = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get("id");
    let api = `/api/ticketloglist/?ticket_id=${id}`;
    apiAuth
      .get(api)
      .then((response) => {
        let data = response?.data;
        const sortedData = data?.sort((a, b) => {
          const dateA = new Date(a.timestamp);
          const dateB = new Date(b.timestamp);
          return dateB - dateA;
        });
        setTicketsLogsdata(sortedData);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getTicketsLogs();
  }, []);

  return (
    <React.Fragment>
      <div className="">
        <Container
          fluid
          className="p-3"
          style={{
            overflowY: "auto",
          }}
        >
          <h4 className="text-primary mb-3" style={{ fontSize: "24px" }}>
            Timeline
          </h4>
          {loading ? (
            <div className="loading"></div>
          ) : (
            <>
              {ticketsLogsData?.map((item, i) => {
                const filterInfo = item?.info
                  ?.split(", ")
                  .filter((word) => word !== "Remarks")
                  .join(", ");
                return (
                  <>
                    <Grid
                      item
                      key={i}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        // alignItems: "center",
                        paddingBottom: "20px",
                        position: "relative",
                        paddingLeft: "20px",
                        borderLeft: `${
                          i === ticketsLogsData?.length - 1
                            ? ""
                            : "1px solid #8197AE"
                        }`,
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: -16,
                          borderRadius: "50%",
                          background: "#8197AE",
                          padding: "5px",
                        }}
                      >
                        <LanguageIcon style={{ color: "white" }} />
                      </div>
                      <div className="d-flex justify-content-start align-items-start">
                        <div>
                          <h5
                            className="ms-3 text-primary"
                            style={{ fontSize: "16px" }}
                          >
                            {item?.type === "Created"
                              ? `${item?.info}`
                              : `${filterInfo} ${item?.type?.toLowerCase()} by ${
                                  item?.updated_by_details?.username
                                } `}
                          </h5>

                          <p
                            className="text-muted ms-3"
                            style={{
                              color: "black",
                              fontWeight: "500",
                              fontStyle: "italic",
                              fontSize: "12px",
                            }}
                          >
                            {moment(item?.timestamp).format(
                              "Do MMMM YYYY [at] h:mm A"
                            )}
                          </p>
                        </div>
                      </div>
                      <div>
                        <PriorityHighRounded
                          // fontSize="small"
                          sx={{
                            backgroundColor: "#8197AE",
                            color: "white",
                            borderRadius: "50%",
                            fontSize: "14px",
                          }}
                          onMouseEnter={() => toggleTooltip(i)}
                          onMouseLeave={() => toggleTooltip(i)}
                          id={`hint-icon-${i}`}
                        />
                        {item?.sub_info !== null && (
                          <Tooltip
                            placement="top"
                            isOpen={tooltipOpen[i]}
                            target={`hint-icon-${i}`}
                            style={{
                              backgroundColor: "#093564",
                              // width: "auto",
                            }}
                          >
                            {item?.sub_info === "created" ? (
                              <div>{item?.info}</div>
                            ) : (
                              item?.sub_info?.split(",").map((inf, index) => {
                                const previous = item?.previous_data[0];
                                const current = previous[inf];

                                return (
                                  <div className="d-flex mb-2" key={index}>
                                    <span style={{ fontWeight: "700" }}>
                                      {getCapitalise(inf)}
                                    </span>
                                    {current[1] !== null && `: ${current[1]}`}
                                  </div>
                                );
                              })
                            )}
                          </Tooltip>
                        )}
                      </div>
                    </Grid>
                  </>
                );
              })}
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TicketTimeline;
